import { call, put, takeEvery, fork } from "redux-saga/effects";
import * as actions from "../actions/novedades";
import novedades from "../services/novedades";

function* getNovedadesByClienteId({ clienteId }) {
  yield put(actions.startLoading());
  try {
    const response = yield call(novedades.getNovedadesByClienteId, clienteId);
    if (response) {
      yield put(actions.getNovedadesByClienteIdSucceeded(response));
    }
  } catch (err) {
    yield put(actions.getNovedadesByClienteIdError(err));
    console.log(err);
  }
}

function* getNovedadesMenuByClienteId({ clienteId }) {
  yield put(actions.startLoading());
  try {
    const response = yield call(novedades.getNovedadesMenuByClienteId, clienteId);
    if (response) {
      yield put(actions.getNovedadesMenuByClienteIdSucceeded(response));
    }
  } catch (err) {
    yield put(actions.getNovedadesMenuByClienteIdError(err));
    console.log(err);
  }
}

function* watchGetNovedadesByClienteId() {
  yield takeEvery(actions.Types.NOVEDADES_GET_REQUEST, getNovedadesByClienteId);
}
function* watchGetNovedadesMenuByClienteId() {
  yield takeEvery(actions.Types.NOVEDADES_GET_MENU_REQUEST, getNovedadesMenuByClienteId);
}

const novedadesSagas = [
  fork(watchGetNovedadesByClienteId),
  fork(watchGetNovedadesMenuByClienteId)
];

export default novedadesSagas;
