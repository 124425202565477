import {call, put, takeEvery, fork} from 'redux-saga/effects';
import * as actions from '../actions/reclamos';
import reclamos from '../services/reclamos';

function* getReclamosByCuit({cuit}) {
    yield put(actions.startLoading());
    try {
        const response = yield call(reclamos.getReclamosByCuit, cuit);
        if(response){
            yield put(actions.getReclamosByCuitSucceeded(response));
        }
    } catch (err) {
        yield put(actions.getReclamosByCuitError(err));
        console.log(err)
    }
}

function* getReclamosByFilter({filter}) {
    yield put(actions.startLoading());
    try {
        const response = yield call(reclamos.getReclamosByFilter, filter);
        if(response){
            yield put(actions.getReclamosByFilterSucceeded(response));
        } 
    } catch (err) {
        yield put(actions.getReclamosByFilterError(err));
        console.log(err)
    }
}

function* getEstadosReclamo() {
    yield put(actions.startLoading());
    try {
        const response = yield call(reclamos.getEstadosReclamo);
        if(response){
            yield put(actions.getEstadoReclamoSucceeded(response));
        } 
    } catch (err) {
        yield put(actions.getEstadoReclamoError(err));
        console.log(err)
    }
}

function* crearNuevoReclamo({reclamo}) {
    yield put(actions.startLoading());
    try {
        const response = yield call(reclamos.crearNuevoReclamo, reclamo);
        if(response.data.isSuccess){
            yield put(actions.postReclamoByClientIdSucceeded(response));
        } else {
            yield put(actions.postReclamoByClientIdError(true));
        }
    } catch (err) {
        yield put(actions.postReclamoByClientIdError(err));
        console.log(err)
    }
}

function*  watchGetReclamosByCuitRequest() {
    yield takeEvery(actions.Types.RECLAMOS_GET_REQUEST, getReclamosByCuit);
}
function*  watchGetReclamosByFilter() {
    yield takeEvery(actions.Types.RECLAMOS_SEARCH_REQUESTED, getReclamosByFilter);
}
function*  watchGetEstadosReclamos() {
    yield takeEvery(actions.Types.ESTADO_RECLAMO_REQUESTED, getEstadosReclamo);
}
function*  watchCrearNuevoReclamo() {
    yield takeEvery(actions.Types.RECLAMO_NUEVO_REQUESTED, crearNuevoReclamo);
}

const reclamosSagas = [
    fork(watchGetReclamosByCuitRequest),    
    fork(watchGetReclamosByFilter),    
    fork(watchGetEstadosReclamos),    
    fork(watchCrearNuevoReclamo)
]

export default reclamosSagas;