import IColumnData from "../../../../common/interface/IColumnData";

export const ESTADO_PEDIDOS_COLUMN :IColumnData[] = [    
    {
        width: 120,
        label: 'ESTADO',
        dataKey: 'estado',
    },
    {
        width: 80,
        label: 'FECHA DE CREACIÓN',
        dataKey: 'fecha'
    },
    {
        width: 40,
        label: 'PRESUPUESTO',
        dataKey: 'presuportalId',
        numeric: true,
    },
    {
        width: 40,
        label: 'PEDIDO',
        dataKey: 'pedidoId',
        numeric: true,
    },
    {
        width: 20,
        label: 'ITEM',
        dataKey: 'item',
        numeric: true,
    },    
    {
        width: 50,
        label: 'ID PRODUCTO',
        dataKey: 'productoId',
        numeric: true,
    },
    {
        width: 120,
        label: 'PRODUCTO',
        dataKey: 'descripcion',
        numeric: true,
    },
    {
        width: 40,
        label: 'CANTIDAD',
        dataKey: 'cantidad',
        numeric: true,
    },
    {
        width: 80,
        label: 'FECHA REMITO',
        dataKey: 'fechaRemito'
    },
    {
        width: 40,
        label: 'REMITO',
        dataKey: 'remitoId',
        numeric: true,
    },
    {
        width: 40,
        label: 'SERIE',
        dataKey: 'serie',
        numeric: true,
    },
    {
        width: 40,
        label: 'FACTURA',
        dataKey: 'facturaId',
        numeric: true,
    }
];