import {call, put, takeEvery, fork} from 'redux-saga/effects'

import * as actions from '../actions/users';
import auth from '../services/auth';
import UsersService from '../services/users'
import CognitoService from '../services/cognito';

function* login({loginData}) {
    try {
        const res = yield call(CognitoService.login, loginData);
        if(res){
            yield put(actions.loginSucceeded(res.idToken.jwtToken, true));
        }
    } catch (err) {
        console.log(err)
    }
}

function* fetchUsers() {
    try {
        const users = yield call(UsersService.find);
        yield put(actions.receiveUsers(users));
    } catch (err) {
        console.log(err)
    }
}
function* fetchOneUsers({id, TipoI}) {
    try {
        const user = yield call(UsersService.findOne, id, TipoI);
        yield put(actions.receiveOneUsers(user));
    } catch (err) {
        yield put(actions.receiveOneUsersError(err.response.data));
        console.log(err)
    }
}
function* fetchOneUsersIdentification({identificationData}) {
    
    try {
        const user = yield call(UsersService.findOneIdentification,identificationData);
        yield put(actions.receiveOneUsersIdentification(user));
    } catch (err) {
        console.log(err)
    }
}
function* saveUser({user}) {
    try {
        const res = yield call(CognitoService.register, user);
        if(res){
            //yield put(requestUsers());
            console.log(res)
        }
        
    } catch (err) {
        console.log(err)
    }
}
function* deletedUser({id}) {
    
    try {
        const res = yield call(UsersService.deleted,id);
        if(res){
            yield put(actions.requestUsers());
        }
    } catch (err) {
        console.log(err)
    }
}
function* resetPassword(data) {
    yield put(actions.startLoading());
    try {
        const user = yield call(auth.resetPassword,data);
        yield put(actions.receiveResetPasswordSucceeded(user));
    } catch (err) {
        yield put(actions.receiveResetPasswordError(err));
    }
}

function*  watchGetlogin() {
    yield takeEvery(actions.Types.USERS_OATH, login)
}

function*  watchGetfetchUsers() {
    yield takeEvery(actions.Types.USERS_FETCH_REQUESTED, fetchUsers)
}

function*  watchGetfetchOneUsers() {
    yield takeEvery(actions.Types.USERS_FETCH_ONE_REQUESTED, fetchOneUsers)
}

function*  watchGetfetchOneUsersIdentification() {
    yield takeEvery(actions.Types.USERS_FETCH_ONE_IDENTIFICATION_REQUESTED, fetchOneUsersIdentification)
}

function*  watchGetsaveUser() {
    yield takeEvery(actions.Types.USERS_CREATE_ONE_REQUESTED, saveUser)
}

function*  watchGetdeletedUser() {
    yield takeEvery(actions.Types.USERS_DELETED_ONE_REQUESTED, deletedUser)
}

function*  watchGetresetPassword() {
    yield takeEvery(actions.Types.USER_RESET_PASSWORD_REQUESTED, resetPassword)
}


const usersSagas = [
    fork(watchGetlogin),
    fork(watchGetfetchUsers),
    fork(watchGetfetchOneUsers),
    fork(watchGetfetchOneUsersIdentification),
    fork(watchGetsaveUser),
    fork(watchGetdeletedUser),
    fork(watchGetresetPassword)
]

export default usersSagas;
