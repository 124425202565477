import React from 'react';

const ResetPasswordMessage = (props) => {

  const { user } = props

  return (
    <>
    <div>
      <div className="container" style={{paddingTop:"2rem", paddingBottom:"4rem"}}> 

        <div className="container">
          <div className="d-flex flex-row bd-highlight" style={{justifyContent:"center"}}>
            <div className="mb-3" style={{fontWeight:"bold", marginRight:"1.25rem"}}>
              <h4>Recuperar Contraseña</h4>
            </div>
          </div>
        </div>

        <div className="container" style={{paddingTop:"2rem", blockSize:"4rem"}}>
          <div className="d-flex flex-row bd-highlight">
            <div className="mb-3" style={{marginRight:"1.25rem"}}>
            </div>
          </div>
        </div>

        <div className="container" style={{paddingTop:"2rem"}}>
          <div className="d-flex flex-row bd-highlight">
            <div className="mb-3" style={{color:"gray"}}>
              <h4>
                Se le ha enviado un email a la siguiente dirección registrada: {user.email} <br/>
                Verifique su bandeja de entrada o spam. Cualquier inconveniente ponerse en contacto con su ejecutivo de venta.
              </h4>
            </div>
          </div>
        </div>
     
      </div>
    </div>
  </>
  );
}

export default ResetPasswordMessage;