import axios from 'axios'
import  TokenService  from './token'

const { REACT_APP_ENDPOINT } = process.env;
const ENDPOINT = `${REACT_APP_ENDPOINT}/api/User/`

export default class UsersService {
    static async find() {
        const respuesta = await axios.get(ENDPOINT,{
            headers: {
                authorization: await TokenService.getAuthHeader()
            }
        })
        .then(response => {
            return response
        })  
        return respuesta.data.users
    }
    static async findOne(id, TipoI) {
        const respuesta = await axios.get(`${REACT_APP_ENDPOINT}/api/User?identification=${id}&identificationType=${TipoI}`,{
            headers: {
                authorization: await TokenService.getAuthHeader()
            }
        })
        .then(response => {
            return response
        }) 
        return respuesta.data.data
    }
    static async findOneIdentification(identificationData) {
        const respuesta = await axios.get(`${ENDPOINT}`,{ identificationData })
        .then(response => {
            return response
        })  
        return respuesta.data.user
    }
    static async save(user) {
        const respuesta = await axios.post(ENDPOINT,{user},{
            headers: {
                authorization: await TokenService.getAuthHeader()
            }
        })
        .then(response => {
            return response
        })  
        return respuesta.data
    }
    static async deleted(id) {
        const respuesta = await axios.delete(`${ENDPOINT}${id}`,{
            headers: {
                authorization: await TokenService.getAuthHeader()
            }
        })
        .then(response => {
            return response
        })  
        return respuesta.data
    }
    static async login(loginData) {
        const respuesta = await axios.post(`${REACT_APP_ENDPOINT}/public-api/login`, {loginData})
        .then(response => {
            return response
        })
        return respuesta.data
    }

}