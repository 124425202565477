import axios from "axios";

const { REACT_APP_ENDPOINT } = process.env;
const ENDPOINT = `${REACT_APP_ENDPOINT}/api/Product/`;

export default class productosService {
  static async find(id, clienteId) {
    const respuesta = await axios
      .get(
        `${ENDPOINT}${id}/${clienteId}` //,{
        //headers: {
        //    authorization: await TokenService.getAuthHeader()
        //}
        //}
      )
      .then((response) => {
        return response;
      });
    console.log(respuesta.data.data);
    return respuesta.data.data;
  }

  static async fetchByGrupo(page, id, clienteId) {
    const respuesta = await axios
      .get(`${ENDPOINT}grupo?page=${page}&id=${id}&clienteId=${clienteId}`)
      .then((response) => {
        return response;
      });
    return respuesta.data.data.$values;
  }

  static async search(id, search) {
    const respuesta = await axios
      .get(`${ENDPOINT}grupo/${id}/search?name=${search}`)
      .then((response) => {
        return response;
      });
    return respuesta.data.data.$values;
  }

  static async downloadFile({ grupoId }) {
    const respuesta = await axios
      .get(`${REACT_APP_ENDPOINT}/api/Document/?fileName=${grupoId}.pdf`, {
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        return response;
      });
    return new Blob([respuesta.data], { type: "application/pdf" });
  }

  static async searchAll(search, clienteId) {
    const respuesta = await axios
      .get(`${ENDPOINT}search?name=${search}&clienteId=${clienteId}`)
      .then((response) => {
        return response;
      });
    return respuesta.data.data.$values;
  }

  static async consultarDisponibilidad(id, clientId, cantidad) {
    const respuesta = await axios
      .post(
        `${ENDPOINT}consultarDisponibilidad?productoId=${id}&clienteId=${clientId}&cantidad=${cantidad}`
      )
      .then((response) => {
        return response;
      });
    return respuesta.data.data.enviado;
  }
}
