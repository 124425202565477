export const Types= {
    CUENTA_CORRIENTE_LOADING: 'CUENTA_CORRIENTE_LOADING',

    ESTADO_PEDIDO_PRESUPUESTO_GET_REQUEST: 'ESTADO_PEDIDO_PRESUPUESTO_GET_REQUEST',
    ESTADO_PEDIDO_PRESUPUESTO_GET_SUCCEEDED: 'ESTADO_PEDIDO_PRESUPUESTO_GET_SUCCEEDED',
    ESTADO_PEDIDO_PRESUPUESTO_GET_ERROR: 'ESTADO_PEDIDO_PRESUPUESTO_GET_ERROR',
    
    ESTADO_PEDIDO_FILTER_GET_REQUEST: 'ESTADO_PEDIDO_FILTER_GET_REQUEST',
    ESTADO_PEDIDO_FILTER_GET_SUCCEEDED: 'ESTADO_PEDIDO_FILTER_GET_SUCCEEDED',
    ESTADO_PEDIDO_FILTER_GET_ERROR: 'ESTADO_PEDIDO_FILTER_GET_ERROR',
    
    SALDOS_FILTER_GET_REQUEST: 'SALDOS_FILTER_GET_REQUEST',
    SALDOS_FILTER_GET_SUCCEEDED: 'SALDOS_FILTER_GET_SUCCEEDED',
    SALDOS_FILTER_GET_ERROR: 'SALDOS_FILTER_GET_ERROR',
    
    COMPOSICION_FILTER_GET_REQUEST: 'COMPOSICION_FILTER_GET_REQUEST',
    COMPOSICION_FILTER_GET_SUCCEEDED: 'COMPOSICION_FILTER_GET_SUCCEEDED',
    COMPOSICION_FILTER_GET_ERROR: 'COMPOSICION_FILTER_GET_ERROR',
    
    FACTURA_FILTER_GET_REQUEST: 'FACTURA_FILTER_GET_REQUEST',
    FACTURA_FILTER_GET_SUCCEEDED: 'FACTURA_FILTER_GET_SUCCEEDED',
    FACTURA_FILTER_GET_ERROR: 'FACTURA_FILTER_GET_ERROR',

    ESTADOS_GET_REQUEST: 'ESTADOS_GET_REQUEST',
    ESTADOS_GET_SUCCEEDED: 'ESTADOS_GET_SUCCEEDED',
    ESTADOS_GET_ERROR: 'ESTADOS_GET_ERROR'
};

export const startLoading = () => ({type: Types.CUENTA_CORRIENTE_LOADING});

export const getEstadoPedidoByPresupuestoId = (clienteId, presupuestoId) => ({type: Types.ESTADO_PEDIDO_PRESUPUESTO_GET_REQUEST, clienteId, presupuestoId});
export const getEstadoPedidoByPresupuestoIdSucceeded = (estadopedidos) => ({type: Types.ESTADO_PEDIDO_PRESUPUESTO_GET_SUCCEEDED, estadopedidos})
export const getEstadoPedidoByPresupuestoIdError = (err) => ({type: Types.ESTADO_PEDIDO_PRESUPUESTO_GET_ERROR, err})

export const getEstadoPedidoByFilter = (filter) => ({type: Types.ESTADO_PEDIDO_FILTER_GET_REQUEST, filter});
export const getEstadoPedidoByFilterSucceeded = (estadopedidos) => ({type: Types.ESTADO_PEDIDO_FILTER_GET_SUCCEEDED, estadopedidos})
export const getEstadoPedidoByFilterError = (err) => ({type: Types.ESTADO_PEDIDO_FILTER_GET_ERROR, err})

export const getSaldosByFilter = (filter) => ({type: Types.SALDOS_FILTER_GET_REQUEST, filter});
export const getSaldosByFilterSucceeded = (saldos) => ({type: Types.SALDOS_FILTER_GET_SUCCEEDED, saldos})
export const getSaldosByFilterError = (err) => ({type: Types.SALDOS_FILTER_GET_ERROR, err})

export const getComposicionByFilter = (filter) => ({type: Types.COMPOSICION_FILTER_GET_REQUEST, filter});
export const getComposicionByFilterSucceeded = (composicion) => ({type: Types.COMPOSICION_FILTER_GET_SUCCEEDED, composicion})
export const getComposicionByFilterError = (err) => ({type: Types.COMPOSICION_FILTER_GET_ERROR, err})

export const getFacturasByFilter = (filter) => ({type: Types.FACTURA_FILTER_GET_REQUEST, filter});
export const getFacturasByFilterSucceeded = (facturas) => ({type: Types.FACTURA_FILTER_GET_SUCCEEDED, facturas})
export const getFacturasByFilterError = (err) => ({type: Types.FACTURA_FILTER_GET_ERROR, err})

export const getEstados = () => ({type: Types.ESTADOS_GET_REQUEST});
export const getEstadosSucceeded = (estados) => ({type: Types.ESTADOS_GET_SUCCEEDED, estados})
export const getEstadosError = (err) => ({type: Types.ESTADOS_GET_ERROR, err})