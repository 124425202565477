import { CognitoUserPool } from 'amazon-cognito-identity-js';

import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";

const poolData = new CognitoUserPool({
  UserPoolId: 'sa-east-1_3f4iFVRA4',
  ClientId: '573tkomqokk5hoqh54j5ftms2s'
});

export default class CognitoService {

    static async login(loginData) {
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({
                Username: loginData.number,
                Pool: poolData
            });
            const authDetails = new AuthenticationDetails({
                Username: loginData.number,
                Password: loginData.password
            });
            
            user.authenticateUser(authDetails, {
                onSuccess: data => {
                    resolve(data)
                },
        
                onFailure: err => {
                    reject(err)
                }
            });
        })
    }
    static async register(registerData) {
        return await new Promise((resolve, reject) => {
            poolData.signUp(registerData.nombre, registerData.apellido,[
                {
                    Name: 'custom:identification',
                    Value: registerData.dni
                }
            ], null, (err, data) => {
                if (err) reject(err);
                resolve(data);
            });
        })
    }
}
