import React from "react";
import { useHistory } from "react-router";

import {
    Container,
    Grid,
    Button,
    Box
} from '@material-ui/core';
import mano from '../../assets/images/Error_404.png';
import colores from '../../shared/constants/styles';

const Error = () => {
    const history = useHistory();
    const handleBack = () => {
        history.goBack();
    }
    return (
        <Container className="py-5" component="main" maxWidth="md">
            <Box py={10}>
                <Grid container>
                    <Grid item xs={4}>
                        <img width="90%" alt="mano de stop" src={mano}/>
                    </Grid>
                    <Grid p xs={8}>
                        <h2 className="red-color">UPS, NO ENCONTRAMOS <br/> LA PÁGINA QUE BUSCÁS</h2>
                        <Button
                            className="my-3"
                            style={{backgroundColor: colores.rojo, color: '#fff'}}
                            variant="contained"
                            onClick={() => handleBack()}
                        >
                            Volver
                        </Button>
                        <h6 className="my-3">También podés Intentar lo siguiente</h6>
                        <p>
                            Si escribiste la URL, asegúrate que la ortografía sea la correcta.
                            Si haz hecho click en un vínculo y no ves información, es porque se trasladó el contenido.
                            Intenta utilizando nuestro buscador para encontrar lo que necesitas.
                            Si no estás seguro de cómo llegaste a esta página, regresa a la anterior o al inicio.
                        </p>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}
export default Error;
