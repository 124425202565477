import { Route, Redirect} from 'react-router-dom';
import MyUserService from '../services/myUser';
import  TokenService  from '../services/token';
import { isAllowed } from '../shared/utils/permissions';
import rutas from './constants/routes';

export default function PrivateRoute ({component: Component, permisoId, ...rest}) {
    return (
        <Route {...rest}>
            { (TokenService.hasSession() && MyUserService.getMyUser() != null) ? 
                ( (isAllowed(permisoId)) ? 
                    (<Component/>) 
                    : 
                    (<Redirect to={rutas.ERROR}/>)
                )
                : 
                (<Redirect to={rutas.LOGIN}/>)
            }
        </Route>
    )
};