import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';

const SearchBar = ({ search, success, onSearch, searchTerm, setSearchTerm }) => {
  
  const [shouldSearchBackend, setShouldSearchBackend] = useState(false);

  const handleSearch = () => {
    if (shouldSearchBackend) {
      onSearch(searchTerm);
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchTerm(inputValue);

    if (inputValue.length >= 4) {
      setShouldSearchBackend(true);
    } else {
      setShouldSearchBackend(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && shouldSearchBackend) {
      e.preventDefault(); 
      onSearch(searchTerm);
    }
  };

  return (
    <div style={{ marginLeft: '3vw' }}>
        <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, height: 40}}
        >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="¿Qué estás buscando?"
                inputProps={{ 'aria-label': 'buscar productos' }}
                value={searchTerm}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon onClick={handleSearch}/>
            </IconButton>
            { search && <CircularProgress size={20}/>}
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        </Paper>
        {searchTerm.length > 0 && searchTerm.length < 4 && (
              <p style={{ color: 'white' }}>Ingrese al menos 4 caracteres para buscar.</p>
            )}
    </div>
  );
};

export default SearchBar;
