import { Types } from '../actions/users';

const defaultUser={
    id: '',
    nombre: '',
    isEnable: true,
    dni: '',
    tel: '',
    codTotvs: '',
    mail: '',
    region: ''
}
const defaultUsers=[
    defaultUser
]
const defaultState={
    user: defaultUser,
    users: defaultUsers,
    loading: false,
    saving: false,
    success: false,
    error: false
}

export default function usersReducer(state = defaultState, action) {
    switch (action.type) {
        case Types.USER_LOADING:
            return {...state, loading: true}
        case Types.USERS_FETCH_SUCCEEDED:
            return {...state, users: action.users, loading: false};
        case Types.USERS_FETCH_ONE_SUCCEEDED:
            return {...state, user: action.user, loading: false};
        case Types.USERS_FETCH_ONE_ERROR:
            return {...state, success: false, error: action.err, loading: false}
        case Types.USERS_FETCH_ONE_IDENTIFICATION_SUCCEEDED:
            return {...state, user: action.user, loading: false};
        case Types.USERS_OATH_SUCCEEDED:
            return {...state, session: action.session, success: action.success, loading: false}
        case Types.USERS_LOGOUT:
            return {...state, session: null}
        case Types.USER_RESET_PASSWORD_SUCCEEDED:
            return {...state, success: true, loading: false, error: false}
        case Types.USER_RESET_PASSWORD_ERROR:
            return {...state, success: false, error: true, loading: false}
        case Types.USER_CHANGE_PASSWORD_SUCCEEDED:
            return {...state, success: true, loading: false}
        case Types.USER_CHANGE_PASSWORD_ERROR:
            return {...state, success: false, error: action.error, loading: false}
        default:
            return state;
    }
}