import { connect } from "react-redux";
import colores from "../../shared/constants/styles";
import DriveIcon from '@mui/icons-material/AddToDrive';
import IDataConsulSteel from "../../common/interface/IDataConsulSteel";

interface IDetalleProps {
    detalle: IDataConsulSteel;
}

const Detalle = (props: IDetalleProps) => {
    return (
        <div className="container" style={{ width: "100%" }}>
            <div
                style={{
                    backgroundColor: colores.grisClaro,
                    paddingTop: "1.20rem",
                    paddingBottom: "1.20rem",
                    margin: 0,
                }}>
                <h4 style={{ fontWeight: "lighter",  marginLeft: "1vw" }}>
                    DETALLE
                </h4>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "white",
                    borderRadius: "0px 0px 15px 15px",
                    paddingTop: "1.20rem",
                    paddingBottom: "1.20rem",
                }}
            >
                <DriveIcon
                    fontSize="large"
                    sx={{
                        color: props.detalle?.linkDrive ? colores.grisOscuro : colores.grisClaro,
                        marginBottom: "0.625rem",
                        cursor: props.detalle?.linkDrive ? "pointer" : "default", 
                        pointerEvents: props.detalle?.linkDrive ? "auto" : "none"
                    }}
                    onClick={() => {
                        const link = props.detalle?.linkDrive;
                        if (typeof link === 'string') {
                            window.open(link, '_blank');
                        } else {
                            console.error('Link is undefined');
                        }
                    }}
                ></DriveIcon>
                <p style={{ color: props.detalle?.linkDrive ? colores.grisOscuro : colores.grisClaro}}>Carpeta Proyecto</p>
            </div>

        </div>
    )
}

export default connect(
)(Detalle);
