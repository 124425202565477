import axios from 'axios'

const { REACT_APP_ENDPOINT } = process.env;
const ENDPOINT = `${REACT_APP_ENDPOINT}/api/reclamo/`


export default class reclamos {
    static async getReclamosByCuit(cuit) {
        const respuesta = await axios.get(`${ENDPOINT}getReclamos/${cuit}`)
        .then(response => {
            return response
        })
        return respuesta.data
    }
    static async getReclamosByFilter(filter) {
        const respuesta = await axios.post(`${ENDPOINT}searchBy`, filter )
        .then(response => {
            return response
        })  
        return respuesta.data
    }
    static async getEstadosReclamo() {
        const respuesta = await axios.get(`${ENDPOINT}getEstados`)
        .then(response => {
            return response
        })
        return respuesta.data
    }
    static async crearNuevoReclamo(reclamo) {
        const formData = new FormData();
        if(reclamo.file.length > 0) {
            for(var x = 0; x < reclamo.file.length; x++) {
                // the name has to be 'files' so that .NET could properly bind it
                formData.append('files', reclamo.file.item(x));    
            }
        } 
        const params = {
            Titulo: reclamo.titulo,
            Tipo: reclamo.tipo,
            Asunto: reclamo.asunto,
            Descripcion: reclamo.descripcion,
            NroPedido: reclamo.nroPedido,
            cuit: reclamo.cuit
        };

        const respuesta = await axios.post(ENDPOINT, formData, {
            params,
            headers: {
            'Content-Type': 'multipart/form-data',
            },
        })
        .then(response => {
            return response;
        });
        return respuesta.data;
    }
}

