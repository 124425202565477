import React, {useEffect} from 'react';
import { connect, useDispatch } from 'react-redux';
import { requestClientes, requestClientesBySeller, requestClientesSearch } from '../../actions/cliente';
import { Container, Grid } from '@material-ui/core';
import Cliente from './cliente';
import { isAllowed } from '../../shared/utils/permissions';

const Clientes = (props) => {

  const localStorage = JSON.parse(window.localStorage.getItem("LocalSession"));

  const dispatch = useDispatch()
  
  useEffect(() => {
    if(isAllowed(10)) 
      dispatch(requestClientesBySeller(localStorage.sellerId));
  }, []);


  const { clientes } = props

  const handleSearch = (value) => {
    value ? props.requestClientesSearch(value) : props.requestClientes()
  }

  return (
      <Container className="py-4" component="main" maxWidth="lg" style={{ marginLeft:"10px", paddingLeft:"68px",paddingRight:"68px" }}>
        <Grid container>
          <Grid item>
            <div className="py-5"><h1>CLIENTES</h1></div>
          </Grid>
        </Grid>
        <Grid className="row" style={{marginBottom:"2rem"}}>
          <div className="col-12 col-md-9"style={{paddingLeft:"16px"}}>
            <label htmlFor="searchCliente" className="form-label" style={{color:"gray",fontWeight:600,fontSize:"0.938rem"}}>Buscar Cliente</label>
            <input name="searchCliente" className="form-control" id="searchCliente" style={{borderColor:"black",padding:"0.625rem",maxWidth:"30rem"}} onChange={(e) => handleSearch(e.target.value)}></input>
          </div>
        </Grid>
        <Grid className="row 123">
          {!props.loading ? (
            <Grid container spacing={3} className="22" style={{marginLeft:"-9px",marginRight:"-9px"}}>
              {clientes?.map(cliente => (<Cliente key={cliente.id} data={cliente}/>))}
            </Grid>
          ) : (
            <h1>Cargando clientes...</h1>
          )}
        </Grid>
          
      </Container>
  );
}

export default connect
(
  state => ({
    clientes: state.clientes.clientes,
    loading: state.clientes.loading
  }),
  dispatch => ({
    requestClientesSearch: (search) => dispatch(requestClientesSearch(search)),
    requestClientesBySeller: () => dispatch(requestClientesBySeller(localStorage.sellerId))
  })
)(Clientes);