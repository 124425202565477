import { useState } from "react";
import { Button } from "@material-ui/core";
import colores from "../../shared/constants/styles";
import { connect } from "react-redux";
import VerDetalle from "./ver_detalle";
import IDataConsulSteel from "../../common/interface/IDataConsulSteel";

interface ICardProps {
    data: IDataConsulSteel;
}

const Card = (props: ICardProps) => {
    const { data } = props;
    const localStorageItem = window.localStorage.getItem("LocalSession");
    const localStorage = localStorageItem ? JSON.parse(localStorageItem) : null;  
    const [popUp, setPopUp] = useState(false);
    const handleClick = () => {
        setPopUp(!popUp);
    };

    return (
        <>
        {popUp && (
            <>
            <VerDetalle detalle={data} onSetPopUp={handleClick}></VerDetalle>
            </>
        )}

        <div className="bordesRedondos col-12 col-sm-7 col-md-5 col-lg-4" style={{ margin: "1.875rem 0" }}>
            <div className="bordesRedondosTop white-color p-4 text-uppercase" style={{ backgroundColor: colores.azulConsulSteel }}>
                <div>
                    <div className="atributoTitle">
                        <h5 style={{ fontWeight: "lighter" }}>
                        CÓDIGO: {" "} <strong>{`${data?.nombre}`}</strong>
                        </h5>
                    </div>
                    <div className="atributoTitle">
                        <h5 style={{ fontWeight: "lighter" }}>
                            SERVICIO: {" "}<strong>{`${data?.servicio}`}</strong>
                        </h5>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: colores.grisClaro }} className="bordesRedondosBottom p-4">
                    
                <div className="d-flex flex-row bd-highlight  justify-content-between">
                    <div className="mb-3">
                        <div className="atributoTitle" style={{ fontWeight: "bold", marginRight: "1.25rem" }}>
                            Ingreso del proyecto
                        </div>
                        <div className="d-flex flex-row bd-highlight  justify-content-between">
                            <div className="atributoDato" style={{ marginRight: "0.8rem", marginBottom: "0.8vw" }}>
                                {data?.ingreso}
                            </div>
                        </div>
                        <div className="atributoTitle" style={{ fontWeight: "bold", marginRight: "1.25rem" }}>
                            Fecha Checklist
                        </div>
                        <div className="d-flex flex-row bd-highlight  justify-content-between">
                            <div className="atributoDato" style={{ marginRight: "0.8rem", marginBottom: "0.8vw" }}>
                                {data?.checklist}
                            </div>
                        </div>
                        <div className="atributoTitle" style={{ fontWeight: "bold", marginRight: "1.25rem" }}>
                            Fecha de Entrega acordada
                        </div>
                        <div className="d-flex flex-row bd-highlight  justify-content-between">
                            <div className="atributoDato" style={{ marginRight: "0.8rem", marginBottom: "0.8vw" }}>
                                {data?.vencimiento}
                            </div>
                        </div>
                        <div className="atributoTitle" style={{ fontWeight: "bold", marginRight: "1.25rem" }}>
                            Estado
                        </div>
                        <div className="d-flex flex-row bd-highlight  justify-content-between">
                            <div className="atributoDato" style={{ marginRight: "0.8rem" }}>
                                {data?.estado}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row bd-highlight  justify-content-between">
                    <div className="mb-6">
                    <Button
                        className="my-3"
                        style={{ backgroundColor: colores.azulConsulSteel, color: "#fff" }}
                        variant="contained"
                        onClick={() => handleClick()}
                        type="button"
                    >
                        VER DETALLES
                    </Button>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};
export default connect(
//   (state) => ({
//     file: state.presupuestos.file,
//     successDownload: state.presupuestos.successDownload,
//   }),
//   (dispatch) => ({
//     requestDownloadFile: (numero, ruta) => dispatch(requestDownloadFile(numero, ruta)),
//   })
)(Card);