import { createStore,applyMiddleware, compose } from 'redux'
import reducers from './index';
import saga from 'redux-saga';
import sagas from '../sagas';
import {requestDolarToday, requestParamsDniType, requestRegions} from '../actions/params'

let store = null;
const sagaMiddleware = saga();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
    const store = createStore(
            reducers,
            initialState,
            composeEnhancers(applyMiddleware(sagaMiddleware))
        );
    sagaMiddleware.run(sagas);
    store.dispatch(requestParamsDniType())
    store.dispatch(requestRegions())
    store.dispatch(requestDolarToday())
    return store
}

export {store};