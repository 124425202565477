import axios from 'axios'

const { REACT_APP_ENDPOINT_CONSULSTEEL } = process.env;
const ENDPOINT = `${REACT_APP_ENDPOINT_CONSULSTEEL}/api/consulsteel`

export default class consulSteel {
    static async getByClienteId(clienteId) {
        const respuesta = await axios.get(`${ENDPOINT}/GetDataFromClient?clientId=${clienteId}`)
        .then(response => {
            return response
        })
        return respuesta.data
    }

    static async hasProyectByClientId(clienteId) {
        const respuesta = await axios.get(`${ENDPOINT}/HasProyect?clientId=${clienteId}`)
        .then(response => {
            return response
        })
        return respuesta.data
    }
}
