import React from 'react'

function ShopRowTitle() {
    return (
        <div className='ShoppingCart__items-container' style={{backgroundColor : "#F4F4F4", paddingTop : "30px", borderRadius : "10px"}}>
            <p className='ShoppingCart__items-1 ShopRow_col'>Producto</p>
            <p className='ShoppingCart__items-2'>Precio unidad</p>
            <p className='ShoppingCart__items-3'>Cantidad</p>
            <p className='ShoppingCart__items-4'>Subtotal</p>
            <p className='ShoppingCart__items-5'>Descuento gru. prod.</p>
            <p className='ShoppingCart__items-6 ShopRow_col'>Descuento cliente</p>
            <p className='ShoppingCart__items-7'>Total</p>
        </div>
    )
}

export default ShopRowTitle