export const Types= {
    CONSUL_STEEL_LOADING: 'CONSUL_STEEL_LOADING',

    CONSUL_STEE_BY_CLIENTE_GET_REQUEST: 'CONSUL_STEE_BY_CLIENTE_GET_REQUEST',
    CONSUL_STEE_BY_CLIENTE_GET_SUCCEEDED: 'CONSUL_STEE_BY_CLIENTE_GET_SUCCEEDED',
    CONSUL_STEE_BY_CLIENTE_GET_ERROR: 'CONSUL_STEE_BY_CLIENTE_GET_ERROR',

    HAS_PROJECT_BY_CLIENTE_GET_REQUEST: 'HAS_PROJECT_BY_CLIENTE_GET_REQUEST',
    HAS_PROJECT_BY_CLIENTE_GET_SUCCEEDED: 'HAS_PROJECT_BY_CLIENTE_GET_SUCCEEDED',
    HAS_PROJECT_BY_CLIENTE_GET_ERROR: 'HAS_PROJECT_BY_CLIENTE_GET_ERROR',
};

export const startLoading = () => ({type: Types.CONSUL_STEEL_LOADING});

export const getDataByClienteId = (clienteId) => ({type: Types.CONSUL_STEE_BY_CLIENTE_GET_REQUEST, clienteId});
export const getDataByClienteIdSucceeded = (data) => ({type: Types.CONSUL_STEE_BY_CLIENTE_GET_SUCCEEDED, data})
export const getDataByClienteIdError = (err) => ({type: Types.CONSUL_STEE_BY_CLIENTE_GET_ERROR, err})

export const hasProyectByClienteId = (clienteId) => ({type: Types.HAS_PROJECT_BY_CLIENTE_GET_REQUEST, clienteId});
export const hasProyectByClienteIdSucceeded = (hasproject) => ({type: Types.HAS_PROJECT_BY_CLIENTE_GET_SUCCEEDED, hasproject})
export const hasProyectByClienteIdError = (err) => ({type: Types.HAS_PROJECT_BY_CLIENTE_GET_ERROR, err})
