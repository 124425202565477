import React, { useEffect} from 'react';
import { connect, useDispatch } from 'react-redux';
import {
    Container,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button
} from '@material-ui/core';
import { useFormik } from "formik";
import * as Yup from "yup";
import { saveVendedor, requestVendedor } from '../../actions/vendedor';
import { useHistory, useParams } from 'react-router';

const EditarVendedor = (props) => {
    const dispatch = useDispatch();
    const { id }=useParams();
    useEffect(() => {
        dispatch(requestVendedor(id))
    }, [dispatch])

    const {docType,
        regions,
        vendedor
    } = props;

    const formik = useFormik({
        initialValues: {
            nombre: vendedor.name,
            apellido: vendedor.lastName,
            docType: vendedor.identificationType,
            numberDoc: vendedor.identification,
            telefono: vendedor.phone,
            mail: vendedor.email,
            codigoT: vendedor.totVsCode,
            region: vendedor.region
        },
        validationSchema: Yup.object({
            nombre: Yup.string().required(),
            apellido: Yup.string().required(),
            docType: Yup.string().required(),
            numberDoc: Yup.string().required(),
            telefono: Yup.string().required(),
            mail: Yup.string().required(),
            codigoT: Yup.string().required(),
            region: Yup.string().required()
    }),
        onSubmit: (formData) => {
          props.login(formData);
        }
      })
    const history = useHistory();
    const handleBack = () => {
        history.goBack();
      }
    if (props.error) return <h1>Error de conexion con el BE.</h1>;
    if (props.loading) return <h1>Cargando todo...</h1>;
    if (props.saving) return <h1>Guardando...</h1>;
    return (
        <Container className="py-4" component="main" maxWidth="lg">
            <div className="py-5"><h1>EDITAR VENDEDOR</h1></div>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={6}>
                    <Grid item xs={6}>
                        <TextField 
                            value={formik.values.nombre}
                            required
                            fullWidth
                            id="nombre"
                            label="Nombre"
                            name="nombre"
                            autoComplete="nombre"
                            onChange={formik.handleChange}
                            error={formik.errors.nombre && true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={formik.values.apellido}
                            required
                            fullWidth
                            id="apellido"
                            label="Apellido"
                            name="apellido"
                            autoComplete="apellido"
                            onChange={formik.handleChange}
                            error={formik.errors.apellido && true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="docTypeLabel">Tipo</InputLabel>
                                    <Select
                                    defaultValue="1"
                                    labelId="docTypeLabel"
                                    id="demo-simple-select"
                                    label="Tipo de documento"
                                    name="docType"
                                    required
                                    onChange={formik.handleChange}
                                    error={formik.errors.docType && true}
                                    >
                                    {docType?.map((element, index) => {
                                        return <MenuItem key={index} value={element.id}>{element.name}</MenuItem>
                                    })
                                    }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={8}>
                            <TextField
                                value={formik.values.numberDoc}
                                required
                                fullWidth
                                id="number"
                                label="Numero de documento"
                                name="number"
                                autoComplete="number"
                                onChange={formik.handleChange}
                                error={formik.errors.number && true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField  
                            value={formik.values.telefono}
                            required
                            fullWidth
                            id="telefono"
                            label="Telefono"
                            name="telefono"
                            autoComplete="telefono"
                            onChange={formik.handleChange}
                            error={formik.errors.telefono && true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField  
                            value={formik.values.mail}
                            required
                            fullWidth
                            id="mail"
                            label="Mail"
                            name="mail"
                            autoComplete="mail"
                            onChange={formik.handleChange}
                            error={formik.errors.mail && true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField  
                            value={formik.values.codigoT}
                            required
                            fullWidth
                            id="codigoT"
                            label="Código TOTVS"
                            name="codigoT"
                            autoComplete="codigoT"
                            onChange={formik.handleChange}
                            error={formik.errors.codigoT && true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel id="docTypeLabel">Región</InputLabel>
                            <Select
                            defaultValue=""
                            labelId="docTypeLabel"
                            id="region"
                            label="Región"
                            name="region"
                            required
                            onChange={formik.handleChange}
                            error={formik.errors.region && true}
                            >
                            {regions?.map((element, index) => {
                                return <MenuItem key={index} value={element.id}>{element.name}</MenuItem>
                            })
                            }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={() => handleBack()}
                        >
                            Volver
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                        >
                            Guardar
                        </Button>
                    </Grid>
                </Grid> 
            </form>
        </Container>
    );
}

export default connect(
  state => ({
    vendedor: state.vendedor.vendedor,
    saving: state.vendedor.saving,
    docType: state.params.docType,
    regions: state.params.regions,
    loading: state.params.loading || state.vendedor.loading,
    error: state.params.error
    
  }),
  dispatch => ({
    saveVendedor: (vendedor) => dispatch(saveVendedor(vendedor))    
  })
)(EditarVendedor);