import Box from "@mui/material/Box";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import muiStyles from "../../shared/constants/muiStyles";
import { ThemeProvider } from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

const MuiCustomInput = (props) => {
  const component = props.component;
  let res;

  const handleInputChangeTextField = (e) => {
    props.setValue(e.target.value);
  };
  const handleSearchProducto = () => {
    props.onSearch(props.value);
  };


  switch (component) {
    case "FormControl":
      res = (
        <FormControl
          style={{
            marginInlineEnd: "7rem",
            minWidth: "15rem",
            borderRadius: "0px",
            width: "20rem",
          }}
          sx={{
            width: "20rem",
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#C4C4C4'
            }
          }}  
        >  
          <InputLabel id="subproductoId">Subproductos</InputLabel>
          <Select
            labelId="subproductoId"
            id="subproductos"
            label="Subproductos"
            onChange={(e) => props.handleChange(e.target.value)}
          >
            <MenuItem value=''>
                TODOS
              </MenuItem>
            {props.gruposDropdown?.map((grupo) => (
              <MenuItem key={grupo?.gruposProdId} value={grupo?.gruposProdId}>
                {grupo?.gruposDesc}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
      break;
    case "TextField":
      res = (
        <FormControl >
          <InputLabel htmlFor="outlined-adornment-descripcion">Descripción</InputLabel>
          <OutlinedInput
            id="outlined-adornment-descripcion"
            type='text'
            sx={{
              width: "20rem",
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#C4C4C4'
              }
            }}        
            onChange={handleInputChangeTextField}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle visibility"
                  edge="end"
                >
                  <SearchIcon onClick={handleSearchProducto}/>
                </IconButton>
              </InputAdornment>
            }
            label="Descripcion"
            notchedOutline={{
              borderColor: "red", // Cambia el color del borde aquí
            }}
          />
        </FormControl>
      );
      break;
    default:
      res = null;
  }
  return (
    <Box sx={{ minWidth: 120 }}>
      <ThemeProvider theme={muiStyles.muiGeneralTheme(props?.flagForm)}>
        {res}
      </ThemeProvider>
    </Box>
  );
};
export default MuiCustomInput;
