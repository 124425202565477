import React, {useEffect, useState} from 'react';
import Dolar from '../../routes/dolar';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ListaProductosSugeridos from '../suggestedProducts/ProductosSugeridos'
import { requestSuggested, requestDeleteSuggested, requestSuggestedSearch  } from '../../actions/suggestedProduct';
import { Container, Grid, Button } from "@material-ui/core";
import colores from '../../shared/constants/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFormik } from "formik";
import * as Yup from "yup";

const Index = (props) => {

    const dispatch = useDispatch()

    useEffect(() => {

        dispatch(requestSuggested());
        
      }, [])

    const [filtro, setFiltro] = useState({
        IdProducto: '',
        IdProductoSugerido: ''
      })
    
      const handleInputChange = (event) => {
        setFiltro({
              ...filtro,
              [event.target.name] : event.target.value
          })
      }

    const handleDelete = (idProducto, idProductoRelacionado) => {
        var obj = {idProducto, idProductoRelacionado}
        dispatch(requestDeleteSuggested(obj));
        window.location.reload(false);
    }

    const formik = useFormik({
        initialValues: {
            IdProducto: "",
            IdProductoSugerido: "",
            IdTipoRelacion: "1"
        },
        validationSchema: Yup.object({
            IdProducto: Yup.string(),
            IdProductoSugerido: Yup.string(),
            IdTipoRelacion: Yup.number()
        }),
        onSubmit: (formData) => {
            props.requestSuggestedSearch(formData);
        }
    })
    
    return (
        <>
            <div>
                <Dolar></Dolar>
            </div>
            <div className='container-fluid px-0' style={{backgroundColor: "#EAEBF0", overflow:"hidden", clear:"both"}}>
                <div className='d-flex justify-content-between flex-wrap align-items-center' style={{marginBottom:"0.600rem"}}>
                    <h1 className="fw-bolder" style={{marginLeft:"4.7rem"}}>SUGERENCIA DE PRODUCTO</h1>
                </div>
                <div className="container-fluid" style={{backgroundColor: "white", overflow:"hidden", clear:"both", paddingRight:"78px",paddingLeft:"78px"}}>
                        <form  onSubmit={formik.handleSubmit}  className="d-flex justify-content-start align-items-end flex-wrap" style={{marginRight: "2rem", marginTop: "3rem",width:"100%"}}>
                            <div className="col-md-0 mb-3" style={{marginRight:"1rem"}}>
                                <label for="productoId" style={{color: "gray"}}>Producto</label>
                                <input type="text" className="form-control" placeholder="SKU o nombre de producto" name='IdProducto' onChange={formik.handleChange} style={{borderColor: "black", height: "2.8rem", width:"13rem"}}></input>
                            </div>
                            <div className="col-md-0 mb-3"style={{marginRight:"1rem"}} >
                                <label for="sugeridoId" style={{color: "gray"}} >Producto relacionado</label>
                                <input type="text" className="form-control" placeholder="SKU o nombre de producto" name='IdProductoSugerido' onChange={formik.handleChange} style={{borderColor: "black", height: "2.8rem",width:"13rem"}}></input>
                            </div>
                            <div className="col-md-0 mb-1">
                                <div classNames="input-group"  style={{margin: "0 0 0.938rem" }}>
                                    <button type="submit" className='btn btn-danger d-flex align-items-center justify-content-center'>Filtrar</button>
                                </div>
                            </div>
                            <div className="col-md-3 mb-3" style={{width:"10rem"}}>
                                <div classNames="input-group">
                                    <Link to="/nuevo-producto-sugerido" className='btn btn-danger d-flex align-items-center justify-content-center' style={{width: "100%", height: "2.8rem"}}>Nuevo producto sugerido</Link>
                                </div>
                            </div>
                        </form>
                        {
                            props?.suggestedProducts[0]?.sinResultado ?
                            <div class="alert alert-danger"  style={{maxWidth:"100%", display: 'flex', justifyContent: 'center', alignItems: 'center'}} role="alert">
                                No se ha encontrado el producto o la relacion de productos buscado.
                            </div>
                            : ""
                        }
                        <div className="d-flex align-items-end flex-wrap">
                            <h4 style={{marginRight: "50rem", marginTop: "2rem"}}>Productos</h4>     
                        </div>
                    <div style={{ margin: '1rem' }}>
                    <div className="d-flex flex-row flex-wrap overflow-auto" style={{backgroundColor: "#EAEBF0", borderRadius: "10px", padding: "1.5rem 0"}}>
                            <Grid className="bordesRedondos"  item xs={12} style={{padding: "0 2.5rem"}}>
                                <Grid className="bordesRedondosTop">
                                    <Grid className= "row" >
                                        <Grid item xs={4}>
                                            <p className="atributoTitle">Producto (SKU - Nombre)</p>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <p className="atributoTitle">Producto relacionado (SKU - Nombre)</p>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <p className="atributoTitle">Tipo</p>
                                        </Grid>
                                    </Grid>
                                    <div style={{ margin: "1rem" }}><hr style={{width: "100%", margin: 'auto' }}/></div>

                                    {props.suggestedProducts?.map(suggested => (
                                        <Grid className= "row" key={suggested?.sugerenciaId} >
                                            <Grid item xs={4}>
                                                <p className='d-inline'><span className='btn' style={{color: colores.rojo}} onClick={() => handleDelete(suggested?.idProducto, suggested?.idProductoRelacionado)}><DeleteIcon></DeleteIcon></span>{ suggested?.nombreProducto }</p>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <p className='d-inline'>{ suggested?.productoRelacionado }</p>
                                            </Grid> 
                                            <Grid item xs={4}>
                                                <p className='d-inline'>{ suggested?.tipoRelacion }</p>
                                            </Grid> 
                                        </Grid>
                                    ))}
                                    
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                
                </div>
            </div>
        </>
    )
}

export default connect
(
  state => ({
    suggestedProducts: state.suggestedProduct.suggestedProducts,
    loading: state.suggestedProduct.loading,
    success: state.suggestedProduct.success,
    error: state.suggestedProduct.error
  }),
  dispatch => ({
    requestSuggested: () => dispatch(requestSuggested()),
    requestDeleteSuggested: (data) => dispatch(requestDeleteSuggested(data)),
    requestSuggestedSearch: (filtro) => dispatch(requestSuggestedSearch(filtro))
  })
)(Index);