import { Types } from "../actions/novedades";

export default function novedadesReducer(
  state = { novedades: {}, novedades_menu: {}, loading: false, success: false, success_menu: false, error: false },
  action
) {
  switch (action.type) {
    case Types.NOVEDADES_GET_REQUEST:
      return { ...state, loading: true };
    case Types.NOVEDADES_GET_SUCCEEDED:
      return {
        ...state,
        novedades: action.novedades,
        loading: false,
        success: true,
        error: false,
      };
    case Types.NOVEDADES_GET_ERROR:
      return { ...state, loading: false, success: false, error: action.err };
    
    case Types.NOVEDADES_GET_MENU_REQUEST:
      return { ...state, loading: true };
    case Types.NOVEDADES_GET_MENU_SUCCEEDED:
      return {
        ...state,
        novedades_menu: action.novedades_menu,
        loading: false,
        success_menu: true,
        error: false,
      };
    case Types.NOVEDADES_GET_MENU_ERROR:
      return { ...state, loading: false, success_menu: false, error: action.err };
    default:
      return state;
  }
}
