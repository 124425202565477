import Root from './Root';
import Aplicacion from './routes';
import configureStore from './reducers/store';

const store = configureStore();

function App() {
  return (

    <div>
      <Root store={store} comp={Aplicacion}/>   
    </div>
  );
}

export default App;
