import {useEffect, useState} from 'react';
import Dolar from '../../routes/dolar';
import colores from '../../shared/constants/styles';
import { connect, useDispatch } from 'react-redux';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import { updateShoopEnabled
        , uploadListaPrecio
        , uploadBancoImagenes
        , uploadManualMarca
        , getTerminosCondiciones
        , updateTerminosCondiciones} from '../../actions/configuracion';
const { REACT_APP_ENDPOINT } = process.env;
const ENDPOINT = `${REACT_APP_ENDPOINT}/api/menu/download/`

const Configuracion = (props) => {

    const [checkedHabilitarPortal, setCheckedHabilitarPortal] = useState(JSON.parse(window.localStorage.getItem("shoopEnabled")));
    const [openDialogHabilitar, setOpenDialogHabilitar] = useState(false);
    const [titleHabilitar, setTitleHabilitar]= useState("deshabilitar");
    const [labelHabilitar, setLabelHabilitar]= useState("Desea deshabilitar la compra en Portal Ventas");
    const [formDataFile, setFormDataFile] = useState({ file: []});
    const [valueTipo, setValueTipo] = useState('ListaPrecio');
    const [loading, setLoading]= useState(false);
    const [error, setError]= useState(false);
    const [errorFormato, setErrorFormato]= useState(false);
    const [terminosCondiciones, setTerminosCondiciones]= useState("");
    const [errorTerminos, setErrorTerminos]= useState(false);

    const dispatch = useDispatch()  
    
    //Inicio
    useEffect(() => {
        dispatch(getTerminosCondiciones())
        if(checkedHabilitarPortal){
            setTitleHabilitar("habilitar")
            setLabelHabilitar("Desea deshabilitar la compra en Portal Ventas");
        } else {
            setTitleHabilitar("deshabilitar")
            setLabelHabilitar("Desea habilitar la compra en Portal Ventas");
        }
    }, [])
    
    //Function Habilitar/Deshabilitar portal
    const handleHabilitarPortal = (event) => {
        if(event.target.checked){
            setTitleHabilitar("habilitar")
            setLabelHabilitar("Desea deshabilitar la compra en Portal Ventas");
        } else {
            setTitleHabilitar("deshabilitar")
            setLabelHabilitar("Desea habilitar la compra en Portal Ventas");
        }
        setCheckedHabilitarPortal(event.target.checked);
        setOpenDialogHabilitar(true);
    };
    
    const handleCloseHabilitarPortal = () => {
        setOpenDialogHabilitar(false);
        setCheckedHabilitarPortal(!checkedHabilitarPortal);
        if(!checkedHabilitarPortal){
            setLabelHabilitar("Desea deshabilitar la compra en Portal Ventas");
        } else {
            setLabelHabilitar("Desea habilitar la compra en Portal Ventas");
        }
    };

    const handleConfirmarHabilitarPortal = () => {
        setOpenDialogHabilitar(false);
        dispatch(updateShoopEnabled({
            shoopEnabled: checkedHabilitarPortal
        }));
        localStorage.setItem('shoopEnabled', checkedHabilitarPortal);
    };

    //Function UploadFiles
    const handleChangeTipo = (event) => {
        setValueTipo(event.target.value);
    };

    const handleFileChange = (e) => {
        setFormDataFile({
            ...formDataFile,
            file: e.target.files
        });
    };

    const handleClick = () => {
         // Validar si hay algún archivo seleccionado
        if (formDataFile.file.length === 0) {
            // Muestra un mensaje de error o toma la acción correspondiente
            setError(true)
            return;
        }

        // Validar la extensión del archivo
        const allowedExtensions = ["zip"];
        const fileExtension = formDataFile.file[0].name.split(".").pop().toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
            // Muestra un mensaje de error o toma la acción correspondiente
            setErrorFormato(true);
            return;
        }
        setErrorFormato(false);
        // Continuar con la lógica de subida según el tipo de archivo
        if (valueTipo === "ListaPrecio")
            dispatch(uploadListaPrecio(formDataFile));
        if (valueTipo === "ManualMarca")
            dispatch(uploadManualMarca(formDataFile));
        if (valueTipo === "BancoImagenes")
            dispatch(uploadBancoImagenes(formDataFile));       
    }

    const handleDownloadd = () => {
        setLoading(true);
        let nombre= "";
        if(valueTipo === "ListaPrecio")
            nombre='listaprecios';
        if(valueTipo === "ManualMarca")
            nombre='manualmarca';
        if(valueTipo === "BancoImagenes")
            nombre='bancoimagenes';

        let link= `${ENDPOINT}${nombre}`
        const fileName = `${nombre}.zip`; 
        fetch(link)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const a = document.createElement("a");
                a.href = url;
                a.download = fileName;
                a.click();
                setLoading(false);
            })
            .catch(error => console.error("Error downloading file:", error));
    };

    useEffect(() => {
        if(props.successGetTerminos && !props.loading){
            setTerminosCondiciones(props.terminos)
        }
    }, [props.successGetTerminos, props.loading])

    useEffect(() => {
        if(props.successTerminos && !props.loading){
            setTerminosCondiciones(terminosCondiciones);
        }
    }, [props.successTerminos, props.loading])
    
    const handleChangeTerminos = (event) => {
        if((event.target.value).length <= 0){
            setErrorTerminos(true);
        } else {
            setErrorTerminos(false);
        }
        setTerminosCondiciones(event.target.value);
      };

    const handleClickTerminos = () => {
        if(terminosCondiciones.length <= 0){
            setErrorTerminos(true);
            return;
        }
        setErrorTerminos(false);
        dispatch(updateTerminosCondiciones({
            termsConditions: terminosCondiciones
          }));
        setTerminosCondiciones(terminosCondiciones);
    }

    // useEffect(() => {
    //     if(props.success && !props.loading){
    //         alert("creados")
    //     }
    // }, [props.success, props.loading])


    
    return (
        <>
            <div>
                <Dolar></Dolar>
            </div>
            <div className='container-fluid px-0' style={{backgroundColor: "#EAEBF0", overflow:"hidden", clear:"both"}}>
                <div className='d-flex justify-content-between flex-wrap align-items-center' style={{marginBottom:"0.600rem"}}>
                    <h1 className="fw-bolder" style={{marginLeft:"4.7rem"}}>CONFIGURACIÓN</h1>
                </div>
               
                <div className="container-fluid" style={{backgroundColor: "white", overflow:"hidden", clear:"both", paddingRight:"78px",paddingLeft:"78px"}}>
                    { (props.loading || loading) &&
                        <div className="col-12" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2vw' }}>
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress />
                            </Box>
                        </div>
                    } 
                     {/* Configuración para switch habilitar o deshabilitar compra en portal ventas */}
                    <FormGroup style={{margin: '2vw'}}>
                        <FormControlLabel 
                            control={
                                <Switch
                                    checked={checkedHabilitarPortal}
                                    onChange={handleHabilitarPortal}                                    
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />} 
                                label={labelHabilitar}
                            />
                    </FormGroup>
                    { props.success && !props.loading  &&
                        <Stack sx={{ width: '100%', margin: '2vw' }} spacing={2}>
                            <Alert severity="success">¡Configurado correctamente!</Alert>
                        </Stack> 
                    }
                    <Divider variant="middle" />

                    {/* Configuración subida de archivos- Lista de precios, imagenes, manual de marca */}
                    <div className="row" style={{width: "100%", margin: '2vw'}}>
                        <div className="col-2">
                            <FormControl>
                                <div style={{display: "flex", fontSize: "20px", marginTop: "2vw"}}>Tipo de archivo</div>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={valueTipo}
                                    onChange={handleChangeTipo}
                                >
                                    <FormControlLabel value="ListaPrecio" control={<Radio />} label="Lista de precio" />
                                    <FormControlLabel value="ManualMarca" control={<Radio />} label="Manual de marca" />
                                    <FormControlLabel value="BancoImagenes" control={<Radio />} label="Banco de imágenes" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className="col-2">
                            <div style={{display: "flex", fontSize: "20px", marginTop: "2vw"}}>Subir archivo</div>
                            <input
                                id="file-input"
                                type="file"
                                multiple
                                style={{ border: '1px solid #ccc', display: 'inline-block', padding: '6px 12px', cursor: 'pointer', borderRadius: '5px', marginTop: '16px' }}
                                // style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </div>  
                        <div className="col-2" style={{ marginTop:'7.4vh' }}>
                            <Button
                                className="my-3"
                                style={{ backgroundColor: colores.rojo, color: '#fff', float: 'right' }}
                                variant="contained"
                                onClick={() => handleClick()}
                                type="button"
                            >
                                SUBIR ARCHIVO
                            </Button>
                        </div>  
                        <div className="col-6" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2vw' }}>
                            <div style={{ fontSize: '20px' }}>Descargar archivo</div>
                                <IconButton
                                    aria-label="toggle visibility"
                                    style={{ fontSize: '40px' }} // Ajusta el tamaño del icono aquí
                                >
                                    <DownloadForOfflineIcon
                                        onClick={() => handleDownloadd()}
                                        style={{ fontSize: 'inherit' }} // Ajusta el tamaño del icono aquí si es necesario
                                    />
                                </IconButton>
                            </div>          
                    </div>
                    { props.successFile && !props.loading  &&
                        <Stack sx={{ width: '100%', margin: '2vw' }} spacing={2}>
                            <Alert severity="success">¡Archivo subido correctamente!</Alert>
                        </Stack> 
                    } 
                    { errorFormato  &&
                        <Stack sx={{ width: '100%', margin: '2vw' }} spacing={2}>
                            <Alert severity="error">El archivo debe ser de formato .zip</Alert>
                        </Stack> 
                    } 
                    { error  &&
                        <Stack sx={{ width: '100%', margin: '2vw' }} spacing={2}>
                            <Alert severity="error">Debes seleccionar un archivo.</Alert>
                        </Stack> 
                    } 

                    <Divider variant="middle" />

                    <div className="row" style={{width: "100%", margin: '2vw'}}>
                        <div className="col-8">
                            <FormControl>
                                <div style={{display: "flex", fontSize: "20px", marginTop: "2vw"}}>Términos y condiciones</div>
                                <TextField style={{width: '30vw'}}
                                    id="standard-multiline-flexible"
                                    multiline
                                    maxRows={4}
                                    onChange={handleChangeTerminos}  // Agrega el evento onChange
                                    value={terminosCondiciones}
                                    variant="standard"
                                    />
                            </FormControl>
                        </div>  
                        <div className="col-2" style={{ marginTop:'7.4vh' }}>
                            <Button
                                className="my-3"
                                style={{ backgroundColor: colores.rojo, color: '#fff', float: 'right' }}
                                variant="contained"
                                disabled={errorTerminos}
                                onClick={() => handleClickTerminos()}
                                type="button"
                            >
                                GUARDAR CAMBIOS
                            </Button>
                        </div>  
                        { props.successTerminos && !props.loading  &&
                            <Stack sx={{ width: '100%', margin: '2vw' }} spacing={2}>
                                <Alert severity="success">¡Términos y condiciones modificado correctamente!</Alert>
                            </Stack> 
                        } 
                        { errorTerminos  &&
                            <Stack sx={{ width: '100%', margin: '2vw' }} spacing={2}>
                                <Alert severity="error">Debe completar el texto.</Alert>
                            </Stack> 
                        }       
                    </div>

                    <Divider variant="middle" />
                </div>
            </div>

            <Dialog
                    open={openDialogHabilitar}
                    onClose={handleCloseHabilitarPortal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Deshabilitar/Habilitar compra"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            ¿Está seguro que desea {titleHabilitar} la compra en Portal Ventas?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseHabilitarPortal}>Cancelar</Button>
                        <Button onClick={handleConfirmarHabilitarPortal} autoFocus>Confirmar</Button>
                    </DialogActions>
            </Dialog>
        </>
    )
}

export default connect
(
  state => ({
    success: state.configuracion.success,
    successFile: state.configuracion.successFile,
    successGetTerminos: state.configuracion.successGetTerminos,
    successTerminos: state.configuracion.successTerminos,
    terminos: state.configuracion.terminos,
    loading: state.configuracion.loading
  }),
  dispatch => ({
    updateShoopEnabled: (enabled)=> dispatch(updateShoopEnabled(enabled)),
    updateTerminosCondiciones: (terminos)=> dispatch(updateTerminosCondiciones(terminos)),
    getTerminosCondiciones: ()=> dispatch(getTerminosCondiciones()),
    uploadListaPrecio: (file) => dispatch(uploadListaPrecio(file)),
    uploadBancoImagenes: (file) => dispatch(uploadBancoImagenes(file)),
    uploadManualMarca: (file) => dispatch(uploadManualMarca(file))
  })
)(Configuracion);