import { Types } from '../actions/params';

const defaultParams=[
    {
        id:'1',
        name: 'DNI'
    }
]

export default function paramsReducer(state = {params: defaultParams, loading: false}, action) {
    switch (action.type) {
        case Types.PARAMS_LOADING:
            return {...state, loading: true};
        case Types.KILOS_FETCH_SUCCEEDED:
            return {...state, kilos: action.kilos, loading: false};
        case Types.KILOS_FETCH_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.PARAMS_FETCH_SUCCEEDED:
            return {...state, docType: action.params, loading: false};
        case Types.PARAMS_FETCH_REGIONS_SUCCEEDED:
            return {...state, regions: action.regions, loading: false};
        case Types.PARAMS_FETCH_REGIONS_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.PARAMS_FETCH_GRUPOS_SUCCEEDED:
            return {...state, grupos: action.grupos, loading: false};
        case Types.PARAMS_FETCH_GRUPOS_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.PARAMS_DOLARTODAY_SUCCEEDED:
            return {...state, dolar: action.dolar, loading: false};
        case Types.PARAMS_DOLARTODAY_ERROR:
            return {...state, error: action.err, loading: false};
        default:
            return state;
    }
}
