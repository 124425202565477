import { Types } from '../actions/configuracion';

export default function reclamosReducer(state = {
    loading: false
    , terminos: ""
    , success: false
    , successFile: false
    , successGetTerminos:false
    , successTerminos:false
    , error: false
}, action) {
    switch (action.type) {
        case Types.CONFIGURACION_LOADING:
            return {...state, loading: true }
        //Habilitar/Deshabilitar compra
        case Types.CONFIGURACION_UPDATE_SHOOP_ENABLED_REQUEST:
            return {...state, loading: true }
        case Types.CONFIGURACION_UPDATE_SHOOP_ENABLED_SUCCEEDED:
            return {...state, loading: false, success: true, successFile: false, error: false};
        case Types.CONFIGURACION_UPDATE_SHOOP_ENABLED_ERROR:
            return {...state, loading: false, success: false, successFile: false, error: action.err,};
        //Cargar Lista de precio
        case Types.CONFIGURACION_UPLOAD_LISTA_PRECIO:
            return {...state, loading: true }
        case Types.CONFIGURACION_UPLOAD_LISTA_PRECIO_SUCCEEDED:
            return {...state, loading: false, success: false, successFile: true, error: false};
        case Types.CONFIGURACION_UPLOAD_LISTA_PRECIO_ERROR:
            return {...state, loading: false, success: false, successFile: false, error: action.err,};
        //Cargar banco de imagenes
        case Types.CONFIGURACION_UPLOAD_BANCO_IMAGENES:
            return {...state, loading: true }
        case Types.CONFIGURACION_UPLOAD_BANCO_IMAGENES_SUCCEEDED:
            return {...state, loading: false, success: false, successFile: true, error: false};
        case Types.CONFIGURACION_UPLOAD_BANCO_IMAGENES_ERROR:
            return {...state, loading: false, success: false, successFile: false, error: action.err,};
        //Cargar manual de marca
        case Types.CONFIGURACION_UPLOAD_MANUAL_MARCA:
            return {...state, loading: true }
        case Types.CONFIGURACION_UPLOAD_MANUAL_MARCA_SUCCEEDED:
            return {...state, loading: false, success: false, successFile: true, error: false};
        case Types.CONFIGURACION_UPLOAD_MANUAL_MARCA_ERROR:
            return {...state, loading: false, success: false, successFile: false, error: action.err,};
        //Get terminos y condiciones
        case Types.CONFIGURACION_GET_TERMINOS_CONDICIONES:
            return {...state, loading: true }
        case Types.CONFIGURACION_GET_TERMINOS_CONDICIONES_SUCCEEDED:
            return {...state, terminos: action.terminos, loading: false, successGetTerminos: true, error: false};
        case Types.CONFIGURACION_GET_TERMINOS_CONDICIONES_ERROR:
            return {...state, loading: false, success: false, successGetTerminos: false, error: action.err,};
        //Update terminos y condiciones
        case Types.CONFIGURACION_UPDATE_TERMINOS_CONDICIONES:
            return {...state, loading: true }
        case Types.CONFIGURACION_UPDATE_TERMINOS_CONDICIONES_SUCCEEDED:
            return {...state, loading: false, successTerminos: true, error: false};
        case Types.CONFIGURACION_UPDATE_TERMINOS_CONDICIONES_ERROR:
            return {...state, loading: false, successTerminos: false, error: action.err,};
        default:
            return state;
    }
}
