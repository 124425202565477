import { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import colores from "../../shared/constants/styles";
import { connect, useDispatch } from "react-redux";
import { getAcopiosDetalle } from "../../actions/acopios";

const Acopio = (props) => {
  const dispatch = useDispatch();
  const [acopiosDetalle, setAcopiosDetalle] = useState([]);
  const [popUp, setPopUp] = useState(false);
  const handleVerDetalle = () => {
    setPopUp(true);
    dispatch(getAcopiosDetalle(props.data?.nroAcopio));
  };
  const handleClick = () => {
    setPopUp(false);
  };

  useEffect(() => {
    if (props.acopiosDetalle) {
      setAcopiosDetalle(props.acopiosDetalle?.data.$values);
    }
  }, [props.acopiosDetalle]);

  return (
    <>
      {popUp ? (
        <>
          <div
            style={{
              height: "100%",
              width: "100%",
              position: "fixed",
              left: 0,
              top: 0,
              zIndex: "1",
              backgroundColor: "black",
              filter: "alpha(opacity=75)",
              opacity: 0.75,
            }}
          ></div>
          <div
            className="container"
            style={{
              position: "fixed",
              left: "50%",
              top: "25%",
              transform: "translate(-50%, -25%)",
              bottom: "0",
              zIndex: "2",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <div class="container" style={{ width: "100%" }}>
              <div
                style={{
                  backgroundColor: colores.grisClaro,
                  paddingTop: "1.20rem",
                  paddingBottom: "1.20rem",
                  borderRadius: "15px 15px 0 0",
                }}
              >
                <button
                  onClick={handleClick}
                  className="float-end"
                  style={{
                    position: "absolute",
                    right: "100px",
                    border: "none",
                  }}
                >
                  X
                </button>
                <h4 className="text-center" style={{ fontWeight: "lighter" }}>
                  DETALLES
                </h4>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "white",
                  borderRadius: "0px 0px 15px 15px",
                  paddingTop: "1.20rem",
                  paddingBottom: "1.20rem",
                }}
              >
                <div
                  class="row row-cols-12 row-cols-sm-12 row-cols-md-12"
                  style={{ width: "100%" }}
                >
                  <div class="col">
                    <p className="text-center">FECHA</p>
                  </div>
                  <div class="col">
                    <p className="text-center">DESCRIPCIÓN</p>
                  </div>
                  <div class="col">
                    <p className="text-center">IMPORTE EN PESOS</p>
                  </div>
                  {/* <div class="col">
                    <p className="text-center">SALDO EN PESOS</p>
                  </div> */}
                  <div class="col">
                    <p className="text-center">COTIZACIÓN</p>
                  </div>
                  <div class="col">
                    <p className="text-center">IMPORTE EN DÓLARES</p>
                  </div>
                </div>
                {acopiosDetalle.map((detalle) => (
                  <div
                    class="row row-cols-12 row-cols-sm-12 row-cols-md-12"
                    style={{ width: "100%" }}
                  >
                    <div class="col" style={{ fontWeight: "bold" }}>
                      <p className="text-center">{detalle.fecha}</p>
                    </div>
                    <div class="col" style={{ fontWeight: "bold" }}>
                      <p className="text-center">{detalle.descripcion}</p>
                    </div>
                    <div class="col" style={{ fontWeight: "bold" }}>
                      <p className="text-center">$ {detalle.importe}</p>
                    </div>
                    {/* <div class="col" style={{ fontWeight: "bold" }}>
                      <p className="text-center">$ {detalle.saldo}</p>
                    </div> */}
                    <div class="col" style={{ fontWeight: "bold" }}>
                      <p className="text-center">$ {detalle.tasa}</p>
                    </div>
                    <div class="col" style={{ fontWeight: "bold" }}>
                      <p className="text-center">
                        U$D {detalle.importeDolares}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      <div
        className="bordesRedondos col-12 col-sm-7 col-md-5 col-lg-4"
        style={{ margin: "1.875rem 0" }}
      >
        <div
          className="bordesRedondosTop white-color p-4 text-uppercase"
          style={{ backgroundColor: colores.azulOscuro }}
        >
          <div>
            <div>
              <div className="atributoTitle">
                <h4 style={{ fontWeight: "lighter" }}>
                  NRO DE ACOPIO: <strong>{`${props.data?.nroAcopio}`}</strong>
                </h4>
              </div>
              <div className="atributoTitle">
                <h4 style={{ fontWeight: "lighter" }}>
                  FECHA DE EMISIÓN:{" "}
                  <strong>{`${props.data?.fechaEmision}`}</strong>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ backgroundColor: colores.grisClaro }}
          className="bordesRedondosBottom p-4"
        >
          <div className="d-flex flex-row bd-highlight">
            <div
              className="mb-3"
              style={{ fontWeight: "bold", marginRight: "1.25rem" }}
            >
              <h3 style={{ fontWeight: "bold" }} className="atributoDato">
                COTIZACIÓN:{" "}
              </h3>
              <h3 style={{ fontWeight: "bold" }} className="atributoDato">
                SALDO ACOPIO EN U$D:
              </h3>
            </div>
            <div className="mb-3">
              <h3 className="atributoDato">$ {props.data?.cotizacion}</h3>
              <h3 className="atributoDato">U$D {props.data?.saldoDolares}</h3>
            </div>
          </div>

          <div>
            <hr className="hr-pedidos"></hr>
          </div>
          <div className="d-flex flex-row bd-highlight  justify-content-between">
            <div className="mb-5">
              <div
                className="atributoTitle"
                style={{ fontWeight: "bold", marginRight: "1.25rem" }}
              >
                ÚLTIMA ACTUALIZACIÓN
              </div>
              <div className="d-flex flex-row bd-highlight  justify-content-between">
                <div className="atributoDato" style={{ marginRight: "0.8rem" }}>
                  {props.data?.lastUpdate}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row bd-highlight  justify-content-between">
            <div className="mb-6">
              <Button
                className="my-3"
                style={{ backgroundColor: colores.rojo, color: "#fff" }}
                variant="contained"
                onClick={() => handleVerDetalle()}
                type="button"
              >
                VER DETALLES
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default connect(
  (state) => ({
    acopiosDetalle: state.acopios.acopiosDetalle,
    estados: state.acopios.estados,
    loading: state.acopios.loading,
  }),
  (dispatch) => ({
    getAcopiosDetalle: (nroAcopio) => dispatch(getAcopiosDetalle(nroAcopio)),
  })
)(Acopio);
