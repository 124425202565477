import axios from "axios";

const { REACT_APP_ENDPOINT } = process.env;
const ENDPOINT = `${REACT_APP_ENDPOINT}/api/novedades/`;

export default class novedades {
  static async getNovedadesByClienteId(clienteId) {
    const respuesta = await axios
      .get(`${ENDPOINT}getNovedades/${clienteId}`)
      .then((response) => {
        return response;
      });
    return respuesta.data;
  }
  
  static async getNovedadesMenuByClienteId(clienteId) {
    const respuesta = await axios
      .get(`${ENDPOINT}getNovedadesMenu/${clienteId}`)
      .then((response) => {
        return response;
      });
    return respuesta.data;
  }
}
