import {call, put, takeEvery, fork} from 'redux-saga/effects'

import * as actions from '../actions/params';
import params from '../services/params';

function* fetchParamsTypeDni() {
    yield put(actions.startLoading());
    try {
        const Params = yield call(params.findDniTyps);
        yield put(actions.receiveParamsDniType(Params));
    } catch (err) {
        console.log(err)
    }
}

function* fetchRegions() {
    yield put(actions.startLoading());
    try {
        const regions = yield call(params.findRegions);
        yield put(actions.receiveRegionsSucceeded(regions));
    } catch (err) {
        yield put(actions.receiveRegionsError(err));
        console.log(err)
    }
}

function* fetchParamsGrupos() {
    yield put(actions.startLoading());
    try {
        const grupos = yield call(params.findGruposProductos);
        yield put(actions.receiveGruposSucceeded(grupos));
    } catch (err) {
        yield put(actions.receiveGruposError(err));
        console.log(err)
    }
}

function* fetchParamsDolar() {
    yield put(actions.startLoading());
    try {
        const dolar = yield call(params.fetchDolar);
        yield put(actions.receiveDolarTodaySucceeded(dolar));
    } catch (err) {
        yield put(actions.receiveDolarTodayError(err));
        console.log(err)
    }
}

function* KilosRequest(data) {
    yield put(actions.startLoading());
    try {
        const response = yield call(params.kilosRequest, data);
        yield put(actions.receiveKilos(response));
    } catch (err) {
        yield put(actions.receiveKilosError(err));
        console.log(err)
    }
}

function*  watchGetKilosRequest() {
    yield takeEvery(actions.Types.KILOS_FETCH_REQUESTED, KilosRequest)
}

function*  watchGetTypeDniRequest() {
    yield takeEvery(actions.Types.PARAMS_FETCH_REQUESTED, fetchParamsTypeDni)
}

function*  watchGetRegionsRequest() {
    yield takeEvery(actions.Types.PARAMS_FETCH_REGIONS_REQUESTED, fetchRegions)
}

function*  watchGetGruposProductosRequest() {
    yield takeEvery(actions.Types.PARAMS_FETCH_REQUESTED, fetchParamsGrupos)
}

function*  watchGetDolarTodayRequest() {
    yield takeEvery(actions.Types.PARAMS_DOLARTODAY_REQUESTED, fetchParamsDolar)
}

const paramsSagas = [
    fork(watchGetTypeDniRequest),
    fork(watchGetRegionsRequest),
    fork(watchGetGruposProductosRequest),
    fork(watchGetDolarTodayRequest),
    fork(watchGetKilosRequest)
]

export default paramsSagas;