import { connect } from "react-redux";
import colores from "../../shared/constants/styles";
import IDataConsulSteel from "../../common/interface/IDataConsulSteel";
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import CalculateIcon from '@mui/icons-material/Calculate';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface IEstadoDetalleProps {
    detalle: IDataConsulSteel;
    onSetPopUp: () => void;
}

const EstadoDetalle = (props: IEstadoDetalleProps) => {
    return (
        <div className="container" style={{ width: "100%" }}>
            <div style={{
                    backgroundColor: colores.grisClaro,
                    paddingTop: "1.20rem",
                    paddingBottom: "1.20rem",
                    borderRadius: "15px 15px 0 0",
            }}>
                <button
                    onClick={() => props.onSetPopUp()}
                    className="float-end"
                    style={{
                        backgroundColor: colores.grisClaro,
                        position: "absolute",
                        right: "100px",
                        border: "none",
                        cursor: "pointer"
                    }}
                >X
                </button>
                <h4 style={{ fontWeight: "lighter" , marginLeft: "1vw"}}>
                    ESTADO
                </h4>
            </div>
            <div
                style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "white",
                }}>
                <div
                    className="row row-cols-1 row-cols-sm-4 row-cols-md-6"
                    style={{ width: "80%" }}>
                    <div className="col" style={{ padding: "1.25rem" }}>
                        <div
                            style={
                                props.detalle?.estado?.trim() === "Checklist"
                                ? {
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    color: colores.naranjaConsulSteel,
                                    borderRadius: "6.25rem",
                                    }
                                : {
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    }
                            }
                        >
                        <PlaylistAddCheckCircleIcon
                            fontSize="large"
                            sx={
                                props.detalle?.estado?.trim() === "Checklist"
                                ? {
                                    color: colores.naranjaConsulSteel,
                                    marginBottom: "0.625rem",
                                }
                                : {
                                    color: colores.grisOscuro,
                                    marginBottom: "0.625rem",
                                }
                            }
                        ></PlaylistAddCheckCircleIcon>
                        <p style={{textAlign: "center"}}>Checklist</p>
                    </div>
                </div>
                <div className="col" style={{ padding: "1.25rem" }}>
                    <div
                        style={
                            props.detalle?.estado?.trim() === "Cálculo"
                        ? {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            color: colores.naranjaConsulSteel,
                            borderRadius: "6.25rem",
                            }
                        : {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            }
                        }
                    >
                        <CalculateIcon
                            fontSize="large"
                            sx={
                                props.detalle?.estado?.trim() === "Cálculo"
                                ? {
                                    color: colores.naranjaConsulSteel,
                                    marginBottom: "0.625rem",
                                }
                                : {
                                    color: colores.grisOscuro,
                                    marginBottom: "0.625rem",
                                }
                            }
                        ></CalculateIcon>
                        <p style={{textAlign: "center"}}>Cálculo</p>
                    </div>
                </div>
                <div className="col" style={{ padding: "1.25rem" }}>
                    <div
                        style={
                            props.detalle?.estado?.trim() === "Modelado arq."
                            ? {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                color: colores.naranjaConsulSteel,
                                borderRadius: "6.25rem",
                            }
                            : {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                            }
                        }
                    >
                        <ArchitectureIcon
                        fontSize="large"
                        sx={
                            props.detalle?.estado?.trim() === "Modelado arq."
                            ? {
                                color: colores.naranjaConsulSteel,
                                marginBottom: "0.625rem",
                                }
                            : {
                                color: colores.grisOscuro,
                                marginBottom: "0.625rem",
                                }
                        }
                        ></ArchitectureIcon>
                        <p style={{textAlign: "center"}}>Modelado arq.</p>
                    </div>
                </div>
                <div className="col" style={{ padding: "1.25rem" }}>
                    <div
                        style={
                            props.detalle?.estado?.trim() === "Modelado estr." 
                            ? {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                color: colores.naranjaConsulSteel,
                                borderRadius: "6.25rem",
                            }
                            : {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                            }
                        }>
                        <AutoAwesomeMotionIcon
                        fontSize="large"
                        sx={
                            props.detalle?.estado?.trim() === "Modelado estr." 
                            ? {
                                color: colores.naranjaConsulSteel,
                                marginBottom: "0.625rem",
                                }
                            : {
                                color: colores.grisOscuro,
                                marginBottom: "0.625rem",
                                }
                        }
                        ></AutoAwesomeMotionIcon>
                        <p style={{textAlign: "center"}}>Modelado estr.</p>
                    </div>
                </div>
                <div className="col" style={{ padding: "1.25rem" }}>
                    <div
                        style={
                            props.detalle?.estado?.trim() === "Documentación Montaje" 
                            ? {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                color: colores.naranjaConsulSteel,
                                borderRadius: "6.25rem",
                            }
                            : {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                            }
                        }>
                        <FolderOpenIcon
                        fontSize="large"
                        sx={
                            props.detalle?.estado?.trim() === "Documentación Montaje" 
                            ? {
                                fontSize: 39,
                                color: colores.naranjaConsulSteel,
                                marginBottom: "0.32rem",
                                }
                            : {
                                fontSize: 39,
                                color: colores.grisOscuro,
                                marginBottom: "0.32rem",
                                }
                        }
                        ></FolderOpenIcon>
                        <p style={{textAlign: "center"}}>Documentación montaje</p>
                    </div>
                </div>
                    <div className="col" style={{ padding: "1.25rem" }}>
                        <div
                            style={
                                props.detalle?.estado?.trim() === "Terminado" 
                                ? {
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    color: colores.naranjaConsulSteel,
                                    borderRadius: "6.25rem",
                                }
                                : {
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }
                            }>
                            <CheckCircleOutlineIcon
                            fontSize="large"
                            sx={
                                props.detalle?.estado?.trim() === "Terminado" 
                                ? {
                                    color: colores.naranjaConsulSteel,
                                    marginBottom: "0.625rem",
                                    }
                                : {
                                    color: colores.grisOscuro,
                                    marginBottom: "0.625rem",
                                    }
                            }
                            ></CheckCircleOutlineIcon>
                            <p style={{textAlign: "center"}}>Terminado</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(EstadoDetalle);
