import {call, put, takeEvery, fork} from 'redux-saga/effects';
import * as actions from '../actions/cuentacorriente';
import cuentacorriente from '../services/cuentacorriente';

function* getByPresupuestoId({clienteId, presupuestoId}) {
    yield put(actions.startLoading());
    try {
        const response = yield call(cuentacorriente.getByPresupuestoId, clienteId, presupuestoId);
        if(response){
            yield put(actions.getEstadoPedidoByPresupuestoIdSucceeded(response));
        }
    } catch (err) {
        yield put(actions.getEstadoPedidoByPresupuestoIdError(err));
        console.log(err)
    }
}

function* getEstadoPedidosByFilter({filter}) {
    yield put(actions.startLoading());
    try {
        const response = yield call(cuentacorriente.getEstadoPedidosByFilter, filter);
        if(response){
            yield put(actions.getEstadoPedidoByFilterSucceeded(response));
        }
    } catch (err) {
        yield put(actions.getEstadoPedidoByFilterError(err));
        console.log(err)
    }
}

function* getSaldosByFilter({filter}) {
    yield put(actions.startLoading());
    try {
        const response = yield call(cuentacorriente.getSaldosByFilter, filter);
        if(response){
            yield put(actions.getSaldosByFilterSucceeded(response));
        }
    } catch (err) {
        yield put(actions.getSaldosByFilterError(err));
        console.log(err)
    }
}

function* getComposicionByFilter({filter}) {
    yield put(actions.startLoading());
    try {
        const response = yield call(cuentacorriente.getComposicionByFilter, filter);
        if(response){
            yield put(actions.getComposicionByFilterSucceeded(response));
        }
    } catch (err) {
        yield put(actions.getComposicionByFilterError(err));
        console.log(err)
    }
}

function* getFacturasByFilter({filter}) {
    yield put(actions.startLoading());
    try {
        const response = yield call(cuentacorriente.getFacturasByFilter, filter);
        if(response){
            yield put(actions.getFacturasByFilterSucceeded(response));
        }
    } catch (err) {
        yield put(actions.getFacturasByFilterError(err));
        console.log(err)
    }
}

function* getEstados() {
    yield put(actions.startLoading());
    try {
        const response = yield call(cuentacorriente.getEstados);
        if(response){
            yield put(actions.getEstadosSucceeded(response));
        }
    } catch (err) {
        yield put(actions.getEstadosError(err));
        console.log(err)
    }
}

function*  watchGetByPresupuestoId() {
    yield takeEvery(actions.Types.ESTADO_PEDIDO_PRESUPUESTO_GET_REQUEST, getByPresupuestoId);
}
function*  watchGetEstadoPedidosByFilter() {
    yield takeEvery(actions.Types.ESTADO_PEDIDO_FILTER_GET_REQUEST, getEstadoPedidosByFilter);
}
function*  watchGetSaldosByFilter() {
    yield takeEvery(actions.Types.SALDOS_FILTER_GET_REQUEST, getSaldosByFilter);
}
function*  watchGetComposicionByFilter() {
    yield takeEvery(actions.Types.COMPOSICION_FILTER_GET_REQUEST, getComposicionByFilter);
}
function*  watchGetFacturasByFilter() {
    yield takeEvery(actions.Types.FACTURA_FILTER_GET_REQUEST, getFacturasByFilter);
}
function*  watchGetEstados() {
    yield takeEvery(actions.Types.ESTADOS_GET_REQUEST, getEstados);
}

const cuentacorrienteSagas = [
    fork(watchGetByPresupuestoId),
    fork(watchGetEstadoPedidosByFilter),
    fork(watchGetSaldosByFilter),
    fork(watchGetComposicionByFilter),
    fork(watchGetFacturasByFilter),
    fork(watchGetEstados)
]

export default cuentacorrienteSagas;