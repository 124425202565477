export const Types= {
    VENDEDOR_LOADING: 'VENDEDOR_LOADING',
    
    VENDEDORES_FETCH_REQUESTED: 'VENDEDORES_FETCH_REQUESTED',
    VENDEDORES_FETCH_SUCCEEDED: 'VENDEDORES_FETCH_SUCCEEDED',
    VENDEDORES_FETCH_ERROR: 'VENDEDORES_FETCH_ERROR',
    
    VENDEDORES_FIND_REQUESTED: 'VENDEDORES_FIND_REQUESTED',
    VENDEDORES_FIND_SUCCEEDED: 'VENDEDORES_FIND_SUCCEEDED',
    VENDEDORES_FIND_ERROR: 'VENDEDORES_FIND_ERROR',
    
    VENDEDOR_SAVE_REQUESTED: 'VENDEDOR_SAVE_REQUESTED',
    VENDEDOR_SAVE_SUCCEEDED: 'VENDEDOR_SAVE_SUCCEEDED',
    VENDEDOR_SAVE_ERROR: 'VENDEDOR_SAVE_ERROR',
    
    VENDEDOR_EDIT_REQUESTED: 'VENDEDOR_EDIT_REQUESTED',
    VENDEDOR_EDIT_SUCCEEDED: 'VENDEDOR_EDIT_SUCCEEDED',
    VENDEDOR_EDIT_ERROR: 'VENDEDOR_EDIT_ERROR',
    
    VENDEDOR_DELETE_REQUESTED: 'VENDEDOR_DELETE_REQUESTED',
    VENDEDOR_DELETE_SUCCEEDED: 'VENDEDOR_DELETE_SUCCEEDED',
    VENDEDOR_DELETE_ERROR: 'VENDEDOR_DELETE_ERROR',
    
    VENDEDORES_SEARCH_REQUESTED: 'VENDEDORES_SEARCH_REQUESTED',
    VENDEDORES_SEARCH_SUCCEEDED: 'VENDEDORES_SEARCH_SUCCEEDED',
    VENDEDORES_SEARCH_ERROR: 'VENDEDORES_SEARCH_ERROR'
};

export const startLoading = () => ({type: Types.VENDEDOR_LOADING});

export const requestVendedores = () => ({type: Types.VENDEDORES_FETCH_REQUESTED});
export const receiveVendedores = vendedores => ({type: Types.VENDEDORES_FETCH_SUCCEEDED, vendedores})
export const receiveVendedoresError = err => ({type: Types.VENDEDORES_FETCH_ERROR, err})

export const requestVendedor = (id) => ({type: Types.VENDEDORES_FIND_REQUESTED, id});
export const receiveVendedorSucceeded = vendedor => ({type: Types.VENDEDORES_FIND_SUCCEEDED, vendedor})
export const receiveVendedorError = err => ({type: Types.VENDEDORES_FIND_ERROR, err})

export const saveVendedor = (vendedor) => ({type: Types.VENDEDOR_SAVE_REQUESTED, vendedor});
export const saveVendedorSucceeded = vendedor => ({ type: Types.VENDEDOR_SAVE_SUCCEEDED, vendedor})
export const saveVendedorError = err => ({type: Types.VENDEDOR_SAVE_ERROR, err})

export const editVendedor = (vendedor) => ({type: Types.VENDEDOR_EDIT_REQUESTED, vendedor});
export const editVendedorSucceeded = vendedor => ({ type: Types.VENDEDOR_EDIT_SUCCEEDED, vendedor})
export const editVendedorError = err => ({type: Types.VENDEDOR_EDIT_ERROR, err})

export const deleteVendedor = (vendedor) => ({type: Types.VENDEDOR_DELETE_REQUESTED, vendedor});
export const deleteVendedorSucceeded = vendedor => ({ type: Types.VENDEDOR_DELETE_SUCCEEDED, vendedor})
export const deleteVendedorError = err => ({type: Types.VENDEDOR_DELETE_ERROR, err})

export const requestVendedoresSearch = (search) => ({type: Types.VENDEDORES_SEARCH_REQUESTED, search});
export const receiveVendedoresSearchSucceeded = vendedores => ({type: Types.VENDEDORES_SEARCH_SUCCEEDED, vendedores})
export const receiveVendedoresSearchError = err => ({type: Types.VENDEDORES_SEARCH_ERROR, err})