import { Types } from '../actions/presupuesto';

const defaultPresupuesto={
    idPedido:'',
    numPresupuesto: '',
    numPedido: '',
    id: '',
    cliente: '',
    name: '',
    vendedor: '',
    idSeller:'',
    lastName: '',
    fecha:'',
    total:'',
    estado: '',
    idEstado: '',
}
const defaultPresupuestos=[
    defaultPresupuesto
]

const defaultClienteven={
    id: '',
    nombre: '',
    isEnable: true,
    identificationType: '',
    dni: '',
    tel: '',
    codTotvs: '',
    mail: '',
    seller: '',
    region: ''
}

const defaultClientesVen = [
    defaultClienteven
]

const defaultState={
    Presupuesto: defaultPresupuesto,
    Presupuestos: defaultPresupuestos,
    success: false,
    successDownload: false,
    successPreconfirm: false,
    loading: false,
    saving: false,
    error: false,
    errorMsj: "",
    successPre: false,
    VenClientes: defaultClientesVen,
    presupuestoConfirm: {},
    file: ""
}
export default function presupuestoReducer(state = defaultState, action) {
    switch (action.type) {
        case Types.PRESUPUESTO_LOADING:
            return {...state, loading: true};
        case Types.PRESUPUESTO_ADD_PRODUCT_FALSE:
            return {...state, success: false};
        case Types.PRESUPUESTO_CLOSE_DOWNLOAD:
            return {...state, successDownload: false, file : ""};
        case Types.PRESUPUESTOS_DOWNLOAD_REQUESTED:
            return {...state, successDownload: false, loading: false};
        case Types.PRESUPUESTOS_DOWNLOAD_SUCCEEDED:
            return {...state, file: action.file, loading: false, successDownload: true};
        case Types.PRESUPUESTOS_DOWNLOAD_ERROR:
            return {...state, error: action.err, loading: false, successDownload: false};
        case Types.PRESUPUESTOSCONFIRM_FETCH_REQUESTED:
            return {...state, loading: true, successPre: false};
        case Types.PRESUPUESTOSCONFIRM_FETCH_SUCCEEDED:
            return {...state, presupuestoConfirm: action.presupuestoConfirm, errorMsj: null, successPreconfirm: false, loading: false};
        case Types.PRESUPUESTOSCONFIRM_FETCH_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.PRESUPUESTOS_FETCH_SUCCEEDED:
            return {...state, presupuestos: action.presupuestos, loading: false};
        case Types.PRESUPUESTOS_FETCH_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.PRESUPUESTOS_CLIENTES_FETCH_SUCCEEDED:
            return {...state, VenClientes: action.VenClientes, loading: false};
        case Types.PRESUPUESTOS_CLIENTES_FETCH_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.PRESUPUESTOS_FIND_SUCCEEDED:
            return {...state, presupuesto: action.presupuesto, loading: false};
        case Types.PRESUPUESTOS_FIND_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.PRESUPUESTOS_SEARCH_SUCCEEDED:
            return {...state, presupuestos: action.presupuestos, loading: false};
        case Types.PRESUPUESTOS_SEARCH_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.PRESUPUESTOS_FETCH_LATEST_REQUESTED:
            return {...state, presupuestos: action.presupuestos, loading: true};
        case Types.PRESUPUESTOS_FETCH_LATEST_SUCCEEDED:
            return {...state, ultimosPresupuestos: action.ultimosPresupuestos, loading: false};
        case Types.PRESUPUESTOS_FETCH_LATEST_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.PRESUPUESTOS_DETALLES_REQUESTED:
            return {...state, loading: true};
        case Types.PRESUPUESTOS_DETALLES_SUCCEEDED:
            return {...state, presupuesto: action.presupuesto, loading: false};
        case Types.PRESUPUESTOS_DETALLES_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.PRESUPUESTOS_GET_DETALLES_REQUESTED:
            return {...state, loading: true};
        case Types.PRESUPUESTOS_GET_DETALLES_SUCCEEDED:
            return {...state, presupuesto: action.presupuesto, loading: false};
        case Types.PRESUPUESTOS_GET_DETALLES_ERROR:
            return {...state, error: action.error, loading: false};
        case Types.PRESUPUESTOS_ADD_REQUESTED:
            return {...state, loading: true, error: false, success: false};
        case Types.PRESUPUESTOS_ADD_SUCCEEDED:
            return {...state, presupuesto: action.presupuesto, loading: false, error: false, success: true};
        case Types.PRESUPUESTOS_ADD_ERROR:
            return {...state, error: action.err, loading: false, success: false};
        case Types.PRESUPUESTOS_DELETE_SUCCEEDED:
            return {...state, presupuestos: action.presupuestos, loading: false};
        case Types.PRESUPUESTOS_DELETE_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.PRESUPUESTOS_EDIT_SUCCEEDED:
            return {...state, presupuestos: action.presupuestos, loading: false};
        case Types.PRESUPUESTOS_EDIT_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.PRESUPUESTOS_PRECONFIRM_DATA_SUCCEEDED:
            return {...state, preconfirmData: action.preconfirmData, loading: false};
        case Types.PRESUPUESTOS_PRECONFIRM_DATA_ERROR:
            return {...state, preconfirmData: {}, loading: false};
        case Types.PRESUPUESTOS_CANCELAR_SUCCEEDED:
            return {...state, isCancel: action.isCancel, loading: false};
        case Types.PRESUPUESTOS_CANCELAR_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.PRESUPUESTOS_EDITSHOPCART_SUCCEEDED:
            return {...state, presupuestos: action.presupuestos, loading: false};
        case Types.PRESUPUESTOS_EDITSHOPCART_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.PRESUPUESTOSCONFIRMACION_FETCH_SUCCEEDED:
            return {...state, successPre: true, loading: false};
        case Types.PRESUPUESTOSCONFIRMACION_FETCH_ERROR:
            return {...state, errorMsj: action.err, loading: false, success: false};
        case Types.PRESUPUESTOS_PRECONFIRM_PRESUPUESTO_SUCCEEDED:
            return {...state, successPreconfirm: true, loading: false};
        case Types.PRESUPUESTOS_PRECONFIRM_PRESUPUESTO_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.PRESUPUESTOS_PRECONFIRM_SUCCESS_RESET:
            return {...state, success: action.success,};
        default:
            return state;
    }
}