import React from "react";
import { useEffect } from "react";
import * as Yup from "yup";
import { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import colores from "../../shared/constants/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  requestResetPassword,
  requestOneUsers,
  receiveResetPasswordSucceeded,
} from "../../actions/users";
import { useHistory, useParams } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";

// esta constante va para el reCaptcha
const { REACT_APP_TEST_SITE_KEY } = process.env;

const RestorePassword = (props) => {
  const [trys, setTrys] = useState(0);
  const errorMessage = false;
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(requestOneUsers(id));
  }, [dispatch]);

  const { user } = props;
  const { docType } = user.docType;

  const auth = {
    nCliente: { id },
    documento: { docType },
    nDocumento: "61602306", // {user.}
    empresa: "acá va el nombre de la empresa",
  };

  //Se muestra información sobre la contraseña
  const [showInfo, setShowInfo] = useState(false);
  const infoPassword = (
    <p style={{ fontWeight: 600, fontSize: "0.938rem", color: colores.rojo }}>
      La contraseña debe tener: <br /> 1 Mayúscula, 1 número, 1 caracter
      especial.
    </p>
  );
  //const errorValidacion = <p>Las contraseñas no coinciden o no cumplen con los requisitos.</p>;

  //const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirm: "",
      recaptcha: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Los campos no pueden estar vacíos")
        .matches(
          "^(?=.*[A-Z])(?=.*d)(?=.*[@$!%*#?&])[A-Zd@$!%*#?&]{8,}$",
          "Debe contener al menos 8 caracteres, una mayúscula, n número y un caracter especial"
        ),
      passwordConfirm: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Las contraseñas deben coincidir"
      ),
      recaptcha: Yup.string().required(),
    }),
    onSubmit: (formData) => {
      props.requestChangePassword(formData);
    },
  });

  return (
    <>
      <div>
        <div
          className="d-grid gap-2 col-12 col-md-5 mx-auto"
          style={{ paddingTop: "2rem", paddingBottom: "4rem" }}
        >
          <div className="container">
            <div className="d-flex flex-row bd-highlight">
              <div
                className="mb-3"
                style={{ fontWeight: "bold", marginRight: "1.25rem" }}
              >
                <h4>N° Cliente</h4>
                <h4>{auth?.documento}</h4>
                <h4>Empresa</h4>
              </div>
              <div className="mb-3" style={{ color: "gray" }}>
                <h4>{auth?.nCliente}</h4>
                <h4>{auth?.nDocumento}</h4>
                <h4>{auth?.empresa}</h4>
              </div>
            </div>
          </div>

          <form className="container" onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              <label
                for="exampleInputPassword1"
                className="form-label"
                style={{ color: "gray", fontWeight: 600, fontSize: "0.938rem" }}
              >
                Nueva contraseña
              </label>
              <label
                className="form-label"
                style={{ color: "gray", fontWeight: 600, fontSize: "0.938rem" }}
                onMouseEnter={() => setShowInfo(true)}
                onMouseLeave={() => setShowInfo(false)}
              >
                (info)
              </label>
              {showInfo && (
                <div
                  className=" mx-auto"
                  style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
                >
                  {" "}
                  {infoPassword}{" "}
                </div>
              )}
              <input
                className="form-control"
                type="password"
                id="password"
                onChange={formik.handleChange}
                error={formik.errors.number && true}
                style={{ borderColor: "black", padding: "0.625rem" }}
              ></input>
            </div>
            <div className="mb-3">
              <label
                for="exampleInputPassword1"
                className="form-label"
                style={{ color: "gray", fontWeight: 600, fontSize: "0.938rem" }}
              >
                Repetir contraseña
              </label>
              <input
                className="form-control"
                type="password"
                id="passwordConfirm"
                onChange={formik.handleChange}
                error={formik.errors.number && true}
                style={{ borderColor: "black", padding: "0.625rem" }}
              ></input>
            </div>
            <div className="d-grid gap-2 col-12 col-md-6 mx-auto">
              <ReCAPTCHA
                sitekey={REACT_APP_TEST_SITE_KEY}
                onChange={(value) => {
                  formik.setFieldValue("recaptcha", value);
                  formik.setSubmitting(false);
                }}
              />
            </div>
            <div className="d-grid gap-2 col-12 col-md-6 mx-auto">
              {!trys >= 5 ? (
                <div>
                  Has superado el muero de intentos al generar nueva otraseña,
                  ntenta de nuevo mas tarde
                </div>
              ) : (
                <div>No holi</div>
              )}
              <button
                className="btn btn-danger"
                type="submit"
                style={{
                  backgroundColor: "#E3000B",
                  padding: "0.625rem",
                  marginTop: "1.25rem",
                  inlineSize: "15rem",
                }}
              >
                Confirmar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    user: state.user.user,
    session: state.users.session,
    success: state.users.success,
    docType: state.params.docType,
    maxUser: state,
  }),
  (dispatch) => ({
    requestResetPassword: (formData) =>
      dispatch(requestResetPassword({ formData })),
    receiveResetPasswordSucceeded: () =>
      dispatch(receiveResetPasswordSucceeded()),
  })
)(RestorePassword);
