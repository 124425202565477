import React, {useEffect} from 'react';
import { connect, useDispatch } from 'react-redux';
import { requestVendedores, requestVendedoresSearch } from '../../actions/vendedor';
import {
    Container,
    Grid,
} from '@material-ui/core';
import Vendedor from './vendedor'
import { isAllowed } from '../../shared/utils/permissions';

const Vendedores = (props) => {
  
  const dispatch = useDispatch();
  useEffect(() => {
    if(isAllowed(3))
      dispatch(requestVendedores());
  }, []);

  const { vendedores } = props;

  const handleSearch = (value) => {
    value ? props.requestVendedoresSearch(value) : props.requestVendedores()
  }
  
  return (
    <Container className="py-4" component="main" maxWidth="lg" style={{ marginLeft:"10px", paddingLeft:"72px",paddingRight:"72px" }}>

      <Grid container spacing={5}>
        <Grid item xs={5} style={{paddingLeft:"16px"}}>
          <div className="py-5"><h1>VENDEDORES</h1></div>
        </Grid>
      </Grid>

      <Grid className="row" style={{marginBottom:"2rem"}}>
        <div className="col-12 col-md-9" style={{paddingLeft:"12px"}}>
          <label htmlFor="searchVendedor" className="form-label" style={{color:"gray",fontWeight:600,fontSize:"0.938rem"}}>Buscar Vendedor</label>
          <input name="searchVendedor" className="form-control" id="searchVendedor" style={{borderColor:"black",padding:"0.625rem",maxWidth:"30rem"}} onChange={(e) => handleSearch(e.target.value)}></input>
        </div>
      </Grid>
      
      <Grid className="row">
        {!props.loading ? (
          <Grid container spacing={3}>
            {vendedores?.map(vendedor => (<Vendedor key={vendedor.id} data={vendedor}/>))}
          </Grid>
        ) : (
          <h1>Cargando vendedores...</h1>
        )}
      </Grid>
    </Container>
  );
}

export default connect(
  state => ({
    vendedores: state.vendedor.vendedores,
    loading: state.vendedor.loading
  }),
  dispatch => ({
    requestVendedoresSearch: (search) => dispatch(requestVendedoresSearch(search)),
    requestVendedores: () => dispatch(requestVendedores()),
  })
)(Vendedores);