import { Route, Redirect} from 'react-router-dom';
import  TokenService  from '../services/token';
import rutas from './constants/routes';

export default function PublicRoute ({component: Component, ...rest}) {
    return (
        <Route {...rest}>
            <Component/>
        </Route>
    )
};