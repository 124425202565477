import React from "react";
import {
    Grid,
    Button
} from '@material-ui/core';
import colores from '../../shared/constants/styles';
import IconStatus from "../../shared/iconStatus";
import rutas from "../../routes/constants/routes";
import { useHistory } from 'react-router';

const ClienteVendedor = (props) => {

    const history = useHistory();
    const {data} = props;
    
    const statusActive = {
        name: "Activo",
        color: colores.activo
    }
    const statusInactive = {
        name: "Inactivo",
        color: colores.inactivo
    }
    
    const handleClick= () => {
        
    }

    if ( data.id !== undefined ) {
    return (
        <Grid className="bordesRedondos cliente" item xs={12} sm={6} md={4} lg={4} wrap="nowrap">
            <Grid className="bordesRedondosTop white-color p-4 text-uppercase" style={{backgroundColor: colores.azulOscuro}}>
                <Grid container>
                    <Grid item xs={10}>
                        {/* <h3>{`${data?.name} ${data?.lastName}`}</h3> */}
                        <h3>{`${data?.razonSocial}`}</h3>
                    </Grid>
                    <Grid item xs={2}>
                        <IconStatus config={data?.activo ? statusActive : statusInactive}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{backgroundColor: colores.grisClaro}} className="bordesRedondosBottom p-4">
                <Grid item className="atributoTitle">DNI</Grid>
                <Grid item className="atributoDato">{data?.documentoNro ? data?.documentoNro : "-"}</Grid>
                {/* <Grid item className="atributoTitle">Teléfono</Grid>
                <Grid item className="atributoDato">{data?.telefono ? data?.telefono : "-"}</Grid> */}
                <Grid item className="atributoTitle">Código Totvs</Grid>
                <Grid item className="atributoDato">{data?.id ? data?.id : "-"}</Grid>
                <Grid item className="atributoTitle">Email</Grid>
                <Grid item className="atributoDato">{data?.email ? data?.email : "-"}</Grid>
                <Button 
                    className="my-3"
                    style={{backgroundColor: colores.rojo, color: '#fff'}}
                    variant="contained"
                    onClick = { (e) => history.push(`${rutas.PERFILCLIENTEVENDEDOR}/${data?.usuarioId}`) }
                > 
                    Ver Perfil 
                </Button>
            </Grid> 
            
        </Grid>
    )
    } else {
        return null
    }

}
export default ClienteVendedor