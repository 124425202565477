import { useEffect } from "react";
import { connect } from "react-redux";
import { requestCliente } from "../../actions/cliente";
import { Grid } from "@material-ui/core";
import rutas from "../../routes/constants/routes";
import { useHistory } from "react-router";
import ListadoDescuentos from "../home/descuentos";
import Tienda from "./tienda";
import { makeStyles } from "@material-ui/core";
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  PDato: {
    fontSize: 18,
    fontWeight: "bold"
  },
  PDatoCliente: {
    fontSize: 18,
    fontWeight: "bold",
    color: "gray"
  },
  OficialBack:{
    backgroundColor: "pink"
  }
}));

const ClienteVendedor = (props) => {

  const style = useStyles();
  let { id } = useParams();

  const history = useHistory();
  const { cliente, tiendas, descuentos } = props;

  const myUser = JSON.parse(window.localStorage.getItem("LocalSession"));

  const usuario = {
    ultimoIngreso: myUser?.ultimoLogin,
    mail: myUser?.emailAddress,
    Nombre: myUser?.bussinessName != null ? myUser?.bussinessName : myUser?.userName,
    numeroDoc: myUser?.numeroDoc,
    identificationId: myUser?.identificationId,
    phoneNumber: myUser?.phoneNumber,
    id: myUser?.userId,
  };

  var identificacion = "";

  switch (props?.cliente?.tipodocumento) {
    case "1":
      identificacion = "CUIL";
      break;
    case "2":
      identificacion = "DNI";
      break;
    case "3":
      identificacion = "CUIT";
      break;
    default:
      break;
  }



  useEffect(() => {
    props.requestCliente(id);
  }, []);


  return (
    <>

      {
        props.cliente.cliente === 0
        ?
        <div className="container" style={{marginBottom: "5rem"}}>
        <div className="row d-flex flex-wrap">
        <p style={{ fontSize: "2rem", margin: "4rem 0rem" }}>PERFIL DE VENDEDOR</p>
            <div  className="col-sm-4 d-flex flex-nowrap" >
              <div>
                    <p className={style.PDato} style={{ marginRight: "1.25rem"}}>N° de usuario </p>
                    <p className={style.PDato} style={{ marginRight: "1.25rem"}}>Tipo de documento </p>
              </div>
              <div>
                  <p className={style.PDatoCliente}>{props.cliente.vendedorProfile?.idVendedor}</p>
                  <p className={style.PDatoCliente}>{identificacion}</p> 
              </div>
            </div>
            <div className="col-sm-4  d-flex flex-nowrap">
              <div >
                    <p className={style.PDato} style={{ marginRight: "1.25rem"}}>Número </p>
                    <p className={style.PDato} style={{ marginRight: "1.25rem"}}>Nombre </p>
              </div>
              <div>
                  <p className={style.PDatoCliente}>{props.cliente.vendedorProfile?.nroDocumento}</p>
                  <p className={style.PDatoCliente}>{props.cliente.vendedorProfile?.apellidoNombre}</p>
              </div>
            </div>
            <div  className="col-sm-4  d-flex flex-nowrap">
              <div >
                    <p className={style.PDato} style={{ marginRight: "1.25rem"}}>Correo </p>
                    <p className={style.PDato} style={{ marginRight: "1.25rem"}}>Telefono </p>
              </div>
              <div>
                  <p className={style.PDatoCliente}>{props.cliente.vendedorProfile?.email}</p>
                  <p className={style.PDatoCliente}>{props.cliente.vendedorProfile?.telefono}</p>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="container">
        <div style={{ marginTop: "7rem", marginLeft: "1rem" }}>
          <p style={{ fontWeight: "bold", fontSize: "2.1875rem", marginBottom: "4rem" }}>PERFIL CLIENTE</p>
        </div>
        <div style={{ marginLeft: "1rem" }}>
              <div className="d-flex align-content-center flex-wrap">
        </div>
        <div className="container" style={{marginBottom: "5rem"}}>
          <div className="row">
            <div className="col-sm-3" >
                    <p style={{ fontSize: "2.1875rem" }}>DATOS CLIENTE</p>
                    <p className={style.PDato}>N° de usuario: </p>
                    <p className={style.PDato}>Tipo de documento: </p>
                    <p className={style.PDato}>N°: </p>
                    <p className={style.PDato}>Nombre: </p>
                    <p className={style.PDato}>Email: </p>
                    <p className={style.PDato}>Teléfono: </p>
              </div>
            <div className="col-sm-3">
            <p style={{ fontSize: "2.1875rem" }}>-</p>
                  <p className={style.PDatoCliente}>{props?.cliente?.nroUsuario}</p>
                  <p className={style.PDatoCliente}>{identificacion}</p>
                  <p className={style.PDatoCliente}>{props?.cliente?.numero}</p>
                  <p className={style.PDatoCliente}>{props?.cliente?.nombre}</p>
                  <p className={style.PDatoCliente}>{props?.cliente?.email}</p>
                  <p className={style.PDatoCliente}>{props?.cliente?.telefono}</p>
              </div>
            <div className="col-sm">
                    <div className="container" style={{backgroundColor: "pink", padding: "5rem 2.5rem", borderRadius:"5px" }}>
                        <div className="row" >
                        <div>
                          <h4>Oficial de cuenta</h4>
                        </div>
                          <div className="col-sm-4">
                            <div>
                              <p className={style.PDato}>Nombre: </p>
                            </div>

                            <div>
                              <p className={style.PDato}>Correo: </p>
                            </div>

                            <div>
                              <p className={style.PDato}>Teléfono: </p>
                            </div>
                          </div>

                          <div className="col-sm-8">

                            <div>
                              <p className={style.PDatoCliente}>{props.cliente.oficialDeCuenta?.apellidoNombre}</p>
                            </div>

                            <div>
                              <p className={style.PDatoCliente}>{props.cliente.oficialDeCuenta?.email}</p> 
                            </div>

                            <div>
                              <p className={style.PDatoCliente}>{props.cliente.oficialDeCuenta?.telefono}</p>
                            </div>
                          </div>
                        </div>
                    </div>
              </div>
          </div>
        </div>

        <div className="container">
          <p style={{ fontSize: "2.1875rem" }}>TIENDAS</p>
          <div className="d-flex flex-wrap">
            <Grid container spacing={5}>
                <Tienda key={props.cliente.tiendaUno?.tiendaNro} data={props.cliente?.tiendaUno} />
                {props.cliente.tiendas?.$values.map((tienda) => (
                  <Tienda key={tienda.tiendaNro} data={tienda} />
                ))}
              </Grid>
          </div>
      </div>

          <div className="container">
            <p style={{ fontSize: "2.1875rem" }}>DESCUENTOS</p>
            <div>
              <div className="d-flex justify-content-center" style={{padding: "2.5rem", backgroundColor: "#F4F4F4", margin: "0.625rem", borderRadius: "5px", borderBottomColor: "black", borderBottomSize: "0.031rem", borderBottomStyle: "solid"}}>
                <h4 style={{fontWeight: "bold"}}>DESCUENTO COMERCIAL</h4>
              </div>
              <ListadoDescuentos key={cliente.id} data={props.cliente.reglaDescuento?.$values} />
            </div>
            <div>
              <div className="d-flex justify-content-center" style={{padding: "2.5rem", backgroundColor: "#F4F4F4", margin: "0.625rem", borderRadius: "5px", borderBottomColor: "black", borderBottomSize: "0.031rem", borderBottomStyle: "solid"}}>
                <h4 style={{fontWeight: "bold"}}>DESCUENTO POR FAMILIA DE PRODUCTOS</h4>
              </div>
              <ListadoDescuentos key={cliente.id} data={props.cliente.reglaDescuentoDetalle?.$values} />
            </div>
          </div>
        </div>
      </div>
      }
    </>
  );
};

export default connect(
  (state) => ({
    cliente: state.clientes.cliente,
    loading: state.clientes.loading,
  }),
  (dispatch) => ({
    requestCliente: (id) => dispatch(requestCliente(id)),
  })
)(ClienteVendedor);
