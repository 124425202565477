import { Grid } from '@material-ui/core';
import colores from '../../shared/constants/styles';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

const VendedorCard = ({title, nombre, email, telefono}) => {
    return (
        <Grid className="bordesRedondos tienda" item xs={12} sm={6} md={4} lg={4} style={{marginBottom: "2.5rem"}}>
            <div className="bordesRedondosTop white-color p-4" style={{backgroundColor: colores.azulOscuro}}>
                <Grid container>
                    <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                        <PersonOutlineOutlinedIcon style={{fill: "white", width: "4vh", height: "4vh"}}/>  
                        <h5 style={{margin: "6px"}}>{title}</h5>
                    </Grid>
                </Grid>
            </div>
            <Grid item xs={12} style={{backgroundColor: colores.grisClaro}} className="bordesRedondosBottom p-4">
                <Grid item className="atributoTitle">Nombre</Grid>
                <Grid item className="atributoDato">{nombre}</Grid>
                <Grid item className="atributoTitle">Email</Grid>
                <Grid item className="atributoDato">{email}</Grid>
                <Grid item className="atributoTitle">Teléfono</Grid>
                <Grid item className="atributoDato">{telefono}</Grid>
            </Grid> 
            
        </Grid>
    )
}
export default VendedorCard