import { Types } from '../actions/reclamos';

export default function reclamosReducer(state = {reclamos: {}, estados: {}, loading: false, success: false, error: false}, action) {
    switch (action.type) {
        case Types.RECLAMOS_GET_REQUEST:
            return {...state, loading: true }
        case Types.RECLAMOS_GET_SUCCEEDED:
            return {...state, reclamos: action.reclamos, loading: false, success: true, error: false};
        case Types.RECLAMOS_GET_ERROR:
            return {...state, loading: false, success: false, error: action.err,};
        case Types.RECLAMOS_SEARCH_REQUESTED:
                return {...state, loading: true }
        case Types.RECLAMOS_SEARCH_SUCCEEDED:
            return {...state, reclamos: action.reclamos, loading: false, success: true, error: false};
        case Types.RECLAMOS_SEARCH_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.ESTADO_RECLAMO_REQUESTED:
                return {...state, loading: true }
        case Types.ESTADO_RECLAMO_SUCCEEDED:
            return {...state, estados: action.estados, loading: false, success: true, error: false};
        case Types.ESTADO_RECLAMO_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.RECLAMO_NUEVO_REQUESTED:
                return {...state, loading: true }
        case Types.RECLAMO_NUEVO_SUCCEEDED:
            return {...state, loading: false, success: true, error: false};
        case Types.RECLAMO_NUEVO_ERROR:
            return {...state, error: action.err, loading: false};
        default:
            return state;
    }
}