import React from "react";
import { useHistory } from "react-router-dom";
import { requestVendedores } from '../../actions/vendedor';
import { connect } from 'react-redux';


import {
    Grid,
    Button
} from '@material-ui/core';
import colores from '../../shared/constants/styles';
import IconStatus from "../../shared/iconStatus";
import rutas from "../../routes/constants/routes";

const Vendedor = (props) => {
    const { data } = props;
    const history = useHistory();
    const statusActive = {
        name: "Activo",
        color: colores.activo
    }
    const statusInactive = {
        name: "Inactivo",
        color: colores.inactivo
    }

    const handleClick = () => {
        history.push(`${rutas.PERFIL_VENDEDOR}/${data?.id}`)
    }
    return (
        <Grid className="bordesRedondos vendedor" item xs={12} sm={6} md={4} lg={4} wrap="nowrap">
            <div className="bordesRedondosTop white-color p-4 text-uppercase" style={{backgroundColor: colores.azulOscuro}}>
                <Grid container>
                    <Grid item xs={10}>
                        <h3>{data?.apellidoNombre}</h3>
                    </Grid>
                    <Grid item xs={2}>
                        <IconStatus config={data?.activo ? statusActive : statusInactive }/>
                    </Grid>
                </Grid>
            </div>
            <Grid item xs={12} style={{backgroundColor: colores.grisClaro}} className="bordesRedondosBottom p-4">
                <Grid item className="vendedorAtributoTitle">DNI</Grid>
                <Grid item className="vendedorAtributo">{data?.documentoNro ? data?.documentoNro : "-"}</Grid>
                <Grid item className="vendedorAtributoTitle">Teléfono</Grid>
                <Grid item className="vendedorAtributo">{data?.telefono ? data?.telefono : "-"}</Grid>
                <Grid item className="vendedorAtributoTitle">Código Totvs</Grid>
                <Grid item className="vendedorAtributo">{data?.id ? data?.id : "-"}</Grid>
                <Grid item className="vendedorAtributoTitle">Email</Grid>
                <Grid item className="vendedorAtributo">{data?.email ? data?.email : "-"}</Grid>
                <Button
                    className="my-3"
                    style={{backgroundColor: colores.rojo, color: '#fff'}}
                    variant="contained"
                    onClick={(e) => handleClick()}
                >
                    Ver Perfil
                </Button>
            </Grid> 
        </Grid>
    )

}
export default connect (
    state => ({
      vendedores: state.vendedor.vendedores,
    }),
    dispatch => ({
      requestVendedores: (id) => dispatch(requestVendedores(id))
    })
  )(Vendedor);