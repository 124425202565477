import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Collapse,
  Typography,
  CardActions,
  Button,
  Tooltip,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./novedad.css";

const Novedad = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const history = useHistory();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const verMas = () => {
    localStorage.setItem('novedadData', JSON.stringify(props.data));
    history.push(`/novedad/${props.data.id}`);
  };

  return (
    <Card
      onClick={verMas}
      style={{
        width: 358,
        maxWidth: 360,
        height: 530,
        margin: "6vh 2vh 2vh 2vh",
        backgroundColor: isHovered ? "rgba(255, 0, 0, 0.8)" : "#f5f0f0"
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {props.data.link !== "" && (
        <CardMedia
          component="img"
          height={180}
          image={props.data?.link}
          alt="imagen_novedad"
        />
      )}
      <CardHeader
        style={{height: "140px", padding: "1vw"}}
        action={
          props.data?.destacada && (
            <Tooltip title="Destacada">
              <div aria-label="settings">
                <StarIcon />
              </div>
            </Tooltip>
          )
        }
        titleTypographyProps={{
          fontSize: "0.7rem",
          fontWeight: "bold",
          style: {
            color: isHovered ? "white" : "inherit", 
            marginBottom: "1vh"
          },
        }}
        
        subheaderTypographyProps={{
          variant: "h6",
          fontWeight: "bold",
          style: {
            color: isHovered ? "white" : "inherit"
          },
        }}
        title={props.data?.fechaCreacion}
        subheader={props.data?.titulo}
      />
      <CardContent style={{ height: "140px", padding: "0vw 2vw 2vw 2vw" }}>
        <Typography
          variant="body1"
          color="text.secondary"
          style={{ color: isHovered ? "white" : "inherit" }}
          sx={{
            fontSize: "0.9rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "6",
            WebkitBoxOrient: "vertical",
          }}
        >
          {props.data?.textoPortada}
        </Typography>
      </CardContent>
      <CardActions  style={{ height: "70px"}} >
        <Button
          style={{ color: isHovered ? "white" : "red", marginLeft: "1vw" }}
          onClick={verMas}
          endIcon={<ArrowForwardIcon />}
        >
          VER MÁS
        </Button>
      </CardActions>
    </Card>
  );
};

export default connect((state) => ({}))(Novedad);
