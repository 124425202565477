import React from "react";
import { connect } from 'react-redux';
import colores from '../shared/constants/styles';
import {
    Container, Grid, Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  styleContainer: {
    background:colores.grisClaro, 
    backgroundSize: "cover",
    minHeight:  "4rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    width: "100%",
    },
  styleGrid: {
    background: "white", 
    inlineSize: "10rem",
    margin: "0.1rem",
    paddingInline: "1rem",
    paddingBlock: "0.5rem",
    height: "2.5rem"
  },
}));

const Dolar = (props) => {
  
  const dolar = props.dolar;
  const classes = useStyles();
  if (dolar?.fechaHora != null){
    var fecha = dolar?.fechaHora.split('T');
    var parts = fecha[0].split('-');
    var fechaDolar = parts[2] + '/' + parts[1] + '/' + parts[0];
  }

  return (
    
    <div className={classes.styleContainer}>
      <Container style={{ margin:"2rem 3.5rem", padding: '0', maxWidth: '80%' }}>
        <Grid container
          direction="row"
          justifyContent="flex-start"
          alignItems="center">
            <Grid item className={classes.styleGrid} sm={12} md={6} lg={2}>
              <p><strong><strong>DÓLAR BILLETE</strong></strong></p>
            </Grid>
            <Grid item className={classes.styleGrid} sm={12} md={6} lg={2}>
              <p><strong>VENTA $ {dolar?.cotizacion}</strong></p>
            </Grid>
            <Grid item className={classes.styleGrid} sm={12} md={6} lg={2}>
              <Link target="_blank" href="https://bna.com.ar/Personas"><p>BNA</p></Link>
            </Grid>
            <Grid item className={classes.styleGrid} sm={12} md={6} lg={2}>
              {fechaDolar ? <p>{fechaDolar}</p> : <p></p>}
            </Grid>
        </Grid>

      </Container>

    </div>
  )
}
//export default Dolar

export default connect(
  state => ({
    dolar: state.params.dolar,
  }),
)(Dolar);