import { connect } from "react-redux";
import IDataConsulSteel from "../../common/interface/IDataConsulSteel";
import EstadoDetalle from "./estado_detalle";
import Detalle from "./detalle";
interface IDetalleProps {
    detalle: IDataConsulSteel;
    onSetPopUp: () => void;
}

const VerDetalle = (props: IDetalleProps) => {

    return (
        <div>
            <div
                style={{
                    height: "100%",
                    width: "100%",
                    position: "fixed",
                    left: 0,
                    top: 0,
                    zIndex: "1",
                    backgroundColor: "black",
                    filter: "alpha(opacity=75)",
                    opacity: 0.75,
                }}> 
            </div>
            <div
                className="container"
                style={{
                    position: "fixed",
                    left: "50%",
                    top: "25%",
                    transform: "translate(-50%, -25%)",
                    bottom: "0",
                    zIndex: "2",
                    overflowY: "auto",
                    overflowX: "hidden",
                }}>
                <EstadoDetalle detalle={props.detalle} onSetPopUp={props.onSetPopUp}></EstadoDetalle>
                <Detalle detalle={props.detalle} />
            </div>
        </div>
    );
}

export default connect()(VerDetalle);