import React, {useEffect, useState} from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { requestGrupos } from '../../actions/params';
import colores from '../../shared/constants/styles';
import Dolar from '../../routes/dolar';
import CardProduct from './cardProduct';
import CardMessage from './CardMessage';

const AllProductos = () => {
    const localStorage = JSON.parse(window.localStorage.getItem("LocalSession"));
    const { productos } = useParams();
    const [listaproductos, setListaproductos] = useState([]);

    useEffect(() => {
        if (productos) {
            setListaproductos(JSON.parse(decodeURIComponent(productos)));
          }
    }, [productos]);

    const handleChange = (value) => {
        // setFlagForm(true)
        // setCategoria(id);
        // descripcion? props.requestProductosSearch(id, descripcion) : props.requestProductosPorGrupo(id, localStorage.clientId);
        // setBusqueda(value)
    }

    const handleSearch = (value) => {
        // value ? props.requestProductosSearch(categoria, value) : props.requestProductosPorGrupo(categoria, localStorage.clientId);
        // value?.length === 0? setFlagText(false):setFlagText(true)
        // setDescripcion(value);
    }
 
    return (
        <div style={{backgroundColor: colores.grisClaro}}>
            <Dolar></Dolar>
            {/* <div>
                <div className="container-fluid d-flex flex-row mb-3 flex-wrap" style={{marginLeft: "3.7rem", padding:"1rem"}}>
                    <div className="fluid d-flex flex-start flex-wrap">
                        <div style={{alignSelf:'center',display:"block", marginInlineEnd:"4rem"}}>
                            <p style={{fontSize:"1.2rem", alignSelf:'center', margin:"0rem", display:"block"}}>BÚSQUEDA POR SUBPRODUCTOS</p>
                        </div>
                        <MuiCustomInput component={"FormControl"} handleChange={handleChange} gruposDropdown={gruposDropdown} productos={productos} setFlagForm={setFlagForm} flagForm={flagForm}/>
                        <MuiCustomInput component={"TextField"} handleSearch={handleSearch} value={descripcion} flagText={flagText}/>
                    </div>
                </div>
                
            </div> */}
            <div>
                <div className="container-fluid d--flex flex-row mb-3 flex-wrap" style={{backgroundColor:"white", padding:"1rem"}}>
                    <div className="container-fluid" style={{width: "100%", height: "100%", overflow:"hidden", clear:"both"}}>
                        <div className="container d-flex flex-row flex-wrap" style={{ marginBlock:"2rem"}}>
                            { (
                                listaproductos && listaproductos.length >= 1
                                ? (
                                    listaproductos?.map(producto => (
                                        producto.id !== '' && <CardProduct key={producto.id} data={producto} />
                                    ))
                                )
                                : ( localStorage.tipoRol === 3
                                    ? <CardMessage cliente={true}></CardMessage>
                                    : <CardMessage cliente={false}></CardMessage>)
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(AllProductos);