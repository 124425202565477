import React from "react";
import colores from "../../shared/constants/styles";
import plano from "../../assets/images/Planito_Calculador-02.png";
import { light } from "@material-ui/core/styles/createPalette";

const FooterCalculator = () => {
  const style = { background: colores.rojo, color: "white" };

  return (
    <footer style={style} className="p-5 container-fluid">
      <div className="row" style={{ alignItems: "center" }}>
        <div className="col-lg-2 d-block-sm justify-content-end">
          <img src={plano} style={{ width: 140, height: 116.5 }} alt=""></img>
        </div>
        <div className="col-lg-7 d-block-sm">
          <h5 className="my-4">
            CALCULÁ CON PRECISIÓN LA CANTIDAD DE CADA PRODUCTO QUE NECESITÁS
          </h5>
          <h2 className="my-4">
            <b>CALCULADOR DE MATERIALES</b>
          </h2>
        </div>
        <div className="col-lg-3 d-block-sm">
          <a
            href="https://calculador.adbarbieri.com/calculador"
            alt=""
            target="blank"
          >
            <button
              className="btn btn-secondary"
              style={{
                width: 119,
                height: 60,
                backgroundColor: "white",
                borderRadius: 5,
              }}
            >
              <h5
                className="align-content-center my-0"
                style={{ color: "red" }}
              >
                CALCULAR
              </h5>
            </button>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default FooterCalculator;
