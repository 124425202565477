import {call, put, takeEvery, fork} from 'redux-saga/effects'

import * as actions from '../actions/suggestedProduct';

import SuggestedService from '../services/suggestedProduct';

function* fetchSuggested() {
    yield put(actions.startLoading());
    try {
        const suggestedProducts = yield call(SuggestedService.fetch);
        yield put(actions.receiveSuggestedSucceeded(suggestedProducts));
    } catch (err) {
        yield put(actions.receiveSuggestedError(err));
        console.log(err)
    }
}

function* addSuggested({AddSuggested}) {
    yield put(actions.startLoading())
    try {
        const suggestedProduct = yield call(SuggestedService.add, AddSuggested);
        yield put(actions.receiveAddSuggestedSucceeded(suggestedProduct));
    } catch (err) {
        yield put(actions.receiveAddSuggestedError(err));
        console.log(err)
    }
}

function* deleteSuggested({DeleteSuggested}) {
    yield put(actions.startLoading())
    try {
        const suggestedProduct = yield call(SuggestedService.delete, DeleteSuggested);
        yield put(actions.receiveDeleteSuggestedSucceeded(suggestedProduct));
    } catch (err) {
        yield put(actions.receiveDeleteSuggestedError(err));
        console.log(err)
    }
}

function* fetchSuggestedSearch({search}) {
    yield put(actions.startLoading());
    try {
        const suggestedProductsSearch = yield call(SuggestedService.search, search);
        yield put(actions.receiveSuggestedSucceededSearch(suggestedProductsSearch));
    } catch (err) {
        yield put(actions.receiveSuggestedErrorSearch(err));
        console.log(err)
    }
}

function*  watchGetfetchSuggested() {
    yield takeEvery(actions.Types.SUGGESTED_FETCH_REQUESTED, fetchSuggested)
}

function*  watchAddSuggested() {
    yield takeEvery(actions.Types.SUGGESTED_ADD_REQUESTED, addSuggested)
}

function*  watchDeleteSuggested() {
    yield takeEvery(actions.Types.SUGGESTED_DELETE_REQUESTED, deleteSuggested)
}

function*  watchGetSearchSuggested() {
    yield takeEvery(actions.Types.SUGGESTED_SEARCH_REQUESTED, fetchSuggestedSearch)
}



const suggestedProductSagas = [
    fork(watchGetfetchSuggested),
    fork(watchAddSuggested),
    fork(watchDeleteSuggested),
    fork(watchGetSearchSuggested)
]

export default suggestedProductSagas;
