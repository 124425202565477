import { Types } from '../actions/vendedor';

const defaultVendedor={
    id: '',
    nombre: '',
    isEnable: true,
    dni: '',
    tel: '',
    codTotvs: '',
    mail: '',
    region: ''
}
const defaultVendedores=[
    defaultVendedor
]
const defaultState={
    vendedor: defaultVendedor,
    vendedores: defaultVendedores,
    loading: false,
    saving: false
}
export default function vendedorReducer(state = defaultState, action) {
    switch (action.type) {
        case Types.VENDEDOR_LOADING:
            return {...state, loading: true};
        case Types.VENDEDORES_FETCH_SUCCEEDED:
            return {...state, vendedores: action.vendedores, loading: false};
        case Types.VENDEDORES_FETCH_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.VENDEDOR_SAVE_REQUESTED:
            return {...state, saving: true};
        case Types.VENDEDOR_SAVE_SUCCEEDED:
            return {...state,vendedor: action.vendedor, saving: true};
        case Types.VENDEDOR_SAVE_ERROR:
            return {...state, error: action.err, saving: false};
        case Types.VENDEDOR_EDIT_REQUESTED:
            return {...state, saving: true};
        case Types.VENDEDOR_EDIT_SUCCEEDED:
            return {...state,vendedor: action.vendedor, saving: true};
        case Types.VENDEDOR_EDIT_ERROR:
            return {...state, error: action.err, saving: false};
        case Types.VENDEDOR_DELETE_REQUESTED:
            return {...state, saving: true};
        case Types.VENDEDOR_DELETE_SUCCEEDED:
            return {...state,vendedor: action.vendedor, saving: true};
        case Types.VENDEDOR_DELETE_ERROR:
            return {...state, error: action.err, saving: false};
        case Types.VENDEDORES_FIND_SUCCEEDED:
            return {...state, vendedor: action.vendedor, loading: false};
        case Types.VENDEDORES_FIND_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.VENDEDORES_SEARCH_SUCCEEDED:
            return {...state, vendedores: action.vendedores, loading: false};
        case Types.VENDEDORES_SEARCH_ERROR:
            return {...state, error: action.err, loading: false};
        default:
            return state;
    }
}