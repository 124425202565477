
class TipoComprobanteMapper {
    public mapperNombreComprobante = (nombreCompleto: string): string => {
        let nombreCorto: string= "";
        switch (nombreCompleto) {
            case "Factura":
                return "F";
            case "Nota de Débito":
                return "D";
            case "Nota de Crédito":
                return "C";
            default:
                break;
        }   
        return nombreCorto;
    };
}
export default new TipoComprobanteMapper();
