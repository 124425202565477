import axios from 'axios'

const { REACT_APP_ENDPOINT } = process.env;
const ENDPOINT = `${REACT_APP_ENDPOINT}/api/configuracion/`


export default class configuracion {
    static async updateShoopEnabled(formData) {
        const respuesta = await axios.post(`${ENDPOINT}updateShoopEnabled`, formData)
        .then(response => {
            return response;
        });
        return respuesta.data;
    }

    static async uploadListPrecios(formDataFile) {
        const formData = new FormData();
        if(formDataFile.file.length > 0) {
            formData.append('file', formDataFile.file[0]);    
        } 
        const respuesta = await axios.post(`${ENDPOINT}uploadListPrecios`, formData, {
            headers: {
            'Content-Type': 'multipart/form-data',
            },
        })
        .then(response => {
            return response;
        });
        return respuesta.data;
    }
    static async uploadBancoImagenes(formDataFile) {
        const formData = new FormData();
        if(formDataFile.file.length > 0) {
            formData.append('file', formDataFile.file[0]);     
        } 
        const respuesta = await axios.post(`${ENDPOINT}uploadBancoImagenes`, formData, {
            headers: {
            'Content-Type': 'multipart/form-data',
            },
        })
        .then(response => {
            return response;
        });
        return respuesta.data;
    }
    static async uploadManualMarca(formDataFile) {
        const formData = new FormData();
        if(formDataFile.file.length > 0) {
            formData.append('file', formDataFile.file[0]);    
        } 
        const respuesta = await axios.post(`${ENDPOINT}uploadManualMarca`, formData, {
            headers: {
            'Content-Type': 'multipart/form-data',
            },
        })
        .then(response => {
            return response;
        });
        return respuesta.data;
    }

    static async updateTerminosCondiciones(formData) {
        const respuesta = await axios.post(`${ENDPOINT}updateTermsConditions`, formData)
        .then(response => {
            return response;
        });
        return respuesta.data;
    }    
    static async getTerminosCondiciones() {
        const respuesta = await axios.get(`${ENDPOINT}getTermsConditions`)
        .then(response => {
            return response;
        });
        return respuesta.data;
    }

}

