import { Types} from '../actions/cliente';

const defaultCliente={
    id: '',
    nombre: '',
    isEnable: true,
    identificationType: '',
    dni: '',
    tel: '',
    codTotvs: '',
    mail: '',
    seller: '',
    region: ''
}
const defaultClientes=[
    defaultCliente
]
const defaultState={
    cliente: defaultCliente,
    clientes: defaultClientes,
    loading: false,
    saving: false,
    success: false
}
export default function clienteReducer(state = defaultState, action) {
    switch (action.type) {
        case Types.CLIENTE_LOADING:
            return {...state, loading: true};
        case Types.CLIENTESCONTACT_FETCH_SUCCEEDED:
            return {...state, success: true, loading: false};
        case Types.CLIENTESCONTACT_FETCH_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.CLIENTES_FETCH_SUCCEEDED:
            return {...state, clientes: action.clientes, loading: false};
        case Types.CLIENTES_BY_SELLER_FETCH_SUCCEEDED:
            return {...state, clientes: action.clientes, loading: false};
        case Types.CLIENTES_FETCH_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.CLIENTES_FETCH_ALL_SUCCEEDED:
            return {...state, clientes: action.clientes, loading: false};
        case Types.CLIENTES_FETCH_ALL_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.CLIENTES_FIND_SUCCEEDED:
            return {...state, cliente: action.cliente, loading: false};
        case Types.CLIENTES_FIND_ERROR:
            return {...state, error: action.err, loading: false};
        case Types.CLIENTES_SEARCH_SUCCEEDED:
            return {...state, clientes: action.clientes, loading: false};
        case Types.CLIENTES_SEARCH_ERROR:
            return {...state, error: action.err, loading: false};

        case Types.CLIENTES_EDIT_ADDRESS_SUCCEEDED:
            return {...state, success: true, loading: false};
        case Types.CLIENTES_EDIT_ADDRESS_ERROR:
            return {...state, error: action.err, loading: false};
        default:
            return state;
    }
}