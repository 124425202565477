import { connect, useDispatch } from "react-redux";
import { getFacturasByFilter } from "../../../actions/cuentacorriente";
import { useEffect, useState } from "react";
import { FACTURA_COLUMN } from "./interface/columnData";
import TableCustom from "../../../common/components/table/table";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IFilterFactura from "./interface/IFilterFactura";
import Filter from "./filter";
import cuentaCorrienteService  from "../../../services/cuentacorriente";
import IClienteIdFilter from "../../../common/interface/IClienteIdFilter";
import Descarga from "../descarga";
import TipoComprobanteMapper from "../../../helper/TipoComprobanteMapper";

const Factura = (props: any) => {
    const dispatch = useDispatch();
    const localStorage = JSON.parse(window.localStorage.getItem("LocalSession") || '{}');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingPdf, setLoadingPdf] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [filter, setFilter] = useState<IFilterFactura>({
        clienteId: localStorage.clientId.trim(),
        documento: '',
        page: 0,
        rowPerPage: 5
    })
    const [clienteFilter, setClienteFilter] = useState<IClienteIdFilter>({
        clienteId: localStorage.clientId.trim()
    })
    const getFacturas = (page: number, rowsPerPage: number): void => {
        setError(false);
        filter.rowPerPage= rowsPerPage;
        filter.page= page;
        dispatch(getFacturasByFilter(filter));
    };
    const getFilterFacturas = ():void => {
        setError(false);
        setPage(0);
        setRowsPerPage(5);
        filter.rowPerPage= 5;
        filter.page= 0;
        dispatch(getFacturasByFilter(filter));
    }
    useEffect(() => {
        dispatch(getFacturasByFilter(filter));
    }, []);

    // useEffect(() => {
    //     console.log(props.facturas)
    //     // if (props.estadopedidos.data) {
    //     //     setAcopios(props.acopios?.data.$values);
    //     // }
    // }, [props]);

    const handleDownload = async () => {
        setLoading(true);
        setError(false);
        const response = await cuentaCorrienteService.downloadFileFactura(clienteFilter);
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'facturas.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoading(false);
    };

    const handleDownloadPdf = async (item: any) => {
        setLoadingPdf(true);
        setError(false);
        try {
            const response = await cuentaCorrienteService.downloadComprobanteFactura(localStorage.numeroDoc.trim(), item.tipo, item.documento);
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            const tipo= TipoComprobanteMapper.mapperNombreComprobante(item.tipo);
            link.setAttribute('download', `ADB${localStorage.numeroDoc.trim()}${tipo}${item.documento}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            setLoadingPdf(false);   
        } catch (error) {
            setError(true);
            setLoadingPdf(false);
        }
    };

    return (
        <div style={{ margin: "2vw" }}>
            <div className="row">
                <div className="col-10">
                    <Filter 
                        getFilterFactura={getFilterFacturas}
                        filter={filter}
                        onSelectedFilter={setFilter}
                    />                    
                </div>   
                <div className="col-2">
                    <Descarga loading={loading} handleDownload={handleDownload}></Descarga>   
                </div>   
            </div>
            { error && 
                <Stack sx={{ width: '100%', margin: '2vh' }} spacing={2}>
                    <Alert severity="error">No se encuentra el documento.</Alert>
                </Stack>
            }
            <TableCustom page={page} 
                    onSelectedPage={setPage} 
                    rowsPerPage={rowsPerPage} 
                    onSelectedRowPerPage={setRowsPerPage} 
                    items={props.facturas?.facturaResponses}
                    length={props.facturas?.length} 
                    getItems={getFacturas}
                    columns={FACTURA_COLUMN}
                    handleDownload={handleDownloadPdf}
                    loading={loadingPdf}
                />
        </div>
    );
};
export default connect(
    (state: any) => ({
        facturas: state.cuentacorriente.facturas,
        error: state.cuentacorriente.error,    
        loading: state.cuentacorriente.loading
    }),
    (dispatch) => ({
        getFacturasByFilter: (filter: IFilterFactura) => dispatch(getFacturasByFilter(filter))
    })
)(Factura);
