import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { requestProducto } from '../../actions/producto';
import rutas from "../../routes/constants/routes";
import ProductoSinImagen from '../../assets/images/ProductoSinImagen.png';
import {
    Grid, Button
} from '@material-ui/core';

const CardProduct = (props) => {
    
    const dispatch = useDispatch();
    const history = useHistory();
    const { data } = props;
    const handleClick = () => {
        // dispatch(requestProducto(data?.id));
        history.push(`${rutas.DETALLES_PRODUCTO}/${data?.id}`);
    }

    const [isHovering, setIsHovering] = useState(false);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const [isHoveringButton, setIsHoveringButton] = useState(false);

    const handleMouseEnterB = () => {
        setIsHoveringButton(true);
    };

    const handleMouseLeaveB = () => {
        setIsHoveringButton(false);
    };

    // Función para dividir el texto y encontrar el contenido dentro de paréntesis
    const renderDescriptionWithBoldParenthesis = (description) => {
        const match = description.match(/\((.*?)\)/);
        if (!match) {
            return description; // Si no hay paréntesis, retorna el texto tal cual
        }
        const beforeParenthesis = description.substring(0, match.index);
        const parenthesisContent = match[0]; // El contenido dentro de los paréntesis incluyendo los paréntesis mismos
        const afterParenthesis = description.substring(match.index + parenthesisContent.length);
        return (
            <>
                {beforeParenthesis}
                <div style={{fontWeight: 'bold'}}>{parenthesisContent}</div>
                {afterParenthesis}
            </>
        );
    };

    return ( 
        <Grid>
            <div className='container-fluid' style={{ margin: "0", borderRadius: "2px"}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div className="card text-center" style={{border:"none", width: "11.8rem", paddingLeft : "18px", paddingRight : "18px", paddingBottom: "10px", marginBottom : "10px", boxShadow: isHovering ? '0px 0px 5px 0px gray' : '0px 0px 2px 0px gray'}}>
                        {data?.image
                            ? 
                            (<img src={data?.image} className="card-img-top" style={{ marginTop: "1rem", marginBottom: "1rem", borderRadius: "10px"}} alt="Imagen del producto"/>) 
                            : 
                            (<img src={ProductoSinImagen} className="card-img-top" style={{marginTop: "1rem", marginBottom: "1rem", borderRadius: "10px"}} alt="Producto sin imagen"/>)
                        }
                        <div className="card-body" style={{ padding: 0 }}>
                        <p className="card-title" style={{height: "6rem" }}>{renderDescriptionWithBoldParenthesis(data?.descripcion)}</p>
                        <p className="h4" style={{marginTop:"1rem", height: "2rem"}}>{data?.moneda?.simbolo} {data?.precio}</p>
                        <Button 
                            variant="contained" 
                            style={{fontSize:"0.75rem", textTransform:"none", backgroundColor: isHoveringButton ? "#B00009" : "red", boxShadow: 'none', color: "white", height: "2rem" }} 
                            onClick ={(e) => handleClick()}
                            onMouseEnter={handleMouseEnterB} onMouseLeave={handleMouseLeaveB}
                        > 
                            Más Información
                        </Button>
                    </div>
                </div>
            </div>
        </Grid>
    )
}

export default CardProduct
