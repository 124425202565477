import axios from 'axios'

const { REACT_APP_ENDPOINT } = process.env;
const ENDPOINT = `${REACT_APP_ENDPOINT}/api/acopios/`

export default class acopios {
    static async getAcopiosByClienteId(clienteId) {
        const respuesta = await axios.get(`${ENDPOINT}getAcopioByClienteId/${clienteId}`)
        .then(response => {
            return response
        })
        return respuesta.data
    }

    static async getAcopiosDetalle(nroAcopio) {
        const respuesta = await axios.get(`${ENDPOINT}getAcopiosDetalle/${nroAcopio}`)
        .then(response => {
            return response
        })
        return respuesta.data
    }
}

