import { Types } from '../actions/suggestedProduct';

const defaultSuggested = [
    {
        SugerenciaId :'',
        NombreProducto :'',
        IdProducto :'',
        ProductoRelacionado :'',
        IdProductoRelacionado :'',
        TipoRelacion :'',
        IdTipoRelacion :''
    },
    {
        SugerenciaId :'',
        NombreProducto :'',
        IdProducto :'',
        ProductoRelacionado :'',
        IdProductoRelacionado :'',
        TipoRelacion :'',
        IdTipoRelacion :''
    }
]

    const defaultState={
        suggestedProducts: defaultSuggested,
        loading: false,
        success: false,
        successAdd: false,
        error: ''
    }


export default function suggestedProduct(state = defaultState, action) {
    switch (action.type) {
        case Types.SUGGESTED_LOADING:
            return {...state, loading: true, error: false, successAdd: false};
        case Types.SUGGESTED_FETCH_REQUESTED:
            return {...state, loading: false};
        case Types.SUGGESTED_FETCH_SUCCEEDED:
            return {...state, suggestedProducts: action.suggestedProducts, success: true, loading: false};
        case Types.SUGGESTED_FETCH_ERROR:
            return {...state, error: action.err, success: false, loading: false};
        case Types.SUGGESTED_ADD_REQUESTED:
            return {...state, loading: false};
        case Types.SUGGESTED_ADD_SUCCEEDED:
            return {...state, successAdd: true, loading: false};
        case Types.SUGGESTED_ADD_ERROR:
            return {...state, error: action.err, success: false, loading: false};
        case Types.SUGGESTED_DELETE_REQUESTED:
            return {...state, loading: false};
        case Types.SUGGESTED_DELETE_SUCCEEDED:
            return {...state, success: true, loading: false};
        case Types.SUGGESTED_DELETE_ERROR:
            return {...state, error: action.err, success: false, loading: false};
        case Types.SUGGESTED_SEARCH_REQUESTED:
            return {...state, loading: false};
        case Types.SUGGESTED_SEARCH_SUCCEEDED:
            return {...state, suggestedProducts: action.suggestedProducts, success: true, loading: false};
        case Types.SUGGESTED_SEARCH_ERROR:
            return {...state, error: action.err, success: false, loading: false};
        default:
            return state;
    }
}