import React from "react";

const IconStatus = (props) => {
    const {config} = props
    return (
        <div className="text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={config.color} class="bi-circle-fill" viewBox="0 0 16 16">
                <circle cx="8" cy="8" r="8"/>
            </svg><br/>
            <small>{config.name}</small>
        </div>
    )
}
export default IconStatus;
