export const Types= {
    CONFIGURACION_LOADING: 'CONFIGURACION_LOADING',
    CONFIGURACION_UPDATE_SHOOP_ENABLED_REQUEST: 'CONFIGURACION_UPDATE_SHOOP_ENABLED_REQUEST',
    CONFIGURACION_UPDATE_SHOOP_ENABLED_SUCCEEDED: 'CONFIGURACION_UPDATE_SHOOP_ENABLED_SUCCEEDED',
    CONFIGURACION_UPDATE_SHOOP_ENABLED_ERROR: 'CONFIGURACION_UPDATE_SHOOP_ENABLED_ERROR',

    CONFIGURACION_UPLOAD_LISTA_PRECIO: 'CONFIGURACION_UPLOAD_LISTA_PRECIO',
    CONFIGURACION_UPLOAD_LISTA_PRECIO_SUCCEEDED: 'CONFIGURACION_UPLOAD_LISTA_PRECIO_SUCCEEDED',
    CONFIGURACION_UPLOAD_LISTA_PRECIO_ERROR: 'CONFIGURACION_UPLOAD_LISTA_PRECIO_ERROR',
    
    CONFIGURACION_UPLOAD_BANCO_IMAGENES: 'CONFIGURACION_UPLOAD_BANCO_IMAGENES',
    CONFIGURACION_UPLOAD_BANCO_IMAGENES_SUCCEEDED: 'CONFIGURACION_UPLOAD_BANCO_IMAGENES_SUCCEEDED',
    CONFIGURACION_UPLOAD_BANCO_IMAGENES_ERROR: 'CONFIGURACION_UPLOAD_BANCO_IMAGENES_ERROR',
    
    CONFIGURACION_UPLOAD_MANUAL_MARCA: 'CONFIGURACION_UPLOAD_MANUAL_MARCA',
    CONFIGURACION_UPLOAD_MANUAL_MARCA_SUCCEEDED: 'CONFIGURACION_UPLOAD_MANUAL_MARCA_SUCCEEDED',
    CONFIGURACION_UPLOAD_MANUAL_MARCA_ERROR: 'CONFIGURACION_UPLOAD_MANUAL_MARCA_ERROR',
    
    CONFIGURACION_GET_TERMINOS_CONDICIONES: 'CONFIGURACION_GET_TERMINOS_CONDICIONES',
    CONFIGURACION_GET_TERMINOS_CONDICIONES_SUCCEEDED: 'CONFIGURACION_GET_TERMINOS_CONDICIONES_SUCCEEDED',
    CONFIGURACION_GET_TERMINOS_CONDICIONES_ERROR: 'CONFIGURACION_GET_TERMINOS_CONDICIONES_ERROR',
    
    CONFIGURACION_UPDATE_TERMINOS_CONDICIONES: 'CONFIGURACION_UPDATE_TERMINOS_CONDICIONES',
    CONFIGURACION_UPDATE_TERMINOS_CONDICIONES_SUCCEEDED: 'CONFIGURACION_UPDATE_TERMINOS_CONDICIONES_SUCCEEDED',
    CONFIGURACION_UPDATE_TERMINOS_CONDICIONES_ERROR: 'CONFIGURACION_UPDATE_TERMINOS_CONDICIONES_ERROR',
};

export const startLoading = () => ({type: Types.CONFIGURACION_LOADING});

export const updateShoopEnabled = (formData) => ({type: Types.CONFIGURACION_UPDATE_SHOOP_ENABLED_REQUEST, formData});
export const updateShoopEnabledSuccess = () => ({type: Types.CONFIGURACION_UPDATE_SHOOP_ENABLED_SUCCEEDED});
export const updateShoopEnabledError = (err) => ({type: Types.CONFIGURACION_UPDATE_SHOOP_ENABLED_ERROR, err});

export const uploadListaPrecio = (formData) => ({type: Types.CONFIGURACION_UPLOAD_LISTA_PRECIO, formData});
export const uploadListaPrecioSuccess = () => ({type: Types.CONFIGURACION_UPLOAD_LISTA_PRECIO_SUCCEEDED});
export const uploadListaPrecioError = (err) => ({type: Types.CONFIGURACION_UPLOAD_LISTA_PRECIO_ERROR, err});

export const uploadBancoImagenes = (formData) => ({type: Types.CONFIGURACION_UPLOAD_BANCO_IMAGENES, formData});
export const uploadBancoImagenesSuccess = () => ({type: Types.CONFIGURACION_UPLOAD_BANCO_IMAGENES_SUCCEEDED});
export const uploadBancoImagenesError = (err) => ({type: Types.CONFIGURACION_UPLOAD_BANCO_IMAGENES_ERROR, err});

export const uploadManualMarca = (formData) => ({type: Types.CONFIGURACION_UPLOAD_MANUAL_MARCA, formData});
export const uploadManualMarcaSuccess = () => ({type: Types.CONFIGURACION_UPLOAD_MANUAL_MARCA_SUCCEEDED});
export const uploadManualMarcaError = (err) => ({type: Types.CONFIGURACION_UPLOAD_MANUAL_MARCA_ERROR, err});

export const getTerminosCondiciones = () => ({type: Types.CONFIGURACION_GET_TERMINOS_CONDICIONES});
export const getTerminosCondicionesSuccess = (terminos) => ({type: Types.CONFIGURACION_GET_TERMINOS_CONDICIONES_SUCCEEDED, terminos});
export const getTerminosCondicionesError = (err) => ({type: Types.CONFIGURACION_GET_TERMINOS_CONDICIONES_ERROR, err});

export const updateTerminosCondiciones = (formData) => ({type: Types.CONFIGURACION_UPDATE_TERMINOS_CONDICIONES, formData});
export const updateTerminosCondicionesSuccess = () => ({type: Types.CONFIGURACION_UPDATE_TERMINOS_CONDICIONES_SUCCEEDED});
export const updateTerminosCondicionesError = (err) => ({type: Types.CONFIGURACION_UPDATE_TERMINOS_CONDICIONES_ERROR, err});