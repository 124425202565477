import { useEffect } from "react";
import Dolar from "../../routes/dolar";
import Title from "../commons/header_title";
import colores from "../../shared/constants/styles";
import { connect } from "react-redux";
import { requestCliente } from "../../actions/cliente";
import ListadoDescuentos from "../home/descuentos";

const Bonificaciones = (props: any) => {
    useEffect(() => {
        const localStorageItem = window.localStorage.getItem("LocalSession");
        if (localStorageItem !== null) {
            const local = JSON.parse(localStorageItem);
            props.requestCliente(local.userId);
        } 
    }, []);

    return (
        <div>
            <div
                style={{
                    backgroundColor: colores.grisClaro,
                    overflow: "hidden",
                    clear: "both",
                }}
            >
                <div>
                    <Dolar></Dolar>
                    <Title title={"BONIFICACIONES"}></Title>
                </div>
            </div>
            <div className="container">
                <div style={{ marginTop:"5rem" }}>
                    <div className="container">
                        <p style={{ fontSize: "2.1875rem", fontWeight: "bold" }}>Descuentos</p>
                        <div style={{marginBottom: "1.5rem"}}>
                            <ListadoDescuentos key={props.cliente.id} title={"Descuento comercial"} data={props.cliente.reglaDescuento?.$values} />
                        </div>
                        <div style={{marginBottom: "1.5rem"}}>
                            <ListadoDescuentos key={props.cliente.id} title={"Descuento por familia de productos"} data={props.cliente.reglaDescuentoDetalle?.$values} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default connect(
    (state: any) => ({
        cliente: state.clientes.cliente,
        loading: state.clientes.loading,
    }),
    (dispatch) => ({
        requestCliente: (id: any) => dispatch(requestCliente(id)),
    })
  )(Bonificaciones);