import { makeStyles } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";

const muiGeneralTheme = (flagForm) => {
  return createTheme({
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            background: "transparent",
            "&:hover": {
              backgroundColor: "#DBDADA",
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            marginLeft: "0px",
          },
        },
      },
    },
  });
};
const muiInputGeneralStyles = (props) =>
  makeStyles({
    root: {
      "& .MuiInputLabel-root.Mui-focused": {
        color: "black",
        fontWeight: "bold",
        top: "-12px",
        marginLeft: "0px",
      },
      "& .MuiInputLabel-root": {
        color: props.flagForm ? "black" : "#6B6C6E",
        fontWeight: props.flagForm && "bold",
        top: props.flagForm && "-12px",
        marginLeft: props.flagForm ? "0px" : "5px",
      },
      "&:hover .MuiInputLabel-root": {
        fontWeight: "bold",
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#EAEBF0",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#EAEBF0",
      },
    },
    textInput: {
      "& .MuiInputLabel-root.Mui-focused": {
        color: "black",
        fontWeight: "bold",
        top: "-15.5px",
        marginLeft: "0px",
      },
      "& .MuiInputLabel-root": {
        color: props.flagText ? "black" : "#6B6C6E",
        fontWeight: props.flagText && "bold",
        top: props.flagText ? "-15.5px" : "-3px",
        marginLeft: props.flagText ? "0px" : "5px",
      },
      "&:hover .MuiInputLabel-root": {
        fontWeight: "bold",
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#EAEBF0",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#EAEBF0",
      },
    },
  });
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  muiInputGeneralStyles,
  muiGeneralTheme,
};
