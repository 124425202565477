import {call, put, takeEvery, fork} from 'redux-saga/effects'

import * as actions from '../actions/vendedor';

import VendedorService from '../services/vendedor';

function* fetchVendedores() {
    yield put(actions.startLoading());
    try {
        const vendedores = yield call(VendedorService.fetch);
        yield put(actions.receiveVendedores(vendedores));
    } catch (err) {
        yield put(actions.receiveVendedoresError(err));
        console.log(err)
    }
}

function* findVendedores({id}) {
    yield put(actions.startLoading());
    try {
        const vendedor = yield call(VendedorService.find, id);
        yield put(actions.receiveVendedorSucceeded(vendedor));
    } catch (err) {
        yield put(actions.receiveVendedorError(err));
        console.log(err)
    }
}
function* searchVendedores({search}) {
    yield put(actions.startLoading());
    console.log(search)
    try {
        const vendedores = yield call(VendedorService.search, search);
        yield put(actions.receiveVendedores(vendedores));
    } catch (err) {
        yield put(actions.receiveVendedoresError(err));
        console.log(err)
    }
}

function* save({vendedor}) {
    yield put(actions.startLoading())
    try {
        const vendedorRes = yield call(VendedorService.save(vendedor));
        if(vendedorRes) {
            yield put(actions.saveVendedorSucceeded(vendedorRes));
        }
    } catch (err) {
        yield put(actions.saveVendedorError(err));
        console.log(err)
    }
}

function* edit(vendedor) {
    yield put(actions.startLoading())
    try {
        const vendedorRes = yield call(VendedorService.edit(vendedor));
        yield put(actions.editVendedorSucceeded(vendedorRes));
    } catch (err) {
        yield put(actions.editVendedorError(err));
        console.log(err)
    }
}

function* deleteVendedor(vendedor) {
    yield put(actions.startLoading())
    try {
        //const vendedores = yield call(VendedorService.save(vendedor));
        yield put(actions.deleteVendedorSucceeded(vendedor));
    } catch (err) {
        yield put(actions.deleteVendedorError(err));
        console.log(err)
    }
}

function*  watchGetfetchVendedores() {
    yield takeEvery(actions.Types.VENDEDORES_FETCH_REQUESTED, fetchVendedores)
}

function*  watchGetfindVendedores() {
    yield takeEvery(actions.Types.VENDEDORES_FIND_REQUESTED, findVendedores)
}

function*  watchGetsearchVendedores() {
    yield takeEvery(actions.Types.VENDEDORES_SEARCH_REQUESTED, searchVendedores)
}

function*  watchGetsave() {
    yield takeEvery(actions.Types.VENDEDOR_SAVE_REQUESTED, save)
}

function*  watchGetedit() {
    yield takeEvery(actions.Types.VENDEDOR_EDIT_REQUESTED, edit)
}

function*  watchGetdeleteVendedor() {
    yield takeEvery(actions.Types.VENDEDOR_DELETE_REQUESTED, deleteVendedor)
}

const vendedorSagas = [
    fork(watchGetfetchVendedores),
    fork(watchGetfindVendedores),
    fork(watchGetsearchVendedores),
    fork(watchGetsave),
    fork(watchGetedit),
    fork(watchGetdeleteVendedor)
]

export default vendedorSagas;