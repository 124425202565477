import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { isAllowed } from '../../../shared/utils/permissions';
import colores from '../../../shared/constants/styles';
import { getNovedadesMenuByClienteId } from "../../../actions/novedades";
import INovedad from "../../novedades/iNovedad";
import './menu.css'

const MenuNovedades = (props: any) => {
    const localSessionItem = window.localStorage.getItem("LocalSession");
    const local = localSessionItem ? JSON.parse(localSessionItem) : null;
    const [novedades, setNovedades] = useState<INovedad[]>([]);
    const history = useHistory();

    const handleClick = (novedadId: string) => {
        if (props.novedades_menu.data) {
            localStorage.setItem('novedadData', JSON.stringify(props.novedades_menu.data.$values.find((novedad: INovedad) => novedad.id == novedadId)));
            history.push(`/novedad/${novedadId}`);
        }
    };

    const dispatch = useDispatch();
    useEffect(() => {
        if(local.clientId)
            dispatch(getNovedadesMenuByClienteId(local.clientId.trim()));
    }, []);

    useEffect(() => {
        if (props.novedades_menu.data) {
            setNovedades(props.novedades_menu?.data.$values);
        }
    }, [props.novedades_menu]);
    
    return (
        <li className="nav-item dropdown my-auto" style={{marginRight:'0.7rem'}}>
            <Link className="nav-link dropdown-toggle" style={{textDecoration: 'none', color: 'white'}} href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Novedades
            </Link>
            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" style={{ width: '18vw'}}>
                {/* <li style={{backgroundColor: colores.rojo}}> 
                    <Link className="dropdown-item" href="#" 
                        to={'/novedades'} style={{textDecoration: 'none', color: 'white'}}>Novedades
                    </Link>
                </li>  */}
                {novedades?.map(novedad => (
                    <li key={novedad.id} onClick={() => handleClick(novedad.id)}>
                        <Link className="textDecoration dropdown-item" href="#">
                            <div className="row">
                                <div className="padding-05 col-lg-3 col-md-3 mb-3">
                                    <img src={novedad.link} alt={novedad.titulo} height={'45px'} style={{marginTop: '1.2vw'}}/>
                                </div>
                                <div className="padding-1 col-lg-9 col-md-9 mb-9">
                                    <div className="truncate-text-7">{novedad.fechaCortaCreacion}</div>
                                    <div className="truncate-text-12">{novedad.titulo}</div>
                                    <div className="truncate-text-08">{novedad.textoPortada}</div>
                                </div>
                            </div>
                        </Link>
                    </li>
                ))}
                <li> 
                    <Link className="textDecoration dropdown-item" href="#" to={'/novedades'} 
                        style={{textAlign: 'right'}}>ver más...
                    </Link>
                </li> 
            </ul>
        </li>
);};

export default withRouter(connect(
    (state: any) => ({
        novedades_menu: state.novedades.novedades_menu,
        loading: state.novedades.loading,
    }),
    (dispatch) => ({
        getNovedadesMenuByClienteId: (clienteId: number) => dispatch(getNovedadesMenuByClienteId(clienteId)),
    })
)(MenuNovedades));
