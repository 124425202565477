export const Types= {
    RECLAMOS_LOADING: 'RECLAMOS_LOADING',
    RECLAMOS_GET_REQUEST: 'RECLAMOS_GET_REQUEST',
    RECLAMOS_GET_SUCCEEDED: 'RECLAMOS_GET_SUCCEEDED',
    RECLAMOS_GET_ERROR: 'RECLAMOS_GET_ERROR',

    RECLAMOS_SEARCH_REQUESTED: 'RECLAMOS_SEARCH_REQUESTED',
    RECLAMOS_SEARCH_SUCCEEDED: 'RECLAMOS_SEARCH_SUCCEEDED',
    RECLAMOS_SEARCH_ERROR: 'RECLAMOS_SEARCH_ERROR',

    ESTADO_RECLAMO_REQUESTED: 'ESTADO_RECLAMO_REQUESTED',
    ESTADO_RECLAMO_SUCCEEDED: 'ESTADO_RECLAMO_SUCCEEDED',
    ESTADO_RECLAMO_ERROR: 'ESTADO_RECLAMO_ERROR',
    
    RECLAMO_NUEVO_REQUESTED: 'RECLAMO_NUEVO_REQUESTED',
    RECLAMO_NUEVO_SUCCEEDED: 'RECLAMO_NUEVO_SUCCEEDED',
    RECLAMO_NUEVO_ERROR: 'RECLAMO_NUEVO_ERROR',
};

export const startLoading = () => ({type: Types.RECLAMOS_LOADING});
export const getReclamosByCuit = (cuit) => ({type: Types.RECLAMOS_GET_REQUEST, cuit});
export const getReclamosByCuitSucceeded = (reclamos) => ({type: Types.RECLAMOS_GET_SUCCEEDED, reclamos});
export const getReclamosByCuitError = (err) => ({type: Types.RECLAMOS_GET_ERROR, err});

export const getReclamosByFilter = (filter) => ({type: Types.RECLAMOS_SEARCH_REQUESTED, filter});
export const getReclamosByFilterSucceeded = (reclamos) => ({type: Types.RECLAMOS_SEARCH_SUCCEEDED, reclamos})
export const getReclamosByFilterError = (err) => ({type: Types.RECLAMOS_SEARCH_ERROR, err})

export const getEstadosReclamo = () => ({type: Types.ESTADO_RECLAMO_REQUESTED});
export const getEstadoReclamoSucceeded = (estados) => ({type: Types.ESTADO_RECLAMO_SUCCEEDED, estados})
export const getEstadoReclamoError = (err) => ({type: Types.ESTADO_RECLAMO_ERROR, err})

export const postReclamoByClientId = (reclamo) => ({type: Types.RECLAMO_NUEVO_REQUESTED, reclamo});
export const postReclamoByClientIdSucceeded = () => ({type: Types.RECLAMO_NUEVO_SUCCEEDED})
export const postReclamoByClientIdError = (err) => ({type: Types.RECLAMO_NUEVO_ERROR, err})