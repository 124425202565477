import React, {useEffect} from 'react';
import { useFormik } from "formik";
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Yup from "yup";
import { requestOneUsers } from '../../actions/users';
import { changePassword } from '../../actions/auth';
import { useHistory } from "react-router-dom";
import rutas from '../../routes/constants/routes';
import TokenService from '../../services/token';
import { grey } from '@material-ui/core/colors';
import { padding } from '@mui/system';

const ChangePassword = (props) => {

    const myUser = JSON.parse(window.localStorage.getItem("LocalSession"));
    const usuario = {
        primerInicio: myUser?.primerInicio,
    };
    
    let { id } = useParams();
    let { TipoI } = useParams();

    const captchaTokenLS = localStorage.getItem('_grecaptcha');

    const history = useHistory();

    useEffect(() => {
        if(props.successPassword){
            history.push(rutas.LOGIN);
            window.location.reload();
        }
    }, [props])

    
    const passClarification = {
        fontSize: 15,
        fontWeight: 400,
        letterSpacing: -0.3,
        color:'#505050',
        paddingTop:10,
    }
 
    const formik = useFormik({
        initialValues: {
            identification: id,
            identificationType: TipoI,
            passwordCopy: "",
            newPassword: "",
            password: "",
            captchaToken: captchaTokenLS
        },
        validationSchema: Yup.object({
            passwordCopy: Yup.string().required("Ingrese una contraseña")
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!.@#\$%\^&\*])(?=.{8,})/,
                "Debe tener: 1 mayúscula, 1 número, 1 caracter especial y una longitud minima de 8"
              ),
            newPassword: Yup.string().required("Ingrese nuevamente la contraseña")
            .test('passwords-match', 'Las contraseñas deben coincidir', function(value){
                return this.parent.passwordCopy === value
              }),
            password: Yup.string()
                   
        }),
        onSubmit: ({identification, identificationType, passwordCopy, newPassword, password, captchaToken}) => {
            props.changePassword({identification, identificationType, passwordCopy, newPassword, password, captchaToken});
        }
    })

    useEffect(() => {
        fetchUser();
    }, []);

    async function fetchUser() {
        if (id !== undefined && TipoI !== undefined) {
            props.requestOneUsers(id, TipoI);
        }
    }
 
    return (
        <>
        <div>
            <div className="d-grid gap-2 col-12 col-md-5 mx-auto" style={{marginTop:"2.25rem", marginBottom:"2.25rem"}}>
                <div className="container" style={{margin: "20px 0px"}}>
                        <div className="d-flex flex-row" style={{backgroundColor: "#e2e2e2", padding: "15px", borderRadius: "10px"}}>
                            <div className="float-start" style={{fontWeight:"bold", marginRight:"1.25rem"}}>
                                <h4>{ props.users?.tipoRol === 2 ? "ID Vendedor" : "N° Cliente"}</h4>
                                <h4>CUIT</h4>
                                <h4>Empresa</h4>
                            </div>
                            <div className="float-end" style={{color:"gray", fontWeight:200}}>
                                <h4>{props.users?.tipoRol === 2 ? props.users?.vendedorId : props.users?.id}</h4>
                                <h4>{props.users?.documentoNro}</h4>
                                <h4>{props.users?.razonSocial}</h4>
                            </div>
                        </div>
                </div>
                <form className="container" onSubmit={formik.handleSubmit}>
                {TokenService.hasSession()
                    && 
                        (<div className="mb-3">
                        <label for="password" className="form-label" style={{color:"gray",fontWeight:600,fontSize:"0.938rem"}}>Ingrese contraseña actual:</label>
                        <input  onChange={formik.handleChange} error={formik.errors.password} type="password" className="form-control" id="password" name='password' style={{borderColor:"black",padding:"0.625rem"}}></input>
                        {formik.touched.password && formik.errors.password ? (
                        <div className='text-danger'>{formik.errors.password}</div>
                        ): null}
                        </div>
                        )
                }
                        
                        <div className="mb-3">
                            <label for="passwordCopy" className="form-label" style={{color:"gray",fontWeight:500,fontSize:"1.15rem"}}>Nueva contraseña:</label>
                            <input  onChange={formik.handleChange} error={formik.errors.passwordCopy} type="password" className="form-control" id="passwordCopy" name='passwordCopy' style={{borderColor:"black",padding:"0.625rem"}}></input>
                            {formik.touched.passwordCopy && formik.errors.passwordCopy ? (
                            <div className='text-danger'>{formik.errors.passwordCopy}</div>
                            ): null}
                            <p style={passClarification}>&#40;Debe tener: 1 mayúscula, 1 número, 1 caracter especial.&#41;</p>
                        </div>
                        <div className="mb-3">
                            <label for="newPassword" className="form-label" style={{color:"gray",fontWeight:500,fontSize:"1.15rem"}}>Repetir contraseña:</label>
                            <input  onChange={formik.handleChange} error={formik.errors.newPassword} type="password" className="form-control" id="newPassword" name='newPassword' style={{borderColor:"black",padding:"0.625rem"}}></input>
                            {formik.touched.newPassword && formik.errors.newPassword ? (
                            <div className='text-danger'>{formik.errors.newPassword}</div>
                            ): null}
                        </div>
                        <div className="d-grid gap-2 col-12 col-md-6 mx-auto">
                                <button disabled={props.error} className="btn btn-danger" style={{backgroundColor: "#E3000B", padding: "0.625rem", marginTop:"1.25rem"}} type="submit">Confirmar</button>
                               </div>
                        {
                            props.error ?
                            <div class="alert alert-danger" style={{ maxWidth: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }} role="alert">
                                {props.error}
                            </div>
                            :  ""
                        }
                </form>
            </div>
        </div>
    </>
    )
}

export default connect(
    state => ({
      loading: state.auth.loading,
      successPassword: state.auth.successPassword,
      users: state.users.user,
      error: state.users.error
    }),
    dispatch => ({
        requestOneUsers: (id, TipoI) => dispatch(requestOneUsers(id, TipoI)),
        changePassword: (data) => dispatch(changePassword(data))
    })
  )(ChangePassword);
