export const Types= {
    USER_LOADING: 'USER_LOADING',
    USERS_FETCH_REQUESTED: 'USERS_FETCH_REQUESTED',
    USERS_FETCH_SUCCEEDED: 'USERS_FETCH_SUCCEEDED',
    USERS_FETCH_ONE_REQUESTED: 'USERS_FETCH_ONE_REQUESTED',
    USERS_FETCH_ONE_SUCCEEDED: 'USERS_FETCH_ONE_SUCCEEDED',
    USERS_FETCH_ONE_ERROR: 'USERS_FETCH_ONE_ERROR',
    USERS_FETCH_ONE_IDENTIFICATION_REQUESTED: 'USERS_FETCH_ONE_IDENTIFICATION_REQUESTED',
    USERS_FETCH_ONE_IDENTIFICATION_SUCCEEDED: 'USERS_FETCH_ONE_IDENTIFICATION_SUCCEEDED',
    USERS_CREATE_ONE_REQUESTED: 'USERS_CREATE_ONE_REQUESTED',
    USERS_DELETED_ONE_REQUESTED: 'USERS_DELETED_ONE_REQUESTED',
    USERS_OATH: 'USERS_OATH',
    USERS_LOGOUT: 'USERS_LOGOUT',
    USERS_OATH_SUCCEEDED: 'USERS_OATH_SUCCEEDED',
    USER_CHANGE_PASSWORD_REQUESTED: 'USER_CHANGE_PASSWORD_REQUESTED',
    USER_CHANGE_PASSWORD_SUCCEEDED: 'USER_CHANGE_PASSWORD_SUCCEEDED',
    USER_CHANGE_PASSWORD_ERROR: 'USER_CHANGE_PASSWORD_ERROR',
    USER_RESET_PASSWORD_REQUESTED: 'USER_RESET_PASSWORD_REQUESTED',
    USER_RESET_PASSWORD_SUCCEEDED: 'USER_RESET_PASSWORD_SUCCEEDED',
    USER_RESET_PASSWORD_ERROR: 'USER_RESET_PASSWORD_ERROR'
};

export const startLoading = () => ({type: Types.USER_LOADING});

export const requestUsers = () => ({type: Types.USERS_FETCH_REQUESTED});
export const receiveUsers = users => ({
    type: Types.USERS_FETCH_SUCCEEDED, users
})

export const requestOneUsers = (id, TipoI) => ({type: Types.USERS_FETCH_ONE_REQUESTED, id, TipoI});
export const receiveOneUsers = user => ({ type: Types.USERS_FETCH_ONE_SUCCEEDED, user })
export const receiveOneUsersError = err => ({type: Types.USERS_FETCH_ONE_ERROR, err})

export const requestOneUsersIdentification = (identificationData) => ({type: Types.USERS_FETCH_ONE_IDENTIFICATION_REQUESTED, identificationData});
export const receiveOneUsersIdentification = user => ({
    type: Types.USERS_FETCH_ONE_IDENTIFICATION_SUCCEEDED, user
})

export const createUser = (user) => ({type: Types.USERS_CREATE_ONE_REQUESTED, user});

export const requestDeleteUser = (id) => ({type: Types.USERS_DELETED_ONE_REQUESTED, id});

export const login = (loginData) => ({type: Types.USERS_OATH, loginData});
export const logout = () => ({type: Types.USERS_LOGOUT});
export const loginSucceeded = (session, success) => ({type: Types.USERS_OATH_SUCCEEDED, session, success})

export const requestChangePassword = (data) => ({type: Types.USER_CHANGE_PASSWORD_REQUESTED, data});
export const receiveChangePasswordSucceeded = success => ({type: Types.USER_CHANGE_PASSWORD_SUCCEEDED, success})
export const receiveChangePasswordError = err => ({type: Types.USER_CHANGE_PASSWORD_ERROR, err})

export const requestResetPassword = (data) => ({type: Types.USER_RESET_PASSWORD_REQUESTED, data});
export const receiveResetPasswordSucceeded = () => ({type: Types.USER_RESET_PASSWORD_SUCCEEDED})
export const receiveResetPasswordError = error => ({type: Types.USER_RESET_PASSWORD_ERROR, error})