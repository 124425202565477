import { useState} from 'react';
import { connect, useDispatch } from 'react-redux';
import { useFormik } from "formik";
import * as Yup from "yup";
import Dolar from "../../routes/dolar";
import Title from "../commons/header_title";
import colores from "../../shared/constants/styles";
import { requestClienteContact } from '../../actions/cliente';

const ContactForm = (props) => {
    const [ message, setMessage] = useState(false)
    
    const localStorage = JSON.parse(window.localStorage.getItem("LocalSession"));
    const dispatch = useDispatch()
    const yupValidation = {
        message: "Debe contener texto. Vuelva a intentar",
        messageLength: "Debe contener como máximo "
    }
    const sentenceCase = (input) => {
        return input.toLowerCase().toString().replace( /(^|\.\s *|\? *)([a-z])/g, function(match, separator, char) {
            return separator + char.toUpperCase();
        });
    } 
    const formik = useFormik({
        initialValues: {
            Email: localStorage.emailAddress,
            Issue: "",
            Message: "",
            Telefono: localStorage.phoneNumber,
            Id: localStorage.clientId,
            UsuarioId: localStorage.userId,
            RazonSocial: localStorage.bussinessName
        },
        validationSchema: Yup.object({
            Email: Yup.string().required(),
            Issue: Yup.string().required("Ingrese asunto").test('len', yupValidation.message, val => val?.trim().length > 0).test('len', `${yupValidation.messageLength} " 80 " caracteres.'`, val => val?.length < 80),
            Message: Yup.string().required("Ingrese su mensaje").test('len', yupValidation.message, val => val?.trim().length > 0).test('len', `${yupValidation.messageLength} " 500 " caracteres.'`, val => val?.length < 501),
            Telefono: Yup.string(),
            Id: Yup.string(),
            UsuarioId: Yup.string(),
            RazonSocial: Yup.string()
        }),
        onSubmit: (formData) => {
            formData.Issue = sentenceCase(formData.Issue).trim();
            formData.Message = sentenceCase(formData.Message).trim();
            dispatch(requestClienteContact(formData));
            formik.resetForm();
            setMessage(true)
        }
    })

    return (
        <>
        <div
            style={{
            backgroundColor: colores.grisClaro,
            overflow: "hidden",
            clear: "both",
            }}
        >
            <div>
                <Dolar></Dolar>
                <Title title={"CONTACTO"}></Title>
            </div>
            <div
                className="container-fluid"
                style={{
                    backgroundColor: "white",
                    width: "100%",
                    overflow: "hidden",
                    clear: "both",
                    marginBottom: "1.85rem",
                    paddingLeft: "76px",
                    paddingRight: "76px",
                }}
            >
                <form className='d-grid gap-2 col-12 col-md-5 mx-auto' style={{paddingTop:"2rem", paddingBottom:"4rem"}} onSubmit={formik.handleSubmit}>
                    <p className='my-0'>Asunto *</p>
                        <textarea type="text" placeholder="" style={{width: '33.313rem', height: '2.813rem', borderRadius: 5}} {...formik.getFieldProps('Issue')}/>  
                        {formik.touched.Issue && formik.errors.Issue ? (
                                    <div className='text-danger'>{formik.errors.Issue}</div>
                        ): null}
                    <p className='my-0' style={{paddingTop:'0.938rem'}}>Mensaje *</p>
                        <textarea type="text" placeholder="" style={{width: '33.688rem', height: '8.438rem', borderRadius: 5}} {...formik.getFieldProps('Message')}/>

                        {formik.touched.Message && formik.errors.Message ? (
                                    <div className='text-danger'>{formik.errors.Message}</div>
                        ): null}
                    <div className='d-inline'>
                        {/* <div>
                            <label for="formFile" className='py-0 mb-0 mt-3 d-block' style={{paddingTop:'1.25rem'}}>Adjuntar imágenes o archivos</label>
                            <input type="file" class="form-control-file" id="formFile"  style={{ position:'relative', top:'0.625rem', color:"white" }}></input>
                        </div> */}
                        <div>
                            <button type='submit' className='btn btn-secondary text-white d-block' style={{width: '16.875rem', height: '2.813rem', backgroundColor: '#E22022', borderRadius: 5, position:'relative', left:'9.375rem', top:'1.875rem'}}>Confirmar</button>
                        </div>
                    </div>
                    { (props.success && message)  
                    ?
                    <div class="alert alert-success" role="alert" style={{width: '33.688rem', marginTop: '60px'}}>
                        Su mensaje se ha enviado. Su vendedor asignado se comunicará con usted.
                    </div>

                    :
                    ""
                }
                </form>
            </div>            
        </div>
    </>
    )
}

export default connect
(
  state => ({
    presupuestos: state.presupuestos.presupuestos,
    loading: state.presupuestos.loading,
    VenClientes: state.presupuestos.VenClientes,
    success: state.clientes.success
  }),
  dispatch => ({
    requestClienteContact: (data) => dispatch(requestClienteContact(data)),
  })
)(ContactForm);