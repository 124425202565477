import React from 'react';
import ProductoSinImagen from '../../assets/images/ProductoSinImagen.png';

import { isAllowed } from '../../shared/utils/permissions';

import DeleteIcon from '@mui/icons-material/Delete';

const ShopRowOut = (props) => {

    const { data } = props;

    return (
        <div>
            <div className='d-flex align-items-center flex-row flex-wrap'>
                <div className="card text-center" style={{width: "6rem", marginBlock:"2rem", marginInline:"auto", border:"none"}}>
                    {data?.producto?.images?.$values[0] 
                        ? 
                        (<img src={data?.producto?.images?.$values[0]} className="card-img-top" style={{border: "1px solid black", borderRadius: "7px"}} alt="Imagen del producto"/>) 
                        : 
                        (<img src={ProductoSinImagen} className="card-img-top" style={{border: "1px solid black", borderRadius: "7px"}}  alt="Producto sin imagen"/>)
                    }
                </div>
                <div className="card-body" style={{height:"138"}}>
                    <p className="card-title fw-bolder" style={{margin: "0", paddingTop:"20", fontSize:"0.9rem", height:"100%"}}>{data?.producto?.descripcion}</p>
                    <div style={{ margin: "0", padding: "0" }} className="ShopRow__product-inline">
                        {(isAllowed(14) && isAllowed(16)) ? (
                            <p className="fw-bolder btn ShopRow__product-inline" style={{ margin: "0", padding: "0", color: "red", marginRight: '1rem' }}
                                onClick={props.handleClickEliminar}><DeleteIcon fontSize="large" sx={{ color: "red", width: "0.9rem", height: "0.9rem" }}></DeleteIcon></p>
                        ) : (null)
                    }
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-3" style={{margin: "0", padding:"0"}}>
                                <p className="fw-bolder" style={{margin: "0", padding:"0", fontSize: "1.5rem"}}> </p>
                            </div>
                            <div class="col" style={{margin: "0", padding:"0", alignSelf:"center"}}>
                                <p className="fw-bolder" style={{margin: "0", padding:"0", fontSize: "1rem"}}> </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3" style={{margin: "0", padding:"0"}}>
                                <p className="fw-bolder btn" style={{margin: "0", padding:"0", color: "red"}}> </p>
                            </div>
                            <div class="col" style={{margin: "0", padding:"0"}}>
                                <p className="fw-bolder" style={{margin: "0"}}> </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='float-end d-flex align-items-center' style={{width: "13.4rem", height: "2.8rem"}}>
                    <div className='text-danger' style={{fontSize: "1rem", textAlign:"center"}}>
                        El producto ya no está disponible.
                    </div>
                </div>
            </div>
        </div>                            
    )
}

export default ShopRowOut;
