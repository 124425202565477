import React from 'react'

import './index.css'

function LoadingCart() {
  return (
    <div className='LoadingCart__container'>
        <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        <p className='LoadingCart__text'>Cargando...</p>
    </div>
  )
}

export default LoadingCart