import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
    requestPresupuestoDetalles
    , requestEliminarPresupuestoDetalle
    , requestCancelarPresupuesto
    , requestEditarPresupuesto
    , requestEditarCantPresupuestoDetalle
    , resetReceivePreconfirmPresupuestoStatus
} from '../../actions/presupuesto';
import Dolar from '../../routes/dolar';
import rutas from "../../routes/constants/routes";
import colores from '../../shared/constants/styles';
import {
    Button, Dialog, DialogActions, DialogTitle
} from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import MyUserService from "../../services/myUser";
import { isAllowed } from '../../shared/utils/permissions';
import ShopRowOut from './shopRowOut';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import ShopRow from './ShopRow';
import ShopRowTitle from './ShopRowTitle';
import LoadingCart from './LoadingCart';

const Carrito = (props) => {

    const { presupuesto, isCancel } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const myUser = MyUserService.getMyUser();

    const clienteSeleccionado = JSON.parse(window.localStorage.getItem('selectedClient'));
    const detalles = presupuesto?.presupuestoDetalles?.$values;
    const seguirComprandoProductos = myUser?.menus?.$values.find(m => m.nombre.includes('Productos'));
    const seguirComprandoGrupo = myUser?.menus?.$values.find(m => m.menuPadreId === seguirComprandoProductos.id && m.link !== null);
    const seguirComprando = seguirComprandoProductos?.link + seguirComprandoGrupo?.link
    var cantArticulos = detalles?.map(detalle => 1).reduce((previous, current) => {
        return previous + current;
    }, 0);
    const [precioSubtotal, setPrecioSubtotal] = React.useState(null);
    const [precioTotal, setPrecioTotal] = React.useState(null);
    const [pesoSubtotal, setPesoSubtotal] = React.useState(null);
    const [actualizarCarrito, setActualizarCarrito] = React.useState(false);

    const [botonBloqueado, setBotonBloqueado] = useState(false)
    const [botonError, setBotonError] = useState(false)

    // Nuevas funciones para los valores de la tabla de total

    const redondear = (monto) => {
        return Math.round((monto + Number.EPSILON) * 100) / 100
    }
    const calculoMontoTotalSinDto = (items) => {
        let totalAmout = 0

        if (items !== undefined) {
            for (let i = 0; i < items.length; i++) {
                //let dimension = items[i].dimension1 > 0 ? items[i].dimension1:1;
                //totalAmout = (items[i].cantidadTiras * items[i].precio * dimension) + totalAmout
                totalAmout = items[i].montoSubtotal + totalAmout
            }
        } else {
            return 0
        }
        // Esta formula es para tener dos decimales de forma precisa evitando toFixed que aveces da erroes
        return redondear(totalAmout)
    }

    const calculoMontolConDto = (items) => { 
        
        let totalAmoutDTo = 0

        if (items !== undefined) {
            for (let i = 0; i < items.length; i++) {
                //let dimension = items[i].dimension1 > 0 ? items[i].dimension1:1;
                //totalAmout = (items[i].cantidadTiras * items[i].precio * dimension) + totalAmout
                totalAmoutDTo = items[i].montoSubtotalDto + totalAmoutDTo
            }
        } else {
            return 0
        }
        // Esta formula es para tener dos decimales de forma precisa evitando toFixed que aveces da erroes
        return redondear(totalAmoutDTo)

        // let descuentoProductos = 0
        // let descuentoCliente = 0

        // if (items !== undefined) {
        //     for (let i = 0; i < items.length; i++) {
        //         descuentoProductos = ((items[i].cantidadTiras * items[i].precio * items[i].descuento1) / 100) + descuentoProductos
        //     }
        // }

        // total = total - descuentoProductos

        // if (user !== undefined) {
        //     descuentoCliente = (total * user.descuentoCliente) / 100
        // }
        // // Esta formula es para tener dos decimales de forma precisa evitando toFixed que aveces da erroes
        //return redondear(total - descuentoCliente)
    }

    const calculoPeso = (items) => {
        let total = 0
        if (items !== undefined) {
            for (let i = 0; i < items.length; i++) {
                total = items[i].pesoSubtotal + total
            }
        } else {
            return total
        }
        return total
    }

    const buscarInactivo = (items) => {
        setBotonError(false)
        let encontrado = false
        if (typeof items === "object") {
            for (let i = 0; i < items.length; i++) {
                console.log(items[i].activo)
                if (!items[i].activo) {
                    encontrado = true
                }
            }
        }
        setBotonBloqueado(encontrado)
    }
    // Asigno los montos que seran mostrados en la tabla de total
    const montoTotalCarrito = calculoMontoTotalSinDto(detalles)
    const montoTotalDescuento = calculoMontolConDto(detalles)
    const montoDescuento = redondear(montoTotalCarrito - montoTotalDescuento)


    const pesoTotalAct = calculoPeso(detalles)

    const [isChange, setIsChange] = React.useState(false);
    const handleChange = (count, item) => {
        if (!isChange) {
            detalles?.map(d => {
                d.precioTotalActualizado = d.precioTotalActualizado ?? d.montoSubtotal
                d.pesoTotalActualizado = d.pesoTotalActualizado ?? d.pesoSubtotal
                d.precioDtoActualizado = d.precioDtoActualizado ?? d.montoSubtotalDto
            })
        }
        const det = detalles?.find(d => d.item === item)
        if(count <= 0 ||  count < det.cantMin){
            setBotonBloqueado(true);
        } else {
            setBotonBloqueado(false);
            if (det != null) {
                det.cantidadTiras = count;
                det.precioTotalActualizado = det.cantidadTiras * det.precio * ((det.dimension1) > 0 ? det.dimension1 : 1) * ((det.dimension2) > 0 ? det.dimension2 : 1)
                det.pesoTotalActualizado = det.cantidadTiras * det.producto?.peso * ((det.dimension1) > 0 ? det.dimension1 : 1) * ((det.dimension2) > 0 ? det.dimension2 : 1)
                det.precioDtoActualizado = det.descuento1 > 0 ? (det.precioTotalActualizado - (det.precioTotalActualizado * det.descuento1 / 100)) : det.precioTotalActualizado
                setIsChange(true);
            }
            let precioTotalDto = 0
            let pesoTotal = 0
            let precioDto = 0
            if (isChange) {
                detalles?.map(d => {
                    d.precioTotalActualizado = d.precioTotalActualizado ?? d.montoSubtotal
                    d.pesoTotalActualizado = d.pesoTotalActualizado ?? d.pesoSubtotal
                    d.precioDtoActualizado = d.precioDtoActualizado ?? d.montoSubtotalDto
                })
            }
            detalles?.map(d => {
                precioTotalDto = precioTotalDto + d.precioTotalActualizado
                pesoTotal = pesoTotal + d.pesoTotalActualizado
                precioDto = precioDto + d.precioDtoActualizado
            });
    
            handleClick(presupuesto.id, false)
    
            setPrecioSubtotal(precioTotalDto)
            setPesoSubtotal(pesoTotal)
            setPrecioTotal(precioDto)
        }
    }

    useEffect(() => {
        dispatch(resetReceivePreconfirmPresupuestoStatus())
        if (isAllowed(18)) {
            dispatch(requestPresupuestoDetalles(myUser?.userId, myUser?.clientId ? myUser?.clientId : clienteSeleccionado ? clienteSeleccionado[clienteSeleccionado?.length - 1]?.id : null))
        };
    }, [actualizarCarrito]);

    useEffect(() => {
        buscarInactivo(detalles)
    }, [detalles])

    const handleClickCancelarCarrito = (presupuestoId) => {
        dispatch(requestCancelarPresupuesto(presupuestoId))
        history.push(`${seguirComprando}`)
    }
    const handleClickSeguirComprando = (presupuestoId) => {
        if (isChange) {
            const presupuestoActualizado = {
                id: presupuesto.id,
                usuarioAlta: presupuesto.usuarioAlta,
                vendedorId: presupuesto.vendedorId,
                clienteId: presupuesto.clienteId,
                presupuestoDetalles: detalles
            }
            dispatch(requestEditarPresupuesto(presupuestoActualizado, presupuestoId))
        }
        //window.location.assign(rutas.DASHBOARD)
        history.push(rutas.DASHBOARD)
    }
    const handleClick = (presupuestoId, changePage) => {
        
        if (detalles !== undefined) {
            for (let i = 0; i < detalles.length; i++) {
                let total = detalles[i].montoSubtotal
                if (detalles[i].descuento1 > 0) {
                    detalles[i].descuento1 = redondear(detalles[i].descuento1)
                    total = total - ((total * detalles[i].descuento1) / 100)
                }
                if (presupuesto.descuentoCliente > 0) {
                    total = total - ((total * presupuesto.descuentoCliente) / 100)
                }
                detalles[i].montoSubtotalDto = total
                if (detalles[i].precioDtoActualizado) {
                    detalles[i].montoSubtotalDto = detalles[i].precioDtoActualizado * ((100-presupuesto.descuentoCliente)/100);
                    detalles[i].montoSubtotal = detalles[i].precioTotalActualizado;
                    detalles[i].pesoSubtotal = detalles[i].pesoTotalActualizado;
                }
            }
        }


        const presupuestoActualizado = {
            id: presupuesto.id,
            usuarioAlta: presupuesto.usuarioAlta,
            vendedorId: presupuesto.vendedorId,
            clienteId: presupuesto.clienteId,
            presupuestoDetalles: detalles
        }
        dispatch(requestEditarPresupuesto(presupuestoActualizado, presupuestoId))
        if (changePage === true) {
            if (!props.loading) {
                history.push(`${rutas.ENVIOPAGO}/${presupuesto?.id}`);
            }
        }
    }

    const handleClickEliminar = (presupuestoId, item) => {
        dispatch(requestEliminarPresupuestoDetalle(presupuestoId, item))
        detalles?.map(d => {
            dispatch(requestEditarCantPresupuestoDetalle(d, d.presupuestoId, d.item))
        })
        setTimeout(function () {
            setActualizarCarrito(!actualizarCarrito);
        }, 1000);
    }

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div style={{ backgroundColor: colores.grisClaro, overflow: "hidden", clear: "both" }}>

            <Dolar></Dolar>

            <div className='d-flex justify-content-center'>
                <hr style={{ width: "90%", color: "black" }}></hr>
            </div>
            <div className='d-flex justify-content-between flex-wrap align-items-center' style={{ marginBottom: "0.600rem", paddingRight: "3rem", paddingLeft: "21px" }}>
                <h1 className="fw-bolder" style={{ margin: "1rem 3.5rem" }}>MI CARRITO</h1>
            </div>
            {/*             <div className="container-fluid" style={{ backgroundColor: "white", overflow: "hidden", clear: "both", paddingInline: "5rem" }}>
                    <div className='d-flex justify-content-between' style={{ margin: "2.5%" }}>
                        <div>
                            <p className='text-center' style={{ fontSize: "1.5rem" }}>CARGANDO CARRITO....</p>
                        </div>
                    </div>
                </div> */}
            {props.loading ?
                (<LoadingCart></LoadingCart>)
                :
                (
                    <div style={{ backgroundColor: "white", overflow: "hidden", height: 'auto' }}>
                        {cantArticulos >= 1 ?
                            (<div>
                                <div className="row justify-content-center" style={{ marginTop: "1rem" }}>
                                    <div className="col-12 col-sm-8 col-md-8 col-lg-8" style={{ overflowX: "scroll", width: "200px", backgroundColor: "#00800" }} >
                                        <div className='ShoppingCart__item-list'>
                                            <ShopRowTitle></ShopRowTitle>
                                            {detalles?.map(detalle =>
                                            (
                                                detalle?.activo ?
                                                    (<ShopRow
                                                        title={false}
                                                        key={detalle.item}
                                                        data={detalle}
                                                        precioTotalActualizado={detalle.montoSubtotal}
                                                        pesoTotalActualizado={detalle.pesoSubtotal}
                                                        handleClickEliminar={() => { handleClickEliminar(detalle.presupuestoId, detalle.item) }}
                                                        handleChange={(e) => { handleChange(e, detalle.item) }}
                                                    ></ShopRow>)
                                                    :
                                                    (<ShopRowOut
                                                        key={detalle.item}
                                                        data={detalle}
                                                        handleClickEliminar={() => { handleClickEliminar(detalle.presupuestoId, detalle.item) }}
                                                    ></ShopRowOut>)
                                            )
                                            )}
                                        </div>

                                    </div>
                                    <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <div style={{ borderRadius: "5px", backgroundColor: "#F4F4F4", padding: "1.5rem 1.5rem" }}>
                                            <h4>Resumen de compra</h4>
                                            <hr style={{ backgroundColor: "black", width: "100%", height: "1px", border: 0 }} />
                                             {/* Subtotal sin descuento */}
                                            <div className='d-flex justify-content-between'>
                                                <p style={{ margin: 0, padding: 0 }}>Subtotal</p>
                                                <p style={{ margin: 0, padding: 0 }}>{presupuesto?.moneda?.simbolo} {montoTotalCarrito.toFixed(2)}</p>
                                                {/* <p style={{margin: 0, padding: 0}}>{presupuesto?.moneda?.simbolo} {isNaN(precioSubtotal) || precioSubtotal<=0 ? presupuesto?.montoTotalSinDto?.toFixed(2): precioSubtotal?.toFixed(1)}</p> */}
                                            </div>
                                            <hr style={{ backgroundColor: "black", width: "100%", height: "1px", border: 0 }} />
                                             {/* Peso */}
                                            <div className='d-flex justify-content-between'>
                                                <p style={{ margin: 0, padding: 0 }}>Peso subtotal</p>
                                                <p style={{ margin: 0, padding: 0 }}>{isNaN(pesoSubtotal) || precioSubtotal <= 0 ? pesoTotalAct.toFixed(2) : pesoSubtotal?.toFixed(2)} KG</p>
                                            </div>
                                            <hr style={{ backgroundColor: "black", width: "100%", height: "1px", border: 0 }} />
                                             {/* Descuento total */}
                                            <div className='d-flex justify-content-between'>
                                                <p style={{ margin: 0, padding: 0 }}>Descuento</p>
                                                <p style={{ margin: 0, padding: 0 }}>{presupuesto?.moneda?.simbolo} {montoDescuento}</p>
                                                {/*<p style={{margin: 0, padding: 0}}>{presupuesto?.moneda?.simbolo} {presupuesto?.descuentoCliente > 0 ? ((presupuesto?.montoTotalSinDto?.toFixed(1) * presupuesto?.descuentoCliente)/100).toFixed(1) : 0}</p> */}
                                            </div>
                                            <hr style={{ backgroundColor: "black", width: "100%", height: "1px", border: 0 }} />
                                             {/* Total con descuento */}
                                            <div className='d-flex justify-content-between'>
                                                <h4 style={{ color: '#E3000B', margin: 0, padding: 0 }}>Total</h4>
                                                {<h4 style={{ color: '#E3000B', margin: 0, padding: 0 }}>{presupuesto?.moneda?.simbolo} {(Math.round(((montoTotalDescuento) + Number.EPSILON) * 100) / 100).toFixed(2)}</h4>}
                                                {/*<h4 style={{color: '#E3000B', margin: 0, padding: 0}}>{presupuesto?.moneda?.simbolo} {presupuesto?.descuentoCliente > 0 ? Math.abs(((presupuesto?.montoTotalSinDto?.toFixed(2) * presupuesto?.descuentoCliente)/100).toFixed(2) - (isNaN(precioSubtotal) || precioSubtotal<=0 ? presupuesto?.montoTotalSinDto?.toFixed(2): precioSubtotal?.toFixed(2))).toFixed(2) : precioSubtotal?.toFixed(2) }</h4> */}
                                            </div>
                                            <div className='d-flex justify-content-around' style={{ backgroundColor: "#F4F4F4", marginTop: '2rem' }}>
                                                {botonBloqueado
                                                    ? <Button
                                                        style={{ backgroundColor: "#a9a9a9", color: '#fff', width: "90%", height: "3.5rem", fontSize: "18px" }}
                                                        variant="contained"
                                                        onClick={() => { setBotonError(true) }}
                                                    >
                                                        Comprar
                                                    </Button>
                                                    : <Button
                                                        style={{ backgroundColor: colores.rojo, color: '#fff', width: "90%", height: "3.5rem", fontSize: "18px" }}
                                                        variant="contained"
                                                        onClick={(e) => handleClick(presupuesto.id, true)}
                                                    >
                                                        Comprar
                                                    </Button>
                                                }
                                            </div>
                                            {botonError && <p style={{ color: colores.rojo, marginTop: "10px" }}>Debes eliminar del carrito los productos que ya no tienes disponible</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {(isAllowed(17)) ? (
                                        <div className='d-flex justify-content-around'
                                            style={{ width: "100%", marginBottom: "2rem" }}
                                        >
                                            <div className="col-12 col-sm-8 col-md-8 col-lg-8" >
                                                <div className='d-flex justify-content-around'>

                                                    {myUser?.tipoRol === 2 && cantArticulos >= 1 ?
                                                        (<>
                                                            <button
                                                                className="btn btn-outline my-3"
                                                                style={{ backgroundColor: colores.rojo, color: '#fff', textTransform: 'uppercase' }}
                                                                variant="contained"
                                                                onClick={handleOpen}
                                                            >
                                                                Cancelar Carrito
                                                            </button>
                                                            <Dialog
                                                                fullScreen={fullScreen}
                                                                open={open}
                                                                onClose={handleClose}
                                                                aria-labelledby="responsive-dialog-title"
                                                                style={{ fontFamily: 'Mada' }}
                                                            >
                                                                <DialogTitle
                                                                    id="responsive-dialog-title"
                                                                >
                                                                    {"¿Estás seguro que querés cancelar el presupuesto?"}
                                                                </DialogTitle>

                                                                <DialogActions
                                                                    class="d-flex justify-content-around"
                                                                >
                                                                    <Button autoFocus onClick={handleClose}
                                                                        class="btn btn-light my-3 mx-3"
                                                                        style={{ textTransform: 'uppercase' }}
                                                                    >
                                                                        Cerrar
                                                                    </Button>
                                                                    <button
                                                                        class="btn btn-outline-secondary my-3 mx-3"
                                                                        style={{ textTransform: 'uppercase' }}
                                                                        onClick={(e) => handleClickCancelarCarrito(presupuesto.id)}
                                                                    >
                                                                        Cancelar carrito
                                                                    </button>
                                                                </DialogActions>
                                                            </Dialog>

                                                        </>
                                                        )
                                                        : ("")}

                                                    <button type="button"
                                                        class="btn btn-outline-success my-3"
                                                        style={{ textTransform: 'uppercase' }}
                                                        onClick={(e) => handleClickSeguirComprando(presupuesto.id)}>
                                                        Seguir Comprando
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    ) : (null)
                                    }
                                </div>
                            </div>)
                            :
                            (<div style={{ height: "10rem", marginTop: '50px' }}>
                                <h4 style={{ textAlign: "center" }}>No hay ningún artículo en tu carrito de compras</h4>
                                <p style={{ textAlign: "center" }}>Hacé <Link href='#' to={`${seguirComprando}`} >clic aquí</Link> para continuar comprando.</p>
                            </div>)
                        }
                    </div>
                )}
        </div>
    )
}

export default connect(
    state => ({
        presupuesto: state.presupuestos.presupuesto,
        isCancel: state.presupuestos.isCancel,
        loading: state.presupuestos.loading,
    }),
    dispatch => ({
        requestEditarCantPresupuestoDetalle: (cant, presupuestoId, item) => dispatch(requestEditarCantPresupuestoDetalle(cant, presupuestoId, item)),
        requestPresupuestoDetalles: (userId, clientId) => dispatch(requestPresupuestoDetalles(userId, clientId)),
        requestEliminarPresupuestoDetalle: (presupuestoId, item) => dispatch(requestEliminarPresupuestoDetalle(presupuestoId, item)),
        requestCancelarPresupuesto: (presupuestoId) => dispatch(requestCancelarPresupuesto(presupuestoId)),
        requestEditarPresupuesto: (presupuestoActualizado, presupuestoId) => dispatch(requestEditarPresupuesto(presupuestoActualizado, presupuestoId)),
        resetReceivePreconfirmPresupuestoStatus: () => dispatch(resetReceivePreconfirmPresupuestoStatus())
    })
)(Carrito);
