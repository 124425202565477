import { Types } from '../actions/cuentacorriente';

export default function cuentacorrienteReducer(state = {cuentacorrienteReducer: {}, loading: false, success: false, error: false}, action) {
    
    switch (action.type) {
        case Types.ESTADO_PEDIDO_PRESUPUESTO_GET_REQUEST:
            return {...state, loading: true }
        case Types.ESTADO_PEDIDO_PRESUPUESTO_GET_SUCCEEDED:
            return {...state, estadopedidos: action.estadopedidos, loading: false, success: true, error: false};
        case Types.ESTADO_PEDIDO_PRESUPUESTO_GET_ERROR:
            return {...state, loading: false, success: false, error: action.err,};
        
        case Types.ESTADO_PEDIDO_FILTER_GET_REQUEST:
            return {...state, loading: true }
        case Types.ESTADO_PEDIDO_FILTER_GET_SUCCEEDED:
            return {...state, estadopedidos: action.estadopedidos, loading: false, success: true, error: false};
        case Types.ESTADO_PEDIDO_FILTER_GET_ERROR:
            return {...state, loading: false, success: false, error: action.err,};
        
        case Types.SALDOS_FILTER_GET_REQUEST:
            return {...state, loading: true }
        case Types.SALDOS_FILTER_GET_SUCCEEDED:
            return {...state, saldos: action.saldos, loading: false, success: true, error: false};
        case Types.SALDOS_FILTER_GET_ERROR:
            return {...state, loading: false, success: false, error: action.err,};

        case Types.COMPOSICION_FILTER_GET_REQUEST:
            return {...state, loading: true }
        case Types.COMPOSICION_FILTER_GET_SUCCEEDED:
            return {...state, composicion: action.composicion, loading: false, success: true, error: false};
        case Types.COMPOSICION_FILTER_GET_ERROR:
            return {...state, loading: false, success: false, error: action.err,};

        case Types.FACTURA_FILTER_GET_REQUEST:
            return {...state, loading: true }
        case Types.FACTURA_FILTER_GET_SUCCEEDED:
            return {...state, facturas: action.facturas, loading: false, success: true, error: false};
        case Types.FACTURA_FILTER_GET_ERROR:
            return {...state, loading: false, success: false, error: action.err,};
            
        case Types.ESTADOS_GET_REQUEST:
            return {...state, loading: true }
        case Types.ESTADOS_GET_SUCCEEDED:
            return {...state, estados: action.estados, loading: false, success: true, error: false};
        case Types.ESTADOS_GET_ERROR:
            return {...state, loading: false, success: false, error: action.err,};
        default:
            return state;
    }
}