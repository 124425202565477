    import React, { useEffect } from 'react';
    import { useHistory } from "react-router";
    import Dolar from '../../routes/dolar';
    import { useFormik } from "formik";
    import * as Yup from "yup";
    import { connect } from 'react-redux';
    import rutas from '../../routes/constants/routes';
    import { requestAddSuggested, cleanSuccess  } from '../../actions/suggestedProduct';

const AddNewSuggestedProduct = (props) => {
    const history = useHistory();
    const formik = useFormik({
        initialValues: {
            IdProducto: "",
            IdProductoSugerido: "",
            IdTipoRelacion: "1"
        },
        validationSchema: Yup.object({
            IdProducto: Yup.string().required("Ingrese un SKU válido"),
            IdProductoSugerido: Yup.string().required("Ingrese un SKU válido"),
            IdTipoRelacion: Yup.number().required("Ingrese una opción")
        }),
        onSubmit: (formData) => {
            props.requestAddSuggested(formData);
        }
    })
    
    return (
        
        <>
            <div className='container-fluid px-0' style={{backgroundColor: "#EAEBF0", overflow:"hidden", clear:"both"}}>
                <Dolar></Dolar>
                <div className='d-flex justify-content-between flex-wrap align-items-center' style={{marginBottom:"0.600rem"}}>
                    <h1 className="fw-bolder" style={{marginLeft:"3%"}}>SUGERENCIA DE PRODUCTO</h1>
                </div>
                <div className="container-fluid" style={{backgroundColor: "white", overflow:"hidden", clear:"both"}}>
                        {
                            props.error && props.success === false ?
                            <div class="alert alert-danger"  style={{maxWidth:"100%", display: 'flex', justifyContent: 'center', alignItems: 'center'}} role="alert">
                                Los datos ingesados son incorrectos o ya existe una relacion entre estos productos.
                            </div>
                            : ""
                        }
                        {
                            props.successAdd === true ?
                            <div class="alert alert-success"  style={{maxWidth:"100%", display: 'flex', justifyContent: 'center', alignItems: 'center'}} role="alert">
                                Sugerencia ingresada correctamente.
                            </div>
                            : ""
                        }
                    <form  onSubmit={formik.handleSubmit} className="d-flex flex-row align-items-center flex-wrap" style={{backgroundColor: "#EAEBF0", borderRadius: "10px", padding: "0.5rem", margin: "2rem 1rem"}}>
                        <div className="card card-block col-md-4 col-sm-12 py-3" style={{width: "100%", backgroundColor: "#EAEBF0", border: "none"}}>
                            <label className='fw-bolder form-label' for="sku">SKU</label>
                            <div>
                                <input onChange={formik.handleChange} error={formik.errors.IdProducto} name="IdProducto" type="text" className="form-control d-inline-block" id="sku" style={{borderColor: "black", width:"12rem", backgroundColor: "#EAEBF0"}}></input>
                                <p className='d-inline-block px-3 py-2 fw-bolder' style={{color:"#7D8181"}}>Nombre del producto</p>
                            </div>
                            {formik.touched.IdProducto && formik.errors.IdProducto ? (
                            <div className='text-danger'>{formik.errors.IdProducto}</div>
                            ): null}
                        </div>
                        <div className="card card-block col-md-5 col-sm-12 py-3" style={{minWidth: "18.75rem", backgroundColor: "#EAEBF0", border: "none"}}>
                            <label className='fw-bolder form-label' for="skuRefer" >SKU del producto relacionado</label>
                            <div>
                                <input onChange={formik.handleChange} error={formik.errors.IdProductoSugerido}  name="IdProductoSugerido" type="text" className="form-control d-inline-block" id="skuRefer" style={{borderColor: "black", width:"12rem", backgroundColor: "#EAEBF0"}}></input>
                                <p className='d-inline-block px-3 py-2 fw-bolder' style={{color:"#7D8181"}}>Nombre del producto relacionado</p>
                            </div>
                            {formik.touched.IdProductoSugerido && formik.errors.IdProductoSugerido ? (
                            <div className='text-danger'>{formik.errors.IdProductoSugerido}</div>
                            ): null}
                        </div>                        
                        <div className="card card-block col-md-3 col-sm-12 py-3" style={{minWidth: "18.75rem", backgroundColor: "#EAEBF0", border: "none"}}>
                            <p className='fw-bolder' >Tipo de relación</p>
                            <select onChange={formik.handleChange} error={formik.errors.IdTipoRelacion} name="IdTipoRelacion" className="form-select" style={{borderColor: "black", width:"12rem", backgroundColor: "#EAEBF0"}}>
                                <option selected value="1">Complementario</option>
                                <option value="2">Adicional</option>
                                <option value="3">Al azar</option>
                            </select>
                            {formik.touched.IdTipoRelacion && formik.errors.IdTipoRelacion ? (
                            <div className='text-danger'>{formik.errors.IdTipoRelacion}</div>
                            ): null}
                        </div>
                        <button  type="button" className="btn btn-danger" style={{width:"6rem", marginLeft: "1rem", marginTop: "2.5rem", marginBottom:"2.5rem"}} onClick={()=>history.push(rutas.PRODUCTO_SUGERIDO)}>Volver</button>
                        <button  type="submit" className={`btn btn-danger ${props.loading ? "disabled" : ""}`} style={{width:"16rem", marginLeft: "1rem", marginTop: "2.5rem", marginBottom:"2.5rem"}}>Confirmar</button>            
                    </form>
                </div>
            </div>
        </>
    )
}

export default connect
(
  state => ({
    suggestedProducts: state.suggestedProduct.suggestedProducts,
    loading: state.suggestedProduct.loading,
    success: state.suggestedProduct.success,
    successAdd: state.suggestedProduct.successAdd,
    error: state.suggestedProduct.error
  }),
  dispatch => ({
    requestAddSuggested: (formData) => dispatch(requestAddSuggested(formData)),
    cleanSuccess: () => dispatch(cleanSuccess())
  })
)(AddNewSuggestedProduct);
