import axios from 'axios';
import  TokenService  from './token';

const { REACT_APP_ENDPOINT } = process.env;
const ENDPOINT = `${REACT_APP_ENDPOINT}/api/Client/`

export default class clientesService {

    static async contact(formData) {
        try {
            const respuesta = await axios.post(`${ENDPOINT}contactForm`, formData, {
                headers: {
                    authorization: TokenService.getAuthHeader(),
                },
                withCredentials: true
            });
            return respuesta.data.data
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
            }
            throw error; // Re-lanza el error si no es 401
        }
    }

    static async fetch() {
        try {
            const respuesta = await axios.get(`${ENDPOINT}all`, {
                headers: {
                    authorization: TokenService.getAuthHeader(),
                },
                withCredentials: true
            });
            return respuesta.data.data.$values
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
            }
            throw error; // Re-lanza el error si no es 401
        }        
    }

    // Recordar cambio en el fetch
    static async fetchAll() {
        try {
            const respuesta = await axios.get(`${ENDPOINT}all`, {
                headers: {
                    authorization: TokenService.getAuthHeader(),
                },
                withCredentials: true
            });
            return respuesta.data.data.$values
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
            }
            throw error; // Re-lanza el error si no es 401
        }        
    }

    static async fetchBySeller(id) {
        try {
            const respuesta = await axios.get(`${ENDPOINT}getAllBySellerId?idSeller=${id}`, {
                headers: {
                    authorization: TokenService.getAuthHeader(),
                },
                withCredentials: true
            });
            return respuesta.data.data.$values
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
            }
            throw error; // Re-lanza el error si no es 401
        }
    }

    static async find(id) {
        try {
            console.log("MI PERFIL", TokenService.getAuthHeader())
            const respuesta = await axios.get(`${ENDPOINT}${id}`, {
                headers: {
                    authorization: TokenService.getAuthHeader(),
                },
                withCredentials: true
            });
            return respuesta.data.data;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
                console.log("CERRANDO SESION MI PERFIL");
            }
            throw error; // Re-lanza el error si no es 401
        }
    }

    static async search(search) {
        try {
            const respuesta = await axios.get(`${ENDPOINT}search?name=${search}`, {
                headers: {
                    authorization: TokenService.getAuthHeader(),
                },
                withCredentials: true
            });
            return respuesta.data.data.$values
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
            }
            throw error; // Re-lanza el error si no es 401
        }
    }

    static async updateAddress(updateAddressRequest) {
        try {
            const respuesta = await axios.post(`${ENDPOINT}updateAddress`, updateAddressRequest, {
                headers: {
                    authorization: TokenService.getAuthHeader(),
                },
                withCredentials: true
            });
            return respuesta.data.data
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
            }
            throw error; // Re-lanza el error si no es 401
        } 
    }
}
