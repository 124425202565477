import {call, put, takeEvery, fork} from 'redux-saga/effects';
import * as actions from '../actions/consulSteel';
import consulSteel from '../services/consulSteel';

function* getByClientId({clienteId}) {
    yield put(actions.startLoading());
    try {
        const response = yield call(consulSteel.getByClienteId, clienteId);
        if(response){
            yield put(actions.getDataByClienteIdSucceeded(response));
        }
    } catch (err) {
        yield put(actions.getDataByClienteIdError(err));
        console.log(err)
    }
}

function* hasProyectClientId({clienteId}) {
    yield put(actions.startLoading());
    try {
        const response = yield call(consulSteel.hasProyectByClientId, clienteId);
        if(response){
            yield put(actions.hasProyectByClienteIdSucceeded(response));
        }
    } catch (err) {
        yield put(actions.hasProyectByClienteIdError(err));
        console.log(err)
    }
}

function*  watchGetByClientId() {
    yield takeEvery(actions.Types.CONSUL_STEE_BY_CLIENTE_GET_REQUEST, getByClientId);
}
function*  watchHasProyectByClientId() {
    yield takeEvery(actions.Types.HAS_PROJECT_BY_CLIENTE_GET_REQUEST, hasProyectClientId);
}


const consulsteelSagas = [
    fork(watchGetByClientId),
    fork(watchHasProyectByClientId)
]

export default consulsteelSagas;