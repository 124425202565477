import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation , withRouter, useParams, useHistory  } from "react-router-dom";
import Dolar from "../../routes/dolar";
import Title from "../commons/header_title";
import INovedad from "./iNovedad"
import colores from "../../shared/constants/styles";
import {
    Grid,
    CardMedia,
    CardActions,
    CardContent,
    Typography,
    Button
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const NovedadDetalle = (props: any) => {
    const history = useHistory(); 
    const location = useLocation();
    const { id } = useParams<{ id: string }>(); 

    const defaultnovedad: INovedad= {
        id: "",
        cuerpo: "",
        cuerpoDetalle: "",
        textoPortada: ""
    };

    const [novedad, setNovedad] = useState<INovedad>(defaultnovedad);
    
    useEffect(() => {
        const novedadData = localStorage.getItem('novedadData');
        if (novedadData) {
            setNovedad(JSON.parse(novedadData));
        }
        // Limpiar el almacenamiento local cuando ya no se necesite
        //return () => localStorage.removeItem('novedadData');
    }, [location, id]);
    
    const back= () => {
        history.push(`/novedades`);
    }

    const download = () => {
        fetch(novedad.linkDescarga!)
        .then(response => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.blob();
        })
        .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'archivo.xlsx');
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        })
        .catch(error => console.error('Error al descargar el archivo:', error));
    };

    return (
        <div style={{ backgroundColor: colores.grisClaro, overflow: "hidden", clear: "both" }}>
            <div>
                <Dolar></Dolar>
                <Title title={"NOVEDADES"}></Title>
            </div>
            <div style={{ margin: "6vh 32vh 10vh 12vw" }}>
                <Button style={{color: "red"}} onClick={back}  startIcon={<ArrowBackIcon />}>NOVEDADES</Button>
                <div style={{ float: "right"}}>{novedad?.fechaCreacion}</div>
                <h1 className="fw-bolder" style={{ marginTop: "6vh", fontSize: "3.6rem"}}>
                    {novedad?.titulo}
                </h1>
            </div>
            <Grid container spacing={2} style={{ width: "72vw", margin: "6vh 2vh 2vh 12vw" }}>
                <Grid item xs={6}>
                    {novedad.linkImagenSec!== "" && (
                        <CardMedia
                            component="img"
                            image={novedad?.linkImagenSec}
                            alt="imagen_novedad"
                        />
                    )}
                    {novedad.linkVideo && (
                        <CardContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                            <video controls style={{ maxWidth: '100%', maxHeight: '100%' }}>
                                <source src={novedad.linkVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </CardContent>
                    )}
                </Grid>
                <Grid item xs={6}>
                    {novedad?.cuerpo!== "" && (
                        <CardContent>
                            <Typography sx={{ fontSize: "1.3rem", padding: "2vw" }}>
                                <div dangerouslySetInnerHTML={{ __html: novedad?.cuerpoDetalle }} />
                            </Typography>
                        </CardContent>
                    )}
                </Grid>
            </Grid>
            {novedad.linkDescarga && (
                <CardActions style={{ display: 'flex', justifyContent: 'center', margin: "1vw" }}>
                    <button onClick={download} style={{background: colores.rojo, color: 'white', height: "46px", width: "150px" }} className="btn">DESCARGAR</button>
                </CardActions>
            )}
        </div>
    );
};

export default withRouter(connect(() => ({}))(NovedadDetalle));
