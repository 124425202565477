import {call, put, takeEvery, fork} from 'redux-saga/effects';
import * as actions from '../actions/acopios';
import acopios from '../services/acopios';

function* getAcopiosByClienteId({clienteId}) {
    yield put(actions.startLoading());
    try {
        const response = yield call(acopios.getAcopiosByClienteId, clienteId);
        if(response){
            yield put(actions.getAcopiosByClienteIdSucceeded(response));
        }
    } catch (err) {
        yield put(actions.getAcopiosByClienteIdError(err));
        console.log(err)
    }
}

function* getAcopiosDetalle({nroAcopio}) {
    yield put(actions.startLoading());
    try {
        const response = yield call(acopios.getAcopiosDetalle, nroAcopio);
        if(response){
            yield put(actions.getAcopiosDetalleSucceeded(response));
        }
    } catch (err) {
        yield put(actions.getAcopiosDetalleError(err));
        console.log(err)
    }
}

function*  watchGetAcopiosByClienteId() {
    yield takeEvery(actions.Types.ACOPIOS_GET_REQUEST, getAcopiosByClienteId);
}
function*  watchGetAcopiosDetalle() {
    yield takeEvery(actions.Types.ACOPIOS_DETALLES_GET_REQUEST, getAcopiosDetalle);
}

const acopiosSagas = [
    fork(watchGetAcopiosByClienteId),    
    fork(watchGetAcopiosDetalle)
]

export default acopiosSagas;