export const Types = {
  NOVEDADES_LOADING: "NOVEDADES_LOADING",
  NOVEDADES_GET_REQUEST: "NOVEDADES_GET_REQUEST",
  NOVEDADES_GET_SUCCEEDED: "NOVEDADES_GET_SUCCEEDED",
  NOVEDADES_GET_ERROR: "NOVEDADES_GET_ERROR",
  
  NOVEDADES_GET_MENU_REQUEST: "NOVEDADES_GET_MENU_REQUEST",
  NOVEDADES_GET_MENU_SUCCEEDED: "NOVEDADES_GET_MENU_SUCCEEDED",
  NOVEDADES_GET_MENU_ERROR: "NOVEDADES_GET_MENU_ERROR",
};

export const startLoading = () => ({ type: Types.NOVEDADES_LOADING });

export const getNovedadesByClienteId = (clienteId) => ({
  type: Types.NOVEDADES_GET_REQUEST,
  clienteId,
});
export const getNovedadesByClienteIdSucceeded = (novedades) => ({
  type: Types.NOVEDADES_GET_SUCCEEDED,
  novedades,
});
export const getNovedadesByClienteIdError = (err) => ({
  type: Types.NOVEDADES_GET_ERROR,
  err,
});

export const getNovedadesMenuByClienteId = (clienteId) => ({
  type: Types.NOVEDADES_GET_MENU_REQUEST,
  clienteId,
});
export const getNovedadesMenuByClienteIdSucceeded = (novedades_menu) => ({
  type: Types.NOVEDADES_GET_MENU_SUCCEEDED,
  novedades_menu,
});
export const getNovedadesMenuByClienteIdError = (err) => ({
  type: Types.NOVEDADES_GET_MENU_ERROR,
  err,
});
