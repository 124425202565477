import { Types } from '../actions/consulSteel';

export default function consulsteelReducer(state = {consulSteelReducer: {}, loading: false, success: false, error: false}, action) {
    switch (action.type) {
        case Types.CONSUL_STEE_BY_CLIENTE_GET_REQUEST:
            return {...state, loading: true }
        case Types.CONSUL_STEE_BY_CLIENTE_GET_SUCCEEDED:
            return {...state, data: action.data, loading: false, success: true, error: false};
        case Types.CONSUL_STEE_BY_CLIENTE_GET_ERROR:
            return {...state, loading: false, success: false, error: action.err,};
        case Types.HAS_PROJECT_BY_CLIENTE_GET_REQUEST:
            return {...state, loading: true }
        case Types.HAS_PROJECT_BY_CLIENTE_GET_SUCCEEDED:
            return {...state, hasproject: action.hasproject, loading: false, success: true, error: false};
        case Types.HAS_PROJECT_BY_CLIENTE_GET_ERROR:
            return {...state, loading: false, success: false, error: action.err,};
        default:
            return state;
    }
}