export const Types= {
    SUGGESTED_LOADING: 'SUGGESTED_LOADING',
    SUGGESTED_FETCH_REQUESTED: 'SUGGESTED_FETCH_REQUESTED',
    SUGGESTED_FETCH_SUCCEEDED: 'SUGGESTED_FETCH_SUCCEEDED',
    SUGGESTED_FETCH_ERROR: 'SUGGESTED_FETCH_ERROR',
    SUGGESTED_ADD_REQUESTED: 'SUGGESTED_ADD_REQUESTED',
    SUGGESTED_ADD_SUCCEEDED: 'SUGGESTED_ADD_SUCCEEDED',
    SUGGESTED_ADD_ERROR: 'SUGGESTED_ADD_ERROR',
    SUGGESTED_DELETE_REQUESTED: 'SUGGESTED_DELETE_REQUESTED',
    SUGGESTED_DELETE_SUCCEEDED: 'SUGGESTED_DELETE_SUCCEEDED',
    SUGGESTED_DELETE_ERROR: 'SUGGESTED_DELETE_ERROR',
    SUGGESTED_SEARCH_REQUESTED: 'SUGGESTED_SEARCH_REQUESTED',
    SUGGESTED_SEARCH_SUCCEEDED: 'SUGGESTED_SEARCH_SUCCEEDED',
    SUGGESTED_SEARCH_ERROR: 'SUGGESTED_SEARCH_ERROR',
    CLEAN_SUCCESS: 'CLEAN_SUCCESS'
};

export const startLoading = () => ({type: Types.SUGGESTED_LOADING});
export const cleanSuccess = () => ({type: Types.CLEAN_SUCCESS});

export const requestSuggested = () => ({type: Types.SUGGESTED_FETCH_REQUESTED});
export const receiveSuggestedSucceeded = suggestedProducts => ({type: Types.SUGGESTED_FETCH_SUCCEEDED, suggestedProducts});
export const receiveSuggestedError = (err) => ({type: Types.SUGGESTED_FETCH_ERROR, err});

export const requestAddSuggested = (AddSuggested) => ({type: Types.SUGGESTED_ADD_REQUESTED, AddSuggested});
export const receiveAddSuggestedSucceeded = (success) => ({type: Types.SUGGESTED_ADD_SUCCEEDED, success});
export const receiveAddSuggestedError = (err) => ({type: Types.SUGGESTED_ADD_ERROR, err});

export const requestDeleteSuggested = (DeleteSuggested) => ({type: Types.SUGGESTED_DELETE_REQUESTED, DeleteSuggested});
export const receiveDeleteSuggestedSucceeded = (success) => ({type: Types.SUGGESTED_DELETE_SUCCEEDED, success });
export const receiveDeleteSuggestedError = (err) => ({type: Types.SUGGESTED_DELETE_ERROR, err});

export const requestSuggestedSearch = (search) => ({type: Types.SUGGESTED_SEARCH_REQUESTED, search});
export const receiveSuggestedSucceededSearch = suggestedProducts => ({type: Types.SUGGESTED_SEARCH_SUCCEEDED, suggestedProducts});
export const receiveSuggestedErrorSearch = (err) => ({type: Types.SUGGESTED_SEARCH_ERROR, err});

