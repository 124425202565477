export const Types= {
    ACOPIOS_LOADING: 'ACOPIOS_LOADING',
    ACOPIOS_GET_REQUEST: 'ACOPIOS_GET_REQUEST',
    ACOPIOS_GET_SUCCEEDED: 'ACOPIOS_GET_SUCCEEDED',
    ACOPIOS_GET_ERROR: 'ACOPIOS_GET_ERROR',
    ACOPIOS_DETALLES_GET_REQUEST: 'ACOPIOS_DETALLES_GET_REQUEST',
    ACOPIOS_DETALLES_GET_SUCCEEDED: 'ACOPIOS_DETALLES_GET_SUCCEEDED',
    ACOPIOS_DETALLES_GET_ERROR: 'ACOPIOS_DETALLES_GET_ERROR',
};

export const startLoading = () => ({type: Types.ACOPIOS_LOADING});

export const getAcopiosByClienteId = (clienteId) => ({type: Types.ACOPIOS_GET_REQUEST, clienteId});
export const getAcopiosByClienteIdSucceeded = (acopios) => ({type: Types.ACOPIOS_GET_SUCCEEDED, acopios})
export const getAcopiosByClienteIdError = (err) => ({type: Types.ACOPIOS_GET_ERROR, err})

export const getAcopiosDetalle = (nroAcopio) => ({type: Types.ACOPIOS_DETALLES_GET_REQUEST, nroAcopio});
export const getAcopiosDetalleSucceeded = (acopiosDetalle) => ({type: Types.ACOPIOS_DETALLES_GET_SUCCEEDED, acopiosDetalle})
export const getAcopiosDetalleError = (err) => ({type: Types.ACOPIOS_DETALLES_GET_ERROR, err})