import React from 'react';
import { connect } from 'react-redux';
import { requestOneUsers } from '../../actions/users';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const FirstLoginMessage = (props) => {

  const myUser = JSON.parse(localStorage.getItem('LocalSession'));
  const usuario = {
    id: myUser?.userId,
    name: myUser?.bussinessName,
    userId: myUser?.numeroDoc,
    emailAddress: myUser?.emailAddress
  }

  return (

    <>
            <div>
                <div className="d-grid gap-2 col-12 col-md-5 mx-auto" style={{marginTop:"2.25rem", marginBottom:"2.25rem"}}>
                    <div className="container">
                      <div style={{height: '3.438rem', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:"3rem" }}>
                          <div style={{width: "45px", height: "45px", background: "gray", borderRadius: "100px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <ErrorOutlineIcon style={{fill: "white"}}></ErrorOutlineIcon>
                          </div>
                      </div>
                      <h5 className="text-center" style={{marginTop:"1rem",marginBottom:"1.875rem"}}>Éste es su primer ingreso</h5>
                            <div className="d-flex flex-row " style={{backgroundColor: "#e2e2e2", padding: "15px", borderRadius: "10px"}}>
                                <div className="float-start" style={{fontWeight:"bold", marginRight:"1.25rem"}}>
                                    <h4>N° Cliente</h4>
                                    <h4>CUIT</h4>
                                    <h4>Empresa</h4>
                                </div>
                                <div className="float-end" style={{color:"gray", fontWeight:200}}>
                                    <h4  style={{fontWeight:200}}>{usuario?.id}</h4>
                                    <h4 style={{fontWeight:200}}>{usuario?.userId}</h4>
                                    <h4 style={{fontWeight:200}}>{usuario?.name}</h4>
                                </div>
                            </div>
                    </div>
                    <div className="container" style={{paddingTop:"2rem"}}>
                    <div className="d-flex flex-row bd-highlight">
                      <div className="mb-3" style={{color:"gray"}}>
                        <h5 style={{ fontWeight: "200", fontSize: "22px"}}>
                          Se le ha enviado un email a la siguiente dirección registrada:<br/>
                          <br/>
                            <span style={{ fontWeight: "bolder"}}>{usuario?.emailAddress}</span>
                          <br/>
                          <br/>
                          Verifique su bandeja de entrada o spam. Cualquier inconveniente ponerse en contacto con su ejecutivo de venta.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
    </>
  )
}

export default connect(
  state => ({
    session: state.auth.session,
    success: state.auth.success,
    users: state.users.user
  }),
  dispatch => ({
      requestOneUsers: (id, TipoI) => dispatch(requestOneUsers(id, TipoI))
  })
)(FirstLoginMessage);