import React from "react";
import {
    Grid,
} from '@material-ui/core';
import colores from '../../shared/constants/styles';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';

const ListadoDescuentos = (props) => {
    
    const {title, data} = props;    

    return (
        <Grid className="bordesRedondos"  item xs={12}>
            <div className="bordesRedondosTop white-color p-4" style={{backgroundColor: colores.azulOscuro}}>
                <Grid container>
                    <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                        <DiscountOutlinedIcon style={{fill: "white", width: "4vh", height: "4vh"}}/>  
                        <h5>{title}</h5>
                    </Grid>
                </Grid>
            </div>
            <Grid className="bordesRedondosBottom p-4" style={{backgroundColor: colores.grisClaro}}>
                <Grid className= "row" >
                    <Grid item xs={2}>
                        <p className="atributoTitle" style={{ fontSize: "18px" }}>Grupo</p>
                    </Grid>
                    <Grid item xs>
                        <p className="atributoTitle" style={{ fontSize: "18px" }}>Nombre</p>
                    </Grid>
                    <Grid item xs={2}>
                        <p className="atributoTitle" style={{ fontSize: "18px" }}>% De Descuento</p>
                    </Grid>
                </Grid>
                
                {data?.map(descuento => (
                    <Grid className= "row" key={descuento.id} data={descuento} >
                        <Grid item xs={2}>
                            <p className="atributoDato" style={{ fontSize: "18px" }}>{descuento?.id}</p>
                        </Grid>
                        <Grid item xs={8}>
                            <p className="atributoDato" style={{ fontSize: "18px" }}>{descuento?.descripcion}</p>
                        </Grid> 
                        <Grid item xs={2}>
                            <p className="atributoDato" style={{ fontSize: "18px" }}>{descuento?.porcentaje}</p>
                        </Grid> 
                    </Grid>
                ))}
                
            </Grid>
        </Grid>
    )
}
export default ListadoDescuentos