import { Container } from "@mui/material";
import { connect } from 'react-redux';

interface ITitle {
    title:string;
  }

const Title = (props: ITitle) => {

    return(
        <div
          className="container-fluid"
          style={{
            width: "100%",
            overflow: "hidden",
            clear: "both",
            marginBottom: "1.85rem",
          }}
        >
          <div className="d-flex justify-content-center">
            <hr style={{ width: "90%", color: "black", marginTop: "0" }}></hr>
          </div>
          <Container
            maxWidth="w-100"
            style={{ paddingRight: "60px", paddingLeft: "60px" }}
          >
            <div
              className="d-flex justify-content-between flex-wrap align-items-center "
              style={{ marginBottom: "0.600rem", paddingRight: "3.5rem" }}
            >
              <h1 className="fw-bolder" style={{ fontSize: "bolder" }}>
                {props.title}
              </h1>
            </div>
          </Container>
        </div>
    );
}
export default connect()(Title);
