import { Route, Switch } from "react-router-dom";
import rutas from "./constants/routes";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "../pages/home/dashboard";
import SignIn from "../pages/signin/index";
import LogIn from "../pages/logIn/Login";
import Error from "../pages/404/index";
import Vendedores from "../pages/vendedores/index";
import NuevoVendedor from "../pages/vendedores/nuevo";
import EditarVendedor from "../pages/vendedores/editar";
import ResetPassword from "../pages/resetPassword/index";
import ResetPasswordMessage from "../pages/resetPassword/message";
import RestorePassword from "../pages/resetPassword/restorePassword";
import FirstLoginMessage from "../pages/signin/message";
import MisPresupuestos from "../pages/misPresupuestos/index";
import Reclamos from "../pages/reclamos/index";
import Acopios from "../pages/acopios/index";
import NuevoReclamo from "../pages/reclamos/nuevo-reclamo";
import ChangePassword from "../pages/changePassword/ChangePassword";
import ContactForm from "../pages/contactForm/index";
import Clientes from "../pages/clientes/index";
import Profile from "../pages/clientes/profile";
import SellerProfile from "../pages/vendedores/profile";
import EnvioPago from "../pages/Pagos/EnvioPago";
import Producto from "../pages/products/producto";
import Productos from "../pages/products/index";
import AllProductos from "../pages/products/allProductsList";
import Carrito from "../pages/ShoppingCart/index";
import Index from "../pages/suggestedProducts/Index";
import Inicio from "../pages/suggestedProducts/AddNewSuggestedProduct.jsx";
import Confirmar from "../pages/ShoppingCart/confirmar";
import ChangePasswordEmail from "../pages/changePassword/ChangePasswordEmail";
import ClienteVendedor from "../pages/clientes/clienteVendedor";
import ScroolToTop from "../pages/helpers/scrollToTop";
import Configuracion from "../pages/configuracion/configuracion";
import Novedades from "../pages/novedades/index";
import novedad_detalle from "../pages/novedades/novedad_detalle";
import CuentaCorriente from "../pages/cuentacorriente/index";
import Bonificaciones from "../pages/bonificaciones/index";
import ConsulSteel from "../pages/consulSteel/index";

function Router() {
  return (
    <>
      <ScroolToTop />
      <Switch>
        <PrivateRoute
          exact
          path={rutas.DASHBOARD}
          component={Dashboard}
          permisoId={1}
        />
        <PrivateRoute
          exact
          path={`${rutas.ENVIOPAGO}/:idPresupuesto`}
          component={EnvioPago}
          permisoId={17}
        />
        <PublicRoute exact path={rutas.LOGIN} component={LogIn} />
        <Route
          exact
          path={rutas.FIRST_LOGIN_MESSAGE}
          component={FirstLoginMessage}
        />
        <Route exact path={rutas.CHANGE_PASSWORD} component={ChangePassword} />
        <Route
          exact
          path={rutas.CHANGE_PASSWORD_EMAIL}
          component={ChangePasswordEmail}
        />
        <PrivateRoute
          exact
          path={rutas.MIS_PRESUPUESTOS}
          component={MisPresupuestos}
          permisoId={15}
        />
        <PrivateRoute
          exact
          path={rutas.RECLAMOS}
          component={Reclamos}
          permisoId={15}
        />
        <PrivateRoute
          exact
          path={rutas.ACOPIOS}
          component={Acopios}
          permisoId={15}
        />
        <PrivateRoute
          exact
          path={rutas.NOVEDADES}
          component={Novedades}
          permisoId={15}
        />
        <PrivateRoute
          exact
          path={rutas.NOVEDAD}
          component={novedad_detalle}
          permisoId={15}
        />
        <PrivateRoute
          exact
          path={`${rutas.NUEVO_RECLAMO}/:idPresupuesto`}
          component={NuevoReclamo}
          permisoId={15}
        />
        <Route exact path={rutas.RESET_PASWORD} component={ResetPassword} />
        <Route
          exact
          path={rutas.RESET_PASWORD_MESSAGE}
          component={ResetPasswordMessage}
        />
        <Route
          exact
          path={rutas.RESTORE_PASSWORD}
          component={RestorePassword}
        />
        <PrivateRoute
          exact
          path={rutas.VENDEDORES}
          component={Vendedores}
          permisoId={3}
        />
        <PrivateRoute
          exact
          path={rutas.PRODUCTO_SUGERIDO}
          component={Index}
          permisoId={7}
        />
        <Route exact path={rutas.NUEVO_PRODUCTO_SUGERIDO} component={Inicio} />
        <Route exact path={rutas.CONFIGURACION} component={Configuracion} />
        <Route exact path={rutas.NUEVO_VENDEDOR} component={NuevoVendedor} />
        <Route
          exact
          path={`${rutas.EDITAR_VENDEDOR}/:id`}
          component={EditarVendedor}
        />
        <PrivateRoute
          exact
          path={rutas.CLIENTES}
          component={Clientes}
          permisoId={10}
        />
        <Route
          exact
          path={`${rutas.PERFIL_VENDEDOR}/:id`}
          component={SellerProfile}
        />
        <Route exact path={rutas.PERFIL} component={Profile} />
        <Route
          exact
          path={`${rutas.PERFILCLIENTEVENDEDOR}/:id`}
          component={ClienteVendedor}
        />
        <PrivateRoute
          exact
          path={`${rutas.DETALLES_PRODUCTO}/:id`}
          component={Producto}
          permisoId={18}
        />
        <PrivateRoute
          exact
          path={`${rutas.PRODUCTOS}/:id`}
          component={Productos}
          permisoId={18}
        />
        <PrivateRoute
          exact
          path={`${rutas.PRODUCTOS}/:id/:prod`}
          component={Productos}
          permisoId={18}
        />
        <PrivateRoute
          exact
          path={`${rutas.ALL_PRODUCTOS}/:productos`}
          component={AllProductos}
          permisoId={18}
        />
        <PrivateRoute
          exact
          path={rutas.CARRITO}
          component={Carrito}
          permisoId={15}
        />
        <PrivateRoute
          exact
          path={`${rutas.CARRITOCONFIRMAR}/:idPresupuesto/:idUser`}
          component={Confirmar}
          permisoId={15}
        />
        <Route exact path={rutas.FORM_CONTACT} component={ContactForm} />
        <Route exact path={rutas.CUENTA_CORRIENTE} component={CuentaCorriente} />
        <Route exact path={rutas.CONSUL_STEEL} component={ConsulSteel} />
        <Route exact path={rutas.BONIFICACIONES} component={Bonificaciones} />
        <Route exact path={rutas.ERROR} component={Error} />
        <Route exact path="*" component={Error} />
      </Switch>
    </>
  );
}

export default Router;
