export const Types= {
    PARAMS_LOADING: 'PARAMS_LOADING',
    PARAMS_FETCH_REQUESTED: 'PARAMS_FETCH_REQUESTED',
    PARAMS_FETCH_SUCCEEDED: 'PARAMS_FETCH_SUCCEEDED',
    PARAMS_FETCH_REGIONS_REQUESTED: 'PARAMS_FETCH_REGIONS_REQUESTED',
    PARAMS_FETCH_REGIONS_SUCCEEDED: 'PARAMS_FETCH_REGIONS_SUCCEEDED',
    PARAMS_FETCH_REGIONS_ERROR: 'PARAMS_FETCH_REGIONS_ERROR',
    PARAMS_FETCH_GRUPOS_REQUESTED: 'PARAMS_FETCH_GRUPOS_REQUESTED',
    PARAMS_FETCH_GRUPOS_SUCCEEDED: 'PARAMS_FETCH_GRUPOS_SUCCEEDED',
    PARAMS_FETCH_GRUPOS_ERROR: 'PARAMS_FETCH_GRUPOS_ERROR',
    PARAMS_DOLARTODAY_REQUESTED: 'PARAMS_DOLARTODAY_REQUESTED',
    PARAMS_DOLARTODAY_SUCCEEDED: 'PARAMS_DOLARTODAY_SUCCEEDED',
    PARAMS_DOLARTODAY_ERROR: 'PARAMS_DOLARTODAY_ERROR',
    KILOS_FETCH_REQUESTED: 'KILOS_FETCH_REQUESTED',
    KILOS_FETCH_SUCCEEDED: 'KILOS_FETCH_SUCCEEDED',
    KILOS_FETCH_ERROR: 'KILOS_FETCH_ERROR'
};

export const startLoading = () => ({type: Types.PARAMS_LOADING});

export const requestKilos = (ClienteId, DateFrom, DateTo, Category) => ({type: Types.KILOS_FETCH_REQUESTED, ClienteId, DateFrom, DateTo, Category});
export const receiveKilos = kilos => ({type: Types.KILOS_FETCH_SUCCEEDED, kilos})
export const receiveKilosError = err => ({type: Types.KILOS_FETCH_ERROR, err})

export const requestParamsDniType = () => ({type: Types.PARAMS_FETCH_REQUESTED});
export const receiveParamsDniType = params => ({type: Types.PARAMS_FETCH_SUCCEEDED, params})

export const requestRegions = () => ({type: Types.PARAMS_FETCH_REGIONS_REQUESTED});
export const receiveRegionsSucceeded = regions => ({type: Types.PARAMS_FETCH_REGIONS_SUCCEEDED, regions})
export const receiveRegionsError = err => ({type: Types.PARAMS_FETCH_REGIONS_ERROR, err})

export const requestGrupos = () => ({type: Types.PARAMS_FETCH_GRUPOS_REQUESTED});
export const receiveGruposSucceeded = grupos => ({type: Types.PARAMS_FETCH_GRUPOS_SUCCEEDED, grupos})
export const receiveGruposError = err => ({type: Types.PARAMS_FETCH_GRUPOS_ERROR, err})

export const requestDolarToday = () => ({type: Types.PARAMS_DOLARTODAY_REQUESTED});
export const receiveDolarTodaySucceeded = dolar => ({type: Types.PARAMS_DOLARTODAY_SUCCEEDED, dolar})
export const receiveDolarTodayError = err => ({type: Types.PARAMS_DOLARTODAY_ERROR, err})