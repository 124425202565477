import { Types } from '../actions/acopios';

export default function acopiosReducer(state = {acopios: {}, loading: false, success: false, error: false}, action) {
    switch (action.type) {
        case Types.ACOPIOS_GET_REQUEST:
            return {...state, loading: true }
        case Types.ACOPIOS_GET_SUCCEEDED:
            return {...state, acopios: action.acopios, loading: false, success: true, error: false};
        case Types.ACOPIOS_GET_ERROR:
            return {...state, loading: false, success: false, error: action.err,};

        case Types.ACOPIOS_DETALLES_GET_REQUEST:
            return {...state, loading: true }
        case Types.ACOPIOS_DETALLES_GET_SUCCEEDED:
            return {...state, acopiosDetalle: action.acopiosDetalle, loading: false, success: true, error: false};
        case Types.ACOPIOS_DETALLES_GET_ERROR:
            return {...state, loading: false, success: false, error: action.err,};
        default:
            return state;
    }
}